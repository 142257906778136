define('modularis/web/widgets/binders/buttonBinders',
    ['jquery', 'kendo'],
    function ($, kendo) {
        'use strict';


/**
  * @deprecated Please use the {@link https://docs.telerik.com/kendo-ui/framework/mvvm/bindings/visible|VisibleBinder } instead.
  * @classdesc Modularis Button Binder.
  * <p>This class provides a mechanism for shows or hide a button element depending on the view-model value.</p>
  * @constructs modularis.web.widgets.buttonBinderContainer
  * @inner
  * 
  * @example <caption> <p>How to use <b>Modularis Button Binder</b></p>
  * <p><ul>1. Add the <b>data-bind</b> attribute to the element.</ul>
  * <ul>2. Inside the attribute must have the following structure "buttonVisible: ViewModelVariableName"</ul></p>
  * </caption>
  * <input type="button" value="ButtonBinder" data-bind="buttonVisible: isVisible" class="k-button" />
  *  
 **/
        var buttonBinderContainer = {

            init: function () {
                /*eslint no-param-reassign: 0*/
                //#region Binder for buttonVisible property (required for buttons that are localized)
                kendo.data.binders.widget.buttonVisible = kendo.data.Binder.extend({

                    init: function (widget, bindings, options) {
                        var that = this;
                        kendo.data.Binder.fn.init.call(that, widget.element[0], bindings, options);
                    },

                    refresh: function () {
                        var that = this;
                        var value = that.bindings.buttonVisible.get();
                        if (value) {
                            $(that.element).css('display', '');
                        } else {
                            $(that.element).css('display', 'none');
                        }
                    }

                });

                //#endregion

            }

        };

        return buttonBinderContainer;

    }
);
