define('modularisStyleSheetManager',
   ['jquery', 'clientCache', 'configLoader', 'util'],
   function ($, clientCache, configLoader, modularisUtil) {
       'use strict';

       var cookieName = 'modularisThemeName',
          themeToken = 'themeStyleSheet',
          commonToken = 'themeCommonStyleSheet';

       /**
       * Provides functions for style sheets management.
       * @namespace styleSheetManager
       * @memberof modularis.web
       */
       var styleSheetManager = {

         /**
         * Gets the currently active theme object.
         * @returns {object} - Theme object.
         * @memberof modularis.web.styleSheetManager
         */
           getCurrentTheme: function () {
               var themeName = clientCache.getCookie(cookieName);
               if (!themeName) {
                   themeName = this._getThemeConfig().defaultTheme;
               }

               //For some reason, there might be a cookie theme defined, but no data in it.
               var result = this._getThemeObject(themeName);
               if (!result) {
                   result = this._getThemeObject(this._getThemeConfig().defaultTheme);
               }

               return result;
           },

            /**
            * Gets the available themes from the configuration file.
            * @returns {Array} - Available themes.
            * @memberof modularis.web.styleSheetManager
            */
           getThemes: function () {
               return this._getThemeConfig().availableThemes;
           },

           /**
            * Loads the style sheets based on the style configuration.
            * @param {function} callback - Function to be called after the style sheets have been loaded.
            * @memberof modularis.web.styleSheetManager
            */
           loadStyleSheets: function (callback) {

               var that = this;

               var styleSheets = configLoader.appConfig.style.sheets,
                  basePath = configLoader.appConfig.style.basePath,
                  cacheSheets = configLoader.appConfig.style.cache;

               var currentThemeName = that.getCurrentTheme().name,
                  themeObject = that._getThemeObject(currentThemeName),
                  sheetMapping = [];

               //The callback will be invoked when all the stylesheet have been loaded.
               $.when.apply($,
                  $.map(styleSheets, function (currentSheet) {

                      var url = currentSheet;

                      var linkAttributes = { rel: 'stylesheet', type: 'text/css' };

                      if (url === commonToken) {
                          url = that._getCommonStyleSheetUrl(themeObject);
                          linkAttributes.id = 'commonCSS';
                      } else if (url === themeToken) {
                          url = that._getThemeStyleSheetUrl(themeObject);
                          linkAttributes.id = 'themeCSS';
                      }

                      if (!url.endsWith('.css')) {
                          url += '.css';
                      }

                      if (!url.startsWith(basePath)) {
                          url = basePath + url;
                      }

                      if (!cacheSheets) {
                          url += modularisUtil.getCacheUrlArgs();
                      }

                      return $.ajax({
                          url: url
                      }).done(function () {
                          linkAttributes.href = url;
                          sheetMapping.push({ sheet: currentSheet, attributes: linkAttributes });
                      });

                  })
               ).then(function () {

                   //Add stylesheet to the DOM. To render the styles properly, they must be added to the DOM in the same order of the array
                   for (var sheetIndex in styleSheets) {
                       if (styleSheets.hasOwnProperty(sheetIndex)) {
                           var currentSheet = styleSheets[sheetIndex];

                           //Find sheet mapping
                           for (var mappingIndex in sheetMapping) {
                               if (sheetMapping.hasOwnProperty(mappingIndex)) {
                                   var currentMapping = sheetMapping[mappingIndex];
                                   if (currentMapping.sheet === currentSheet) {
                                       $('<link/>', currentMapping.attributes).appendTo('head');
                                   }
                               }
                           }

                       }
                   }

                   modularisUtil.notify(callback);
               });

           },

           /**
            * Sets current/active theme for the application.
            * @param {string} themeName  - Theme name.
            * @memberof modularis.web.styleSheetManager
            */
           setTheme: function (themeName) {
               var themeObject = this._getThemeObject(themeName);

               var themeConfig = this._getThemeConfig();

               var themeStyleSheetUrl = this._getThemeStyleSheetUrl(themeObject);
               var commonStyleSheetUrl = this._getCommonStyleSheetUrl(themeObject);

               //$('body').fadeOut(function () {
               $('link[id="themeCSS"]').attr('href', themeStyleSheetUrl);
               $('link[id="commonCSS"]').attr('href', commonStyleSheetUrl);
               //    $('body').fadeIn();
               //});

               //Store theme selection in cookie
               clientCache.setCookie(cookieName, themeObject.name, themeConfig.cookieDuration);
           },

           _getThemeConfig: function () {
               return configLoader.appConfig.style.theme;
           },

           _getThemeObject: function (themeName) {

               var allThemes = this.getThemes();
               for (var index in allThemes) {
                   if (allThemes[index].name === themeName) {
                       return allThemes[index];
                   }
               }
               return null;
           },

           _getThemeStyleSheetUrl: function (themeObject) {

               var themeConfig = this._getThemeConfig(),
                  basePath = configLoader.appConfig.style.basePath;

               var pathPrefix = basePath + themeConfig.themePath + themeConfig.themePrefix;
               if (!pathPrefix.endsWith('.')) {
                   pathPrefix += '.';
               }

               var sheet = themeObject.name.toLowerCase() + themeConfig.themeExtension;
               return pathPrefix + sheet;
           },

           _getCommonStyleSheetUrl: function (themeObject) {

               var themeConfig = this._getThemeConfig(),
                  basePath = configLoader.appConfig.style.basePath;

               var pathPrefix = basePath + themeConfig.commonPath + themeConfig.commonPrefix;
               if (!pathPrefix.endsWith('.')) {
                   pathPrefix += '.';
               }

               var sheet = themeObject.commonStylesheet.toLowerCase() + themeConfig.commonExtension;
               return pathPrefix + sheet;
           }

       };

       return styleSheetManager;
   }
);
