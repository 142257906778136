define('entityCollectionBase',
    ['jquery', 'objectBuilder', 'configLoader', 'localization', 'util', 'modularisBase/entityMetadata', 'RESTActivityWebServiceProxy', 'enums', 'entityHelper'],
    function ($, objectBuilder, configLoader, localization, util, entityMetadataFactory, RESTActivityWebServiceProxy, enums, entityHelper) {
        'use strict';

        var updateSerializationMetadata = function (copyFrom, currentSerializationMetadata) {

            var newSerializationMetadata = currentSerializationMetadata;
            if (copyFrom != null && util.isDefined(copyFrom._EntityCollection)) {
                newSerializationMetadata = util.copy(copyFrom._EntityCollection);
            }

            return newSerializationMetadata;
        };

        var getEntityKey = function (entity) {

            if (!entity) { return null; }

            var key = null;
            if (util.isFunction(entity.getKey)) {
                entity.rebuildKey();
                key = entity.getKey();
            } else if (entity._Entity && entity._Entity.Key) {
                key = entity._Entity.Key;
            }
            return key;
        };

        var setCounts = function (entityCollection, options) {
            // Set TotalCount and StartRow
            if (options != null) {
                if (options.TotalCount) {
                    entityCollection.TotalCount = parseInt(options.TotalCount);
                }
                if (options[enums.options.sqlOptionStartRow]) {
                    entityCollection.StartRow = options[enums.options.sqlOptionStartRow];
                }
            }
        };

        /**
         * @classdesc Base class for all the generated entity collections.
         * @constructs modularis.EntityCollectionBase
         * @param {string} assemblyName - Assembly to which the entity collection belongs.
         * @param {string} entityCollectionName - Entity collection name.
         * @param {string} entityTypeName - Full name of the singleton entity associated to the collection.
         */
        var EntityCollectionBase = function (assemblyName, entityCollectionName, entityTypeName) {
            var that = this;

            that.Items = [];
            that._keys = [];
            that._entityTypeName = entityTypeName;

            //Initialize serialization metadata
            that._serializationMetadata = {
                AssemblyName: null,
                Culture: null,
                CollectionName: null,
                Type: 'EntityCollection'
            };

            that._serializationMetadata.AssemblyName = assemblyName;
            that._serializationMetadata.CollectionName = entityCollectionName;
            that._collectionMetadata = null;

            /**
             * If true, the collection will check for duplicate keys and will thrown an error if a entity with a duplicated key is added.
             * 
             * @type {boolean}
             * @default true
             * @name modularis.EntityCollectionBase#checkDuplicatedKeys
             */
            that.checkDuplicatedKeys = true;
            if (configLoader.appConfig && configLoader.appConfig.entity) {
                that.checkDuplicatedKeys = configLoader.appConfig.entity.checkCollectionDuplicatedKeys;
            }
        };

        EntityCollectionBase.prototype = {

            /**
             * Adds an entity to the collection.
             * @param {modularis.EntityBase} entityObject - Entity to be added.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            add: function (entityObject) {
                var that = this;
                var newObjectKey = getEntityKey(entityObject);
                if (that.checkDuplicatedKeys && newObjectKey && that.containsKey(newObjectKey)) {
                    throw new Error('Unique key ' + newObjectKey + ' is already in use');
                }
                that.Items.push(entityObject);
                if (newObjectKey) {
                    this._keys.push(newObjectKey);
                }
            },

            /**
             * Adds the entities passed in the entityArray parameter  to the collection.
             * @param {Array} entityArray - Entities to be added.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            addAll: function (entityArray) {
                for (var index = 0; index < entityArray.length; index++) {
                    this.add(entityArray[index]);
                }
            },

            /**
             * Removes all the entities from the collection.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            clear: function () {
                var that = this;
                util.clearArray(that.Items);
                util.clearArray(that._keys);
            },

             /**
             * Returns the entity if is contained in the collection otherwise returns false.
             * @param {object} entityObject - Entity Object.
             * @returns {modularis.EntityBase | boolean} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            containsEntity: function (entityObject) {
                var result = false;
                if (entityObject && util.isFunction(entityObject.getKey)) {
                    result = this.containsKey(entityObject.getKey());
                }
                return result;
            },

            /**
             * Returns true if the collection contains an entity with the given key.
             * @param {string} key - Key to check.
             * @returns {boolean} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            containsKey: function (key) {
                var that = this;
                return (that._keys.indexOf(key) >= 0);
            },

             /**
             * Copies the source entity collection passed as parameter into the current entity collection.
             * @param {modularis.EntityCollectionBase} sourceCollection - Source entity collection to be copied.
             * @param {Function} callback - The function to be executed when the process is completed.
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            copyFrom: function (sourceCollection, callback) {
                var that = this;

                //Update metadata
                that._serializationMetadata = updateSerializationMetadata(sourceCollection, that._serializationMetadata);

                var createEntityPromises = [];

                var sourceItems = sourceCollection.Items;
                if ((!sourceItems) && (sourceCollection instanceof Array)) {
                    sourceItems = sourceCollection;
                }

                for (var index = 0; index < sourceItems.length; index++) {
                    var collectionItem = sourceItems[index];
                    var promise = objectBuilder.createEntityObject({
                        entityTypeName: that._entityTypeName,
                        copyFrom: collectionItem
                    });
                    createEntityPromises.push(promise);
                }

                $.when.apply($, createEntityPromises)
                    .done(function () {

                        for (var index = 0; index < arguments.length; index++) {
                            that.add(arguments[index]);
                        }

                        util.notify(callback, that, null);
                    })
                    .fail(function () {
                        util.notify(callback, null, 'failed');
                    });
            },

             /**
             * Returns the entity in  the given index.
             * @param {int} index - Entity key.
             * @returns {modularis.EntityBase} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            get: function (index) {
                return this.Items[index];
            },

            /**
             * Returns the entity identified with the given key.
             * @param {string} key - Entity key.
             * @returns {modularis.EntityBase} 
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            getByKey: function (key) {
                var item = null,
                    index = this._keys.indexOf(key);
                if (index >= 0) {
                    item = this.Items[index];
                }
                return item;
            },

            /**
             * Removes the entity at the given index.
             * @param {number} index - Index.
             *
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            remove: function (index) {
                var that = this;
                if (index >= this.Items.length) {
                    throw new Error('Index out of bounds');
                }
                that._keys.splice(index, 1);
                that.Items.splice(index, 1);
            },

            /**
             * Removes the entity identified with the given key.
             * @param {string} key - Entity key.
             *
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            removeByKey: function (key) {
                var that = this;
                if (that.containsKey(key)) {
                    var index = that._keys.indexOf(key);
                    that._keys.splice(index, 1);
                    that.Items.splice(index, 1);
                }
            },

             /**
             * Returns the number of entities inside the Entity Collection.
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             * @return {number}
             */
            length: function () {
                return this.Items.length;
            },

            /**
             * Transforms the Entity Collection to a plain object with its metadata.
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             * @return {object}
             */
            getInstanceWithSerializationMetadata: function () {
                var that = this;
                var collectionWithMetadata = { _EntityCollection: that._serializationMetadata, Items: [] };

                //Assign Culture
                var culture = localization.getCurrentCulture();
                collectionWithMetadata._EntityCollection.Culture = culture;

                for (var index = 0; index < that.Items.length; index++) {
                    var item = that.Items[index];
                    if (util.isDefined(item.getInstanceWithSerializationMetadata)) {
                        item = item.getInstanceWithSerializationMetadata(culture);
                    }
                    collectionWithMetadata.Items.push(item);
                }

                return collectionWithMetadata;
            },

            /**
             * Returns an object that provides functions to access entity metadata.
             * @instance
             * @memberOf modularis.EntityCollectionBase
             * @returns {modularis.EntityCollectionMetadata} 
             */
            getCollectionMetadata: function () {
                var that = this;

                var entityCollectionMetadata = new entityMetadataFactory.EntityCollectionMetadata(that);
                return entityCollectionMetadata;

            },

             /**
             * Rebuilds the key for each entity in the Collection.
             * 
             * @instance
             * @memberOf modularis.EntityCollectionBase
             */
            rebuildKeys: function () {
                var that = this;
                util.clearArray(that._keys);
                for (var index = 0; index < that.Items.length; index++) {
                    that._keys.push(getEntityKey(that.Items[index]));
                }
            },


            /**
            * Deletes the entity collection instance.
            * @name delete
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @param {boolean} affectChildren - Indicates whether to process child entities or not.
            * @memberof modularis.EntityCollectionBase
            * 
            * @returns {Promise<boolean>} Promise object represents the if the entity was delete. 
            */
            delete: function (options, affectChildren) {
                var that = this;
                options = options || {};
                for (var idx = 0; idx < that.Items.length; idx++) {
                    var entity = that.Items[idx];
                    entity.getEntityMetadata().setEditMode('Delete');
                }

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.deleteCollection(that, affectChildren, options, function (result, error) {
                        if (util.success(result, error)) {
                            resolve(true);
                        }
                        else {
                            reject(false);
                        }
                    });
                });
            },

            /**
            * Loads the entity collection instance with a collection of entities that match the Modularis pseudo-SQL where clause.
            * @name getByKey
            * @param {string} whereClause - The Modularis pseudo-SQL where clause used to perform the search.
            * @param {Array|string} parameterValues - The parameter values for the where clause.
            * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @memberof modularis.EntityCollectionBase
            * 
            * @returns {Promise<boolean|string>} Promise object represents the if the entity was loaded.
            * Rejected when the entity was not loaded.
            */
            load: function (whereClause, parameterValues, getChildren, options) {
                var that = this;
                options = options || {};
                var entityName = that.getCollectionMetadata().getEntityName();
                var entityTypeName = entityHelper.getEntityTypeByEntityName(entityName);

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.getCollectionByWhere(entityTypeName, whereClause, parameterValues, getChildren, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.clear();
                            that.addAll(result.Items);
                            resolve(result.Items.length);
                            setCounts(that, options);
                        }
                    });
                });
            },

            /**
            * Loads the entity collection instance with the entity identified by a key.
            * @name getByKey
            * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @memberof modularis.EntityCollectionBase
            * 
            * @returns {Promise<boolean|string>} Promise object represents the if the entity was loaded.
            * Rejected when the entity was not loaded.
            */
            loadAll: function (getChildren, options) {
                var that = this;
                options = options || {};
                var entityName = that.getCollectionMetadata().getEntityName();
                var entityTypeName = entityHelper.getEntityTypeByEntityName(entityName);
                
                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.getAll(entityTypeName, getChildren, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.clear();
                            that.addAll(result.Items);
                            resolve(result.Items.length);
                            setCounts(that, options);
                        }
                    });
                });
            },

            /**
            * Creates a duplicate of the entity collection instance.
            * @name duplicate
            * @param {boolean} duplicateChildren - Indicates whether or not to duplicate all child entity instances.
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @memberof modularis.EntityCollectionBase
            *
            * @returns {Promise<EntityBase|string>} Promise object resolved with the duplicated entity.
            * Rejected when the entity was not duplicated.
            */
            duplicate: function (duplicateChildren, options) {
                var that = this;
                options = options || {};
                duplicateChildren = duplicateChildren || false;

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.duplicateCollection(that, duplicateChildren, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(result);
                        }
                    });
                });
            },

            /**
             * Saves the entity collection instance and its child entities
             * @name save
             * @param {boolean} validateFirst - Validates the entity and its children if true. Otherwise, the validation is bypassed.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @memberof  modularis.EntityCollectionBase
             *
             * @returns {Promise<boolean|string>} Promise object represents the if the entity was saved.
             * Rejected when the entity is not saved.
             */
            save: function (validateFirst, options, affectChildren) {
                var that = this;
                options = options || {};

                if (!options[enums.options.refetch] !== false) {
                    options[enums.options.refetch] = true;
                }

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.saveCollection(that, validateFirst, affectChildren, options, function (result, error) {

                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.clear();
                            //that.addAll(result.Items)
                            //resolve(result.Items.length);
                            that.copyFrom(result, function (savedEntityCollection) {
                                if (savedEntityCollection) {
                                    resolve(that);
                                } else {
                                    reject('Unable to save the entity');
                                }
                                setCounts(that, options);
                            });
                        }
                    });
                });
            },

            /**
             * Invokes the business validation class for the entity collection to validate its data.
             * @name validate
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @memberof modularis.EntityCollectionBase
             * 
             * @returns {Promise<Array|string>} Promise object resolved with an array of errors. 
             * If there are no errors, the error array is empty.
             * Rejected when the entity can not be validated.
             */
            validate: function (options) {
                var that = this;
                options = options || {};

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.validateCollection(that, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(result.getEntityMetadata().getErrors());
                        }
                    });
                });
            },


            /**
            * Checks if the user identified by the current session has access to perform the activity operation 
            * on the entity collection instance.
            * @name checkSecurity
            * @param {number} activityID - The activity to check.
            * @param {boolean} checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
            * @memberof modularis.EntityCollectionBase
            * 
            * @returns {Promise<Array|string>} Promise object resolved with a boolean value that represents if the current session has acccess to perform the operation.
            * Rejected when the operation can not be performed.
            */
            checkSecurity: function (activityID, checkChildren) {
                var that = this;
                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.checkSecurityCollection(activityID, that, checkChildren, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(true);
                        }
                    });
                });
            },

        };

        /**
         * Transforms the Entity Collection to a plain object with its metadata.
         * 
         * @instance
         * @memberOf modularis.EntityCollectionBase
         * @function toPlainObject
         * @return {object}
         */
        EntityCollectionBase.prototype.toPlainCollection = EntityCollectionBase.prototype.getInstanceWithSerializationMetadata;

        return {
            EntityCollectionBase: EntityCollectionBase
        };
    }
);
