define('entityViewModelBase',
    ['jquery', 'kendo', 'viewModelBase', 'objectBuilder', 'modularis/web/widgets/asyncValidator',
        'modularis/web/validation/serverSideValidationRule', 'dataSource', 'configLoader', 'logger', 'util', 'enums', 'modularis/base/dynamicEntityHelper'],
    function ($, kendo, viewModelBase, objectBuilder, AsyncValidator, validationRule, ModularisDataSource, configLoader, logger, util, enums, dynamicEntityHelper) {
        'use strict';

        var validationContainerMessage = 'The element {0} was not found in the DOM. It is necessary to validate the data in the view.\n' +
                                    'Once you add the element {0}, please do not forget to update the value in the _containerSelector property in the view-model.';

        var initViewModelDefaultOptions = {
            initDefaultSaveCompletedHandler: true,
            initDefaultSaveErrorHandler: true,
            initDefaultDeleteCompletedHandler: true,
            initDefaultDeleteErrorHandler: true
        };

        var saveEntityDefaultOptions = {
            validateFirst: true,
            affectChildren: true,
            serviceProxyOptions: {}
        };

        var deleteEntityDefaultOptions = {
            affectChildren: false,
            serviceProxyOptions: {}
        };

        var loadEntityDefaultOptions = {
            key: null,
            getChildren: false,
            serviceProxyOptions: {},
            callback: null
        };

        var callAfterValidationError = function (viewModel, errors) {
            if (util.isFunction(viewModel.afterValidationError)) {
                try {
                    viewModel.afterValidationError(errors);
                } catch (exception) {
                    logger.error('Error in the afterValidationError function: ' + exception);
                }
            }
        };

        var hasErrors = function (entity) {
            return !entity.getEntityMetadata().isValid() || !entity.getEntityMetadata().isGraphValid();
        };

        var logEntityErrors = function (entityName, errorsCollection) {
            var message = 'Errors in the entity ' + entityName;
            for (var index = 0; index < errorsCollection.length; index++) {
                message += '\n' + errorsCollection[index].PropName + ': ' + errorsCollection[index].Error;
            }
            logger.error(message);
        };

        var DEFAULT_SAVE_ENTITY_ERROR_MESSAGE_KEY = 'entityViewModelBase_error_event_message';
        var DEFAULT_SAVE_ENTITY_VALIDATION_ERROR_MESSAGE_KEY = 'entityViewModelBase_error_view_message';

        var displayErrorMessages = function (viewModel, entity) {

            var errorMessage = null;

            if (!entity) {
                var saveEntityErrorMessageKey = viewModel.saveEntityErrorMessageKey ?
                    viewModel.saveEntityErrorMessageKey : DEFAULT_SAVE_ENTITY_ERROR_MESSAGE_KEY;

                errorMessage = viewModel.translateStringResource(saveEntityErrorMessageKey);
                viewModel.displayMessage(errorMessage, false);
                return;
            }

            //Execute validations using Errors collection
            var errorsCollection = entity.getEntityMetadata().getErrors();
            var serverSideValidationRule = new validationRule.ServerSideValidationRule(errorsCollection);
            var kendoValidator = viewModel.getValidator(serverSideValidationRule);

            var saveEntityValidationErrorMessageKey = viewModel.saveEntityValidationErrorMessageKey ?
                viewModel.saveEntityValidationErrorMessageKey : DEFAULT_SAVE_ENTITY_VALIDATION_ERROR_MESSAGE_KEY;

            var messageSectionTitle = viewModel.translateStringResource(saveEntityValidationErrorMessageKey);

            //Check validations related to visible fields
            if (!kendoValidator.validate()) {
                errorMessage = messageSectionTitle;
                callAfterValidationError(viewModel, kendoValidator.errors());
            }

            //Check validations related to hidden fields
            var errors = serverSideValidationRule.getHiddenPropertiesErrors()
                            .concat(serverSideValidationRule.getUnavailablePropertiesErrors());
            if (errors.length > 0) {

                if (errorMessage === null) {
                    errorMessage = messageSectionTitle;
                }
                errorMessage += '<br/>';

                //Use of kendo templates to reduce HTML elements manipulation.
                var template = kendo.template('- #: Error #');
                for (var index in errors) {
                    if (errors.hasOwnProperty(index)) {
                        errorMessage += template(errors[index]);
                        if (index !== (errors.length - 1)) {
                            errorMessage += '<br/>';
                        }
                    }
                }

            }

            if (errorMessage !== null) {
                viewModel.displayMessage(errorMessage, false);
            }

            if (configLoader.appConfig.entity.logEntityErrors) {
                logEntityErrors(entity.getEntityMetadata().getEntityName(), errorsCollection);
            }
        };

        var initDefaultEventHandlers = function (viewModel, options) {
            if (options.initDefaultSaveCompletedHandler) {
                viewModel.bind(enums.viewModelEventName.saveCompleted, function (eventData) {

                    //this --> view model that triggered the event.
                    var that = this;
                    var entity = eventData.data;

                    //Check if the entity was successfully saved.
                    if (entity.getEntityMetadata().isValid()) {
                        if (entity.getEntityMetadata().isGraphValid()) {
                            that.displayMessage('Entity saved successfully', true);
                        } else {
                            that.displayMessage('There were errors saving child entities. Check log files for details.', false);
                        }
                    } else {
                        displayErrorMessages(that, entity);
                    }

                });
            }

            if (options.initDefaultSaveErrorHandler) {
                viewModel.bind(enums.viewModelEventName.saveError, function (eventData) {
                    var entity = eventData.data;
                    displayErrorMessages(this, entity);
                });
            }

            if (options.initDefaultDeleteCompletedHandler) {
                viewModel.bind(enums.viewModelEventName.deleteCompleted, function () {
                    this.displayMessage('Entity deleted', true);
                    this.bindNewEntityInstance(null);
                });
            }

            if (options.initDefaultDeleteErrorHandler) {
                viewModel.bind(enums.viewModelEventName.deleteError, function () {
                    this.displayMessage('Error deleting entity', false);
                });
            }

        };

        var validateInputs = function (validator, inputSelectorsArray) {
            var result = true;
            for (var index = 0; index < inputSelectorsArray.length; index++) {
                result = result & validator.validateInput($(inputSelectorsArray[index]));
            }
            return result;
        };

        var ViewModelBase = viewModelBase.ViewModelBase;

        /**
         * @classdesc Base class for all the generated view-models.
         * @constructs modularis.web.EntityViewModelBase
         * 
         * @param {Object} initViewModelOptions - Default options when the view-model is created.
         * @param {boolean} [initViewModelOptions.initDefaultSaveCompletedHandler=true] - Indicates whether the default {@link modularis.web.EntityViewModelBase#saveEntity:completed|save completed event} handler should be executed or not.
         * @param {boolean} [initViewModelOptions.initDefaultSaveErrorHandler=true] - Indicates whether the default {@link modularis.web.EntityViewModelBase#saveEntity:error|save error event} handler should be executed or not.
         * @param {boolean} [initViewModelOptions.initDefaultDeleteCompletedHandler=true] - Indicates whether the default deleted completed event handler should be executed or not.
         * @param {boolean} [initViewModelOptions.initDefaultDeleteErrorHandler=true] - Indicates whether the default delete error event handler should be executed or not.
         * 
         * @property {modularis.EntityBase} activeEntity - Gets or sets the entity currently assigned to the view-model.
         * 
         * @augments modularis.web.ViewModelBase
         */
        var EntityViewModelBase = function (initViewModelOptions) {

            var that = this;
            ViewModelBase.call(that);

            that.entityDefID = 'Modularis.StateManagement.EntityBase';
            that.isDeleteOptionAvailable = false;
            //Establishes if the validation will contain async custom validations.
            that.asyncValidations = false;

            //This is a flag indicate whether the entity comes from a save operation.
            that._isEntityAfterSave = false;

            that._entityName = 'Entity';
            that._activeEntity = null;
            that._isPopulatingViewModel = false;
            that.logEntityErrors = false;

            /**
             * Array that contains jQuery selectors of the custom UI elements that needs to be validated.
             * The elements will be passed to the {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/validator#methods-validateInput|Validator.validateInput} function of Kendo UI.
             * This attribute should only be used when validations are needed on non-input elements like grids or other widgets.
             * 
             * @type {string[]}
             * @default []
             * @name modularis.web.EntityViewModelBase#inputsToValidate
             */
            that.inputsToValidate = [];

            that.bind('set', that._setPropertyValue);

            var options = {};
            $.extend(options, initViewModelDefaultOptions, initViewModelOptions);
            initDefaultEventHandlers(that, options);
        };

        EntityViewModelBase.prototype = new ViewModelBase();

        EntityViewModelBase.prototype.populatingViewModel = function (newValue) {
            this._isPopulatingViewModel = newValue;
        };

        /*eslint-disable no-unused-vars*/

        EntityViewModelBase.prototype.beforeSaveEntity = function (entity, options) {
        };


        /**
         * Function called after saving an entity.
         * 
         * @param {modularis.EntityBase} entity - Entity that was saved.
         */
        EntityViewModelBase.prototype.afterSaveEntity = function (entity) {

        };

        /**
         * This function is meant to be overwritten by sub-classes, although it is not mandatory. 
         * It is called when an entity is assigned to the view-model.
         * @function afterAssignEntity
         * 
         * @param {modularis.EntityBase} entity - activeEntity property.
         * @param {boolean} isEntityAfterSave - Does the entity come from an save operation?
         * 
         * @instance
         * @memberOf modularis.web.EntityViewModelBase
         */

        /**
         * This function is meant to be overwritten by sub-classes, although it is not mandatory. 
         * It is called when there is an error saving an entity.
         * @function afterSaveError
         * 
         * @param {object} error - Error object.
         * 
         * @instance
         * @memberOf modularis.web.EntityViewModelBase
         */

        /**
         * This function is meant to be overwritten by sub-classes, although it is not mandatory. 
         * It is called when there is a validation error in the data.
         * @function afterValidationError
         * 
         * @param {Array} errors - Array of error messages.
         *  
         * @instance
         * @memberOf modularis.web.EntityViewModelBase
         */

        EntityViewModelBase.prototype.getValidatorConfiguration = function () {
            return null;
        };

        /*eslint-enable no-unused-vars*/

        /**
         * Saves the activeEntity.
         * @param {modularis.EntityBase} entity - activeEntity property.
         * @param {boolean} validateFirst - Validates the entity and its children if true. Otherwise, the validation is bypassed.
         * @param {boolean} affectChildren - Indicates whether to process child entities or not.
         * @param {Object} options - A plain object representing any custom actions to be performed during execution.
         * @param {requestCallback} callback - The callback that handles the response.
         * 
         * @fires modularis.web.EntityViewModelBase#saveEntity:completed
         * @fires modularis.web.EntityViewModelBase#saveEntity:error
         */
        EntityViewModelBase.prototype.save = function (entity, validateFirst, affectChildren, options, callback) {
            this.activityServiceProxy.save(entity, validateFirst, affectChildren, options, callback);
        };

        /**
         * Saves the active entity.
         * 
         * @param {Object} [saveEntityOptions] - A plain object representing any custom actions to be performed during execution.
         * @param {boolean} [saveEntityOptions.validateFirst] - Indicates if the entity should be validated before being sent to the server.
         * @param {boolean} [saveEntityOptions.affectChildren] - Indicates if the entity's children will be affected by the transaction.
         * @param {Object} [saveEntityOptions.serviceProxyOptions] - A plain object representing any custom actions to be performed during execution.
         * 
         * @fires modularis.web.EntityViewModelBase#saveEntity:completed
         * @fires modularis.web.EntityViewModelBase#saveEntity:error
         */
        EntityViewModelBase.prototype.saveEntity = function (saveEntityOptions) {

            var that = this;

            var executeSave = function () {
                that.busy(true);

                //Before save
                var options = {};
                $.extend(options, saveEntityDefaultOptions, saveEntityOptions);
                var beforeSaveEntityResult = that.beforeSaveEntity(that.activeEntity, options);
                var continueSaveOperation = (!util.isDefined(beforeSaveEntityResult)) || (beforeSaveEntityResult);
                if (!continueSaveOperation) {
                    return;
                }

                //if (util.isDynamicEntity(that.entityDefID)) {
                //    that.activeEntity.getEntityMetadata().setDirty(true);
                //}

                that.save(that.activeEntity, options.validateFirst, options.affectChildren, options.serviceProxyOptions, function (entityObject, entityError) {
                    that.busy(false);

                    var eventError = entityError;

                    if (util.success(entityObject, entityError) && !hasErrors(entityObject)) {

                        //Update view model with changes in entity retrieved from server
                        that._isEntityAfterSave = true;
                        that.activeEntity = entityObject;

                        /**
                         * 
                         * Event triggered when the activeEntity has been saved successfully.
                         * The name of the event can be accessed through {@link modularis.enums.viewModelEventName.saveCompleted}.
                         * 
                         * @event modularis.web.EntityViewModelBase#saveEntity:completed
                         * @type {Object}
                         * @property {string} entityName - Name of the entity that was saved.
                         * @property {modularis.EntityBase} data - Saved entity.
                         */
                        that.trigger(enums.viewModelEventName.saveCompleted, { entityName: that._entityName, data: entityObject });

                        //After save
                        that.afterSaveEntity(that.activeEntity);

                    } else if (!eventError) {

                        var entityErrors = entityObject ? entityObject.getEntityMetadata().getErrors() : [];

                        eventError = {
                            message: that.translateStringResource('entityViewModelBase_error_event_message'),
                            errorsCollection: entityErrors,
                            isEntityError: true
                        };
                    }

                    if (eventError) {
                        /**
                         * 
                         * Event triggered when there was an error saving the activeEntity.
                         * The name of the event can be accessed through {@link modularis.enums.viewModelEventName.saveError}.
                         * 
                         * @event modularis.web.EntityViewModelBase#saveEntity:error
                         * @type {Object}
                         * @property {string} entityName - Name of the entity.
                         * @property {modularis.EntityBase} data - Entity that could not be saved.
                         * @property {Object|string} error - Save error 
                         */
                        that.trigger(enums.viewModelEventName.saveError, { entityName: that._entityName, data: entityObject, error: eventError });

                        //Call default function to handle server error
                        if (util.isFunction(that.afterSaveError)) {
                            try {
                                that.afterSaveError(eventError);
                            } catch (exception) {
                                logger.error('Error in the afterSaveError function: ' + exception);
                                throw exception;
                            }
                        }
                    }
                });
            };

            var validator = that.getValidator();
            validator.hideMessages();

            if (!that.asyncValidations) {
                if (validator.validate() & validateInputs(validator, that.inputsToValidate)) {
                    executeSave();
                } else {
                    callAfterValidationError(that, validator.errors());
                }
            } else {
                //If the validator contains async validations, we will wait until those validations are completed
                var syncValidationsSuccess = validator.validate() & validateInputs(validator, that.inputsToValidate);
                validator.notifyValidationResult(function (validationResult) {
                    var shouldContinue = false;
                    if (validationResult.rulesCount > 0) {
                        shouldContinue = validationResult.valid && syncValidationsSuccess;
                    } else {
                        shouldContinue = syncValidationsSuccess;
                    }
                    if (shouldContinue) {
                        executeSave();
                    } else {
                        callAfterValidationError(that, validator.errors());
                    }
                });
            }
        };

         /**
         * Deletes the active entity.
         * 
         * @param {Object} [deleteEntityOptions] - A plain object representing any custom actions to be performed during execution.
         * @param {boolean} [deleteEntityOptions.affectChildren] - Indicates if the entity's children will be affected by the transaction.
         * @param {Object} [deleteEntityOptions.serviceProxyOptions] - A plain object representing any custom actions to be performed during execution.
         * */
        EntityViewModelBase.prototype.deleteEntity = function (deleteEntityOptions) {
            var that = this;

            var options = {};
            $.extend(options, deleteEntityDefaultOptions, deleteEntityOptions);

            that.busy(true);
            that.activityServiceProxy.deleteEntity(that.activeEntity, options.affectChildren, options.serviceProxyOptions, function (data, error) {
                that.busy(false);
                if (error == null) {
                    that.trigger(enums.viewModelEventName.deleteCompleted, { entityName: that._entityName });
                } else {
                    that.trigger(enums.viewModelEventName.deleteError, { entityName: that._entityName, error: error });
                }
            });
        };

        /**
         * Sets the active entity using a key.
         * 
         * @param {Object} [loadEntityOptions] - A plain object representing any custom actions to be performed during execution.
         * @param {boolean} [loadEntityOptions.key] - The key of the entity to be loaded.
         * @param {boolean} [loadEntityOptions.getChildren] - Indicates if the entity's children will be loaded.
         * @param {Object} [loadEntityOptions.serviceProxyOptions] - A plain object representing any custom actions to be performed during execution.
         * */
        EntityViewModelBase.prototype.loadEntityByKey = function (loadEntityOptions) {

            var that = this;

            var options = {};
            $.extend(options, loadEntityDefaultOptions, loadEntityOptions);

            that.busy(true);
            that.activityServiceProxy.getByKey(that.entityDefID, options.key, options.getChildren, options.serviceProxyOptions,
                function (entityInstance, entityError) {

                    if (util.success(entityInstance, entityError)) {
                        that.activeEntity = entityInstance;
                        that.trigger(enums.viewModelEventName.loadCompleted, { entityName: that._entityName, entityInstance: entityInstance });
                    } else {
                        that.trigger(enums.viewModelEventName.loadError, { entityName: that._entityName, error: entityError });
                    }
                    that.busy(false);
                    util.notify(options.callback, entityInstance);
                }
            );


        };

        /**
         * Function to be bound to the event of a save entity button. 
         * This function saves the active entity in the server and forces the server to retrieve the updated entity.
         * 
         * */
        EntityViewModelBase.prototype.saveEventHandler = function () {

            var saveEntityOptions = {
                serviceProxyOptions: {}
            };

            saveEntityOptions.serviceProxyOptions[enums.options.refetch] = true;

            this.saveEntity(saveEntityOptions);

        };

        /**
         * Function to be bound to the event of a delete entity button. This function deletes the active entity from the server.
         * 
         * */
        EntityViewModelBase.prototype.deleteEventHandler = function () {
            var deleteEntityOptions = {};
            this.deleteEntity(deleteEntityOptions);
        };

         /**
         * Function to be bound to the event of a back button. This function redirects the browser to the previous view.
         * 
         * */
        EntityViewModelBase.prototype.backEventHandler = function () {
            window.history.back();
        };

         /**
         * Gets a new entity.
         * 
         * @param {string} entityDefID -The entity def ID.
         * @param {Object} options - A plain object representing any custom actions to be performed during execution.
         * @param {Function} callback - The callback function to handle the response.
         * */
        EntityViewModelBase.prototype.getNew = function (entityDefID, options, callback) {
            this.activityServiceProxy.getNew(entityDefID, options, callback);
        };

        /**
         * Binds a new entity instance.
         * 
         * @param {Function} callback - The callback function to handle the response.
         * @param {boolean} [initializeOnServer] - Indicates whether the entity should be initialized in the server or not.
         * @param {Object} options - A plain object representing any custom actions to be performed during execution.
         * */
        EntityViewModelBase.prototype.bindNewEntityInstance = function (callback, initializeOnServer, options) {
            var that = this;

            options = options ? options : {};
            options[enums.options.initializeOnServer] = initializeOnServer ? true : false;
            options[enums.options.convertToJavaScriptEntity] = true;
           
            var isDynamicEntity = util.isDynamicEntity(that.entityDefID);

            var getNewCallback = function (entityInstance, error) {
                if (util.success(entityInstance, error)) {
                    that.activeEntity = entityInstance;
                    var keyStructures = entityInstance.getEntityMetadata().getKeyStructure().split('.');
                    keyStructures.forEach(function (keyStructure) {
                        if (entityInstance[keyStructure] != null && entityInstance[keyStructure].length > 0) {
                            that.set(keyStructure, entityInstance[keyStructure]);
                        }
                    });
                }
                util.notify(callback, that, error);
            };

            if (isDynamicEntity) {
                dynamicEntityHelper.getNew(that.entityDefID, options, getNewCallback);
            } else {
                that.getNew(that.entityDefID, options, getNewCallback);
            }
        };

        /**
         * Returns a Validator object.  
         * 
         * @param {Object} validationRules - Object with the validation rules.
         * @return {Object}
         * */
        EntityViewModelBase.prototype.getValidator = function (validationRules) {
            var that = this;

            //#region Check if the container to create the validator is defined
            if (!that._containerSelector) {
                logger.info('Suggestion: If you want to handle validations, please define the _containerSelector property.');
            }

            if (that._containerSelector && $(that._containerSelector).length <= 0) {
                var errorMessage = String.format(validationContainerMessage, that._containerSelector);
                throw new Error(errorMessage);
            }

            //#endregion

            var widgetConfiguration = util.copy(validationRules),
                customConfiguration = that.getValidatorConfiguration();

            if (!widgetConfiguration) { widgetConfiguration = {}; }
            if (!customConfiguration) { customConfiguration = {}; }

            $.extend(true, widgetConfiguration, customConfiguration, { entityTypeName: that.entityDefID });

            var result = null;
            if (!that.asyncValidations) {
                $(that._containerSelector).kendoModularisValidator(widgetConfiguration);
                result = $(that._containerSelector).getKendoModularisValidator();
            } else {
                $(that._containerSelector).kendoAsyncValidator(widgetConfiguration);
                result = $(that._containerSelector).getKendoAsyncValidator();
            }

            return result;
        };

        EntityViewModelBase.prototype.afterViewIsDisplayed = function () {
            //Force validator creation.
            this.getValidator();
        };

        /**
         * Returns value of the edit mode of the active entity.
         * 
         * @returns {string}  
         * */
        EntityViewModelBase.prototype.getEditMode = function () {
            var that = this;
            var result = enums.editModes.unknown;
            if (that.activeEntity && util.isFunction(that.activeEntity.getEntityMetadata)) {
                result = that.activeEntity.getEntityMetadata().getEditMode();
            }
            return result;
        };

        /**
         * Indicates whether the active entity has been updated.
         * 
         * @returns {string}  
         */
        EntityViewModelBase.prototype.isDirty = function () {
            var that = this;
            var isDirty = false;
            if (that.activeEntity && util.isFunction(that.activeEntity.getEntityMetadata)) {
                isDirty = that.activeEntity.getEntityMetadata().isDirty();
            }
            return isDirty;
        };

        EntityViewModelBase.prototype.clearMessage = function () {
            //Override base class function
            var that = this;
            ViewModelBase.prototype.clearMessage.call(that);
            if (that._containerSelector && $(that._containerSelector).length > 0) {
                var validator = that.getValidator();
                if (validator) {
                    validator.hideMessages();
                }
            }
        };

        EntityViewModelBase.prototype.displayErrorMessages = function (entity) {
            var that = this;
            displayErrorMessages(that, entity);
        };

        EntityViewModelBase.prototype._setPropertyValue = function (event) {
            var that = this;
            var activeEntity = that.activeEntity;

            var setValue = function (entity, propertyName, propertyValue) {
                if (util.isDefined(entity[propertyName])) {
                    //Needed for properties injected to the entity (like DV properties).
                    entity[propertyName] = propertyValue;
                    var propertyDef = entity.getEntityMetadata().getPropertyDef(propertyName);
                    if (propertyDef) {
                        entity.setPropertyValue(propertyName, propertyValue);
                    }
                    that.clearMessage();
                }
            };

            if (activeEntity != null && that._isPopulatingViewModel === false) {

                //Does the property belongs to the root entity?
                if (event.field.indexOf('.') < 0) {
                    setValue(activeEntity, event.field, event.value);
                } else {
                    //the property belongs to a child entity.
                    var propertyPath = event.field.substr(0, event.field.lastIndexOf('.'));
                    var propertyName = event.field.substr(event.field.lastIndexOf('.') + 1);
                    var lastObject = activeEntity;
                    var NEXT = 1;
                    while (propertyPath.length > 0 && lastObject) {
                        var currentPropertyName = propertyPath.substr(0, propertyPath.indexOf('.'));
                        if (currentPropertyName.length === 0) {
                            //this is the end of the string
                            currentPropertyName = propertyPath;
                            propertyPath = '';
                        } else {
                            propertyPath = propertyPath.substr(propertyPath.indexOf('.') + NEXT);
                        }
                        lastObject = lastObject[currentPropertyName];
                    }
                    if (lastObject) {
                        setValue(lastObject, propertyName, event.value);
                    }
                }

            }
        };

        Object.defineProperties(EntityViewModelBase.prototype, {
            'entityTypeName': {
                get: function () {
                    return this.entityDefID;
                },
                set: function (newValue) {
                    this.entityDefID = newValue;
                }
            },
            'activeEntity': {
                get: function () {
                    return this._activeEntity;
                },
                set: function (newActiveEntity) {
                    var that = this;
                    var assignEntity = function (entityToBeAssigned) {

                        that._activeEntity = entityToBeAssigned;

                        var entityMetadata = that._activeEntity.getEntityMetadata(),
                            deleteAvailable = entityMetadata.getEditMode() === enums.editModes.update;

                        that.set('isDeleteOptionAvailable', deleteAvailable);
                        that.populatingViewModel(true);
                        var types = enums.propertyTypes;
                        var field;

                        //Update properties
                        for (field in that._activeEntity) {
                            //Avoid private members and internal kendo identifiers
                            if (field.indexOf('_') !== 0 && field !== 'uid') {
                                var fieldType = typeof (that[field]);
                                if (fieldType !== 'undefined' && fieldType !== 'function') {

                                    try {
                                        var propertyDef = entityMetadata.getPropertyDef(field);
                                        var propertyType = propertyDef ? propertyDef.type : null;
                                        if (propertyType === types.typeEntity || propertyType === types.typeEntityCollection || propertyType === types.typeObject || propertyType === types.typeCollection) {

                                            /*In this case, we take properties from newActiveEntity and not that._activeEntity because second one 
                                            contains ECMAScript 6 properties and those one are not supported by Kendo for bindings.*/
                                            that.set(field, newActiveEntity[field]);
                                        } else {
                                            that.set(field, that._activeEntity[field]);
                                        }
                                    } catch (exception) {
                                        logger.error('Error setting binding for property ' + field);
                                        throw exception;
                                    }
                                }
                            }
                        }

                        //Update data sources related to lookup properties
                        for (field in that) {
                            if (that.hasOwnProperty(field) && (that[field] instanceof ModularisDataSource)) {
                                var dataSource = that[field];
                                var shouldBeUpdated = ('modularis' in dataSource.options) && ('activity' in dataSource.options.modularis)
                                                        && ('parameterSource' in dataSource.options.modularis.activity) && ('propertyName' in dataSource.options.modularis.activity);
                                if (shouldBeUpdated) {
                                    dataSource.updateParameterSource(that.activeEntity);
                                    if (!('readAfterAssignEntity' in dataSource.options.modularis.activity) || dataSource.options.modularis.activity.readAfterAssignEntity) {
                                        dataSource.read();
                                    }
                                }
                            }
                        }

                        if (util.isFunction(that.afterAssignEntity)) {
                            try {
                                that.afterAssignEntity(that.activeEntity, that._isEntityAfterSave);
                                that._isEntityAfterSave = false;
                            } catch (exception) {
                                that._isEntityAfterSave = false;
                                logger.error('Error in the afterAssignEntity function: ' + exception);
                                throw exception;
                            }
                        }

                        //Now that another entity has been bound to the view-model, the messages can be cleared.
                        that.clearMessage();
                        that.populatingViewModel(false);
                    };


                    var metadataType = typeof (newActiveEntity.getEntityMetadata);
                    if (metadataType !== 'undefined' && metadataType === 'function'
                            && newActiveEntity.getEntityMetadata().getEntityName() === that._entityName) {
                        assignEntity(newActiveEntity);
                    } else {
                        objectBuilder.createEntityObject({
                            entityTypeName: that.entityDefID,
                            assignIdentifier: false,
                            copyFrom: newActiveEntity,
                            callback: function (entityObject, error) {
                                if (error == null) {
                                    assignEntity(entityObject);
                                }
                            }
                        });
                    }
                }
            }
        });

        return {
            EntityViewModelBase: EntityViewModelBase
        };

    }
);
