define('entityBase',
    ['jquery', 'objectBuilder', 'entityCollectionBase', 'localization', 'enums', 'util', 'modularisBase/entityMetadata', 'RESTActivityWebServiceProxy', 'entityHelper'],
    function ($, objectBuilder, entityCollectionBase, localization, enums, util, entityMetadataFactory, RESTActivityWebServiceProxy, entityHelper) {
        /*eslint new-cap: 0*/
        'use strict';

        var types = enums.propertyTypes;

        var updateSerializationMetadata = function (copyFrom, currentSerializationMetadata) {

            var newSerializationMetadata = currentSerializationMetadata;
            if (copyFrom != null && util.isDefined(copyFrom._Entity)) {
                newSerializationMetadata = util.copy(copyFrom._Entity);
            }

            return newSerializationMetadata;
        };

        var createEntity = function (propertyDef, copyFrom) {
            var deferred = $.Deferred();

            var createEntityCallback = function (entityInstance, error) {
                if (util.success(entityInstance, error)) {
                    deferred.resolve(propertyDef, entityInstance);
                } else {
                    deferred.reject(propertyDef, error);
                }
            };

            var promise = deferred.promise();

            objectBuilder.createEntityObject({
                entityTypeName: propertyDef.entityTypeName,
                copyFrom: copyFrom,
                assignIdentifier: false,
                callback: createEntityCallback
            });

            return promise;
        };

        var createEntityCollection = function (propertyDef, copyFrom, assemblyName) {
            var deferred = $.Deferred();

            var createCollectionCallback = function (entityCollection, error) {
                if (util.success(entityCollection, error)) {
                    deferred.resolve(propertyDef, entityCollection);
                } else {
                    deferred.reject(propertyDef, error);
                }
            };

            var promise = deferred.promise();

            objectBuilder.createEntityCollection({
                assemblyName: assemblyName,
                entityTypeName: propertyDef.entityTypeName,
                entityCollectionName: propertyDef.entityCollectionName,
                copyFrom: copyFrom,
                callback: createCollectionCallback
            });

            return promise;
        };

        var resolveType = function (propertyDef, propertyValue) {
            var resolvedType = propertyDef.type;

            if (propertyDef.type === enums.propertyTypes.typeObject && propertyValue !== null) {

                if (propertyValue.hasOwnProperty('_Entity')) {
                    resolvedType = enums.propertyTypes.typeEntity;
                } else if (propertyValue.hasOwnProperty('_EntityCollection')) {
                    resolvedType = enums.propertyTypes.typeEntityCollection;
                }
            }
            return resolvedType;
        };

        var copyPropertyValues = function (sourceEntity, targetEntity, propertyDefs, callback) {

            if (sourceEntity != null && propertyDefs != null) {

                //Collection of functions that must be invoked in order to create child entities.
                var createChildEntityFunctions = [];
                for (var index = 0; index < propertyDefs.length; index++) {
                    var propertyDef = propertyDefs[index];
                    var currentPropertyName = propertyDef.name;

                    //Property defined in current instance and source entity
                    if (util.isDefined(targetEntity._propertyValues[currentPropertyName]) &&
                            (util.isDefined(sourceEntity[currentPropertyName]) /*|| util.isDefined(sourceEntity._propertyValues[currentPropertyName])*/)) {

                        var promise = null;
                        var propertyOriginalValue = sourceEntity[currentPropertyName];

                        var propertyValue = null;

                        //Gets the property type. This change was made for the properties with custom Type (IEntity), it can be serialized like a Modularis entity.
                        var currentPropertyType = resolveType(propertyDef, propertyOriginalValue);

                        if (propertyOriginalValue != null) {
                            switch (currentPropertyType) {
                                case enums.propertyTypes.typeEntity:
                                    promise = createEntity(propertyDef, propertyOriginalValue);
                                    break;
                                case enums.propertyTypes.typeEntityCollection:
                                    promise = createEntityCollection(propertyDef, propertyOriginalValue, targetEntity._serializationMetadata.AssemblyName);
                                    break;
                                default:
                                    propertyValue = propertyOriginalValue;
                            }
                        }

                        if (promise != null) {
                            createChildEntityFunctions.push(promise);
                        } else {
                            targetEntity._setPropertyValue(currentPropertyName, propertyValue, false);
                        }
                    }
                }

                /* There are some other properties (like DisplayValues -DV-) that are not defined in the propertyDefs, 
                 * but were somehow added to the sourceEntity. Those values will copied to the targetEntity without any transformation. */
                for (var customProperty in sourceEntity) {
                    if (sourceEntity.hasOwnProperty(customProperty) && !(customProperty in targetEntity)) {
                        /*eslint-disable no-param-reassign*/
                        targetEntity[customProperty] = util.copy(sourceEntity[customProperty]);
                        /*eslint-enable no-param-reassign*/
                    }
                }

                if (createChildEntityFunctions.length > 0) {
                    $.when.apply($, createChildEntityFunctions)
                        .done(function () {

                            //Each position of the arguments object contains the response data sent by each promise.
                            if (arguments.length > 0) {
                                var index;
                                if (util.isArray(arguments[0])) {
                                    //In some cases, each position of the arguments object is an array with two objects.
                                    for (index = 0; index < arguments.length; index++) {
                                        targetEntity._setPropertyValue(arguments[index][0].name, arguments[index][1], false);
                                    }
                                } else {
                                    //In other cases, each position of the arguments object contains a single entity instead of an array
                                    var nextIndex = 2;
                                    for (index = 0; index < arguments.length; index += nextIndex) {
                                        targetEntity._setPropertyValue(arguments[index].name, arguments[index + 1], false, false);
                                    }
                                }
                            }

                            util.notify(callback, targetEntity, null);
                        })
                        .fail(function () {
                            util.notify(callback, null, 'failed');
                        });
                } else {
                    util.notify(callback, targetEntity, null);
                }

            }
        };


        /**
         * @classdesc Base class for all the generated entities.
         * @constructs modularis.EntityBase
         * @param {Object} initialEntityState - Object with the default state for the entity.
         * @param {boolean} initialEntityState.AllowNulls - Allows null values to be returned for value types. Currently not supported in the web platform.
         * @param {string} initialEntityState.AssemblyName - Assembly to which the entity belongs.
         * @param {Array} initialEntityState.Attributes - Array of entity attributes.
         * @param {string} initialEntityState.Culture - Culture code.
         * @param {boolean} initialEntityState.Dirty - Dirty flag.
         * @param {modularis.enums.editModes} initialEntityState.EditMode - Initial edit mode.
         * @param {Array} initialEntityState.Errors - Array of entity errors.
         * @param {string} initialEntityState.Name - Entity name.
         * @param {string} initialEntityState.Type - Entity type. 
         * @param {boolean} initialEntityState.Valid - Valid flag.
         * @param {Object[]} entityProperties - Array with entity properties.
         * @param {string} entityProperties[].name - Property name.
         * @param {modularis.enums.propertyTypes} entityProperties[].type - Property type.
         * @param {string} keyStructure - The key structure of the entity.
         */
        var EntityBase = function (initialEntityState, entityProperties, keyStructure) {
            //EntityBase constructor
            var that = this;

            //Properties
            that._propertyValues = {};
            that._entityMetadata = null;
            that._propertyDefs = entityProperties;
            that._keyStructure = keyStructure;
            that._serializationMetadata = util.copy(initialEntityState);
            that._key = null;
        };

        //Methods available for all entities.
        EntityBase.prototype = {
            /**
             * Copies all the properties and metadata from the source entity into the current entity.
             * @param {modularis.EntityBase} sourceEntity - Source entity with the data to be copied.
             * @param {Function} callback - The function to be executed when the process is completed.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             */
            copyFrom: function (sourceEntity, callback) {
                var that = this;
                that._serializationMetadata = updateSerializationMetadata(sourceEntity, that._serializationMetadata);
                copyPropertyValues(sourceEntity, that, that._propertyDefs, callback);
            },

            /**
             * Returns an object that provides functions to access entity metadata and state.
             * @instance
             * @memberOf modularis.EntityBase
             * @returns {modularis.EntityMetadata} 
             */
            getEntityMetadata: function () {
                var that = this;

                var entityMetadata = new entityMetadataFactory.EntityMetadata(that);
                return entityMetadata;
            },

            /**
             * Returns the value of the property specified by the parameter propertyName.
             * @param {string} propertyName - Property name to get.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             * @returns {string|object}
             */
            getPropertyValue: function (propertyName) {
                return this._propertyValues[propertyName];
            },

            /**
             * Changes the value of the given property.
             * @param {string} propertyName - Property name.
             * @param {Object} propertyValue - New property value.
             * @param {boolean} [suppressDirty=false] - Set to true if the entity Dirty flag should not be changed to true.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             */
            setPropertyValue: function (propertyName, propertyValue, suppressDirty) {
                var changeFlag = true;
                if (suppressDirty) {
                    changeFlag = !suppressDirty;
                }
                this._setPropertyValue(propertyName, propertyValue, changeFlag, true);
            },

             /**
             * Transforms the Entity to a plain object with its metadata.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             * @param {string} currentCulture - Current culture.
             * @return {object}
             */
            getInstanceWithSerializationMetadata: function (currentCulture) {
            /* eslint-disable no-continue */
                var that = this,
                    isEntityBase = that instanceof EntityBase;

                var instanceWithMetadata = { _Entity: that._serializationMetadata };//util.copy({ _Entity: that._serializationMetadata });
                var entityMetaData = that.getEntityMetadata();
                //Assign Culture
                instanceWithMetadata._Entity.Culture = currentCulture === undefined ? localization.getCurrentCulture() : currentCulture;
                var properties = Object.keys(that._propertyValues);

                //Serialize entities and collections
                for (var idx = 0; idx < properties.length; idx++) {
                    var propertyName = properties[idx];
                    var propValue = that._propertyValues[propertyName];
                    var entityProValue = that[propertyName];

                    //Ignore empty properties 
                    if ((propValue === entityProValue) && (propValue === null || propValue === '' || propValue === undefined)) {
                        continue;
                    }

                    var propertyDef = entityMetaData.getPropertyDef(propertyName);
                    var type = propertyDef.type;

                    // Ignore empty collections
                    if (type === types.typeEntityCollection) {
                        var ignore = true;
                        if (propValue && propValue.Items && propValue.Items.length > 0) {
                            ignore = false;
                        }

                        if (entityProValue && entityProValue.Items && entityProValue.Items.length > 0) {
                            ignore = false;
                        }

                        if (ignore) {
                            continue;
                        }
                    }

                    var isModularisObject = type === types.typeEntity || type === types.typeEntityCollection || type === types.typeObject;
                    instanceWithMetadata[propertyName] = that[propertyName];

                    if (that._propertyValues[propertyName] !== null) {
                        if (isModularisObject && that._propertyValues[propertyName] !== '' && that._propertyValues[propertyName] !== undefined) {

                            if (util.isFunction(that._propertyValues[propertyName].getInstanceWithSerializationMetadata)) {
                                if (isEntityBase) {
                                    instanceWithMetadata[propertyName] = that._propertyValues[propertyName].getInstanceWithSerializationMetadata();
                                } else if (that[propertyName] && util.isFunction(that[propertyName].getInstanceWithSerializationMetadata)) {
                                    instanceWithMetadata[propertyName] = that[propertyName].getInstanceWithSerializationMetadata();
                                }
                            }
                        }
                    } else {
                        //Check special case when an entity property could have been changed to the object that represents the lookup
                        if (that[propertyName] && util.isObject(that[propertyName]) && !isModularisObject) {
                            if (propertyName in that[propertyName]) {
                                instanceWithMetadata[propertyName] = that[propertyName][propertyName];
                            }
                        } else {
                            // It is possible that a UI framework like Kendo UI may change the entity properties from ECMAScript5 format to plain properties.
                            //  In that case, it is possible that values stored in _properties are outdated.
                            if (that[propertyName] && !isEntityBase && util.isDefined(that[propertyName].getInstanceWithSerializationMetadata)) {
                                instanceWithMetadata[propertyName] = that[propertyName].getInstanceWithSerializationMetadata();
                            }
                        }
                    
                    }
                }

                if (util.isDefined(instanceWithMetadata.EntityDefID) && util.isDynamicEntity(instanceWithMetadata.EntityDefID)) {
                    for (var field in that) {
                        if (that.hasOwnProperty(field)) {
                            if (field.indexOf('_') !== 0 && field !== 'uid' && !util.isFunction(field) && !(field in instanceWithMetadata) && field[0].toUpperCase() === field[0]) {

                                instanceWithMetadata[field] = that[field];
                                //EntityViewModelBase = > Save cambiar el Dirty = true; entity.getEntityMetadata().setDirty(true);
                            }
                        }
                    }
                }

                return instanceWithMetadata;
            },

             /**
             * Returns the entity key.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             * @return {string}
             */
            getKey: function () {
                var that = this;
                if (!that._key) {
                    that._key = '';
                    var separator = '.',
                        keyStructure = that.getEntityMetadata().getKeyStructure(),
                        keyProperties = keyStructure.split(separator),
                        isEntityBase = that instanceof EntityBase;

                    for (var index = 0; index < keyProperties.length; index++) {
                        var propertyName = keyProperties[index];
                        that._key += isEntityBase ? that.getPropertyValue(propertyName) : that[propertyName];
                        if (index !== (keyProperties.length - 1)) { that._key += separator; }
                    }
                }
                return that._key;
            },

             /**
             * Rebuilds the entity key.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             */
            rebuildKey: function () {
                var that = this;
                that._key = null;
                that.getKey();
            },

             /**
             * Transforms the Entity to a JSON string.
             * 
             * @instance
             * @memberOf modularis.EntityBase
             * @function toJSON
             * @return {string}
             */
            toJSON: function () {
                return JSON.stringify(this.getInstanceWithSerializationMetadata());
            },

            /**
            * Deletes the entity instance.
            * @name delete
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @param {boolean} affectChildren - Indicates whether to process child entities or not.
            * @memberof modularis.EntityBase
            * 
            * @returns {Promise<boolean>} Promise object represents the if the entity was delete 
            */
            delete: function (options, affectChildren) {
                var that = this;
                options = options || {};
                that.getEntityMetadata().setEditMode('Delete');

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.deleteEntity(that, affectChildren, options, function (result, error) {
                        if (util.success(result, error)) {
                            resolve(true);
                        }
                        else{
                            reject(false);
                        }
                    });
                });
            },


            /**
             * Creates and initializes a new instance based on the given entityTypeName.
             * @name getNew
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @memberof modularis.EntityBase
             * 
             * @returns {Promise<boolean|string>} Promise object represents the if the entity was initialized. 
             * Rejected when the entity was not initialized.
             */
            initialize: function (options) {
                var that = this;
                options = options || {};
                var entityName = that.getEntityMetadata().getEntityName();
                var entityTypeName = entityHelper.getEntityTypeByEntityName(entityName);

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.getNew(entityTypeName, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.copyFrom(result, function (initilizedEntity) {
                                if (initilizedEntity) {
                                    resolve(true);
                                } else {
                                    reject('Unable to initialize the entity');
                                }
                            });
                        }
                    });
                });
            },

            /**
            * Loads the entity instance with the entity identified by a key.
            * @name getByKey
            * @param {string} key - The primary key of the requested entity.
            * @param {boolean} getChildren - If true, every entity is fetched with its child entities. If false, only the top-level entities are fetched.
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @memberof modularis.EntityBase
            * 
            * @returns {Promise<boolean|string>} Promise object represents the if the entity was loaded.
            * Rejected when the entity was not loaded.
            */
            load: function (key, getChildren, options) {
                var that = this;
                options = options || {};
                var entityName = that.getEntityMetadata().getEntityName();
                var entityTypeName = entityHelper.getEntityTypeByEntityName(entityName);

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.getByKey(entityTypeName, key, getChildren, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.copyFrom(result, function (loadedEntity) {
                                if (loadedEntity) {
                                    resolve(loadedEntity);
                                } else {
                                    reject('Unable to load the entity');
                                }
                            });
                        }
                    });
                });
            },

            /**
            * Creates a duplicate of the entity instance.
            * @name duplicate
            * @param {boolean} duplicateChildren - Indicates whether or not to duplicate all child entity instances.
            * @param {Object} options - A plain object representing any custom actions to be performed during execution.
            * @memberof modularis.EntityBase
            *
            * @returns {Promise<EntityBase|string>} Promise object resolved with the duplicated entity.
            * Rejected when the entity was not duplicated.
            */
            duplicate: function (duplicateChildren, options) {
                var that = this;
                options = options || {};
                duplicateChildren = duplicateChildren || false;

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.duplicate(that, duplicateChildren, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(result);
                        }
                    });
                });
            },

            /**
             * Saves the entity instance and its child entities
             * @name save
             * @param {boolean} validateFirst - Validates the entity and its children if true. Otherwise, the validation is bypassed.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {boolean} affectChildren - Indicates whether to process child entities or not.
             * @memberof  modularis.EntityBase
             *
             * @returns {Promise<boolean|string>} Promise object represents the if the entity was saved.
             * Rejected when the entity was not saved.
             */
            save: function (validateFirst, options, affectChildren) {
                var that = this;
                options = options || {};

                if (!options[enums.options.refetch] !== false) {
                    options[enums.options.refetch] = true;
                }

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.save(that, validateFirst, affectChildren, options, function (result, error) {

                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            that.copyFrom(result, function (savedEntity) {
                                if (savedEntity) {
                                    resolve(that);
                                } else {
                                    reject('Unable to save the entity');
                                }
                            });
                        }
                    });
                });
            },

            /**
             * Invokes the business validation class for the entity to validate its data.
             * @name validate
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @memberof modularis.EntityBase
             * 
             * @returns {Promise<Array|string>} Promise object resolved with and array the errors.
             * Rejected when the entity can not be validated.
             */
            validate: function (options) {
                var that = this;
                options = options || {};

                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.validate(that, options, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(result.getEntityMetadata().getErrors());
                        }
                    });
                });
            },

            
            /**
            * Checks if the user identified by the current session has access to perform the activity operation on the entity instance.
            * @name checkSecurity
            * @param {number} activityID - The activity to check.
            * @param {boolean} checkChildren - Checks all the child entities security if true. Otherwise, it only checks the top-level entity.
            * @memberof modularis.EntityBase
            * 
            * @returns {Promise<Array|string>} Promise object resolved with a boolean value that represents if the current session has acccess to perform the operation.
            * Rejected when the operation can not be performed.
            */
            checkSecurity: function (activityID, checkChildren) {
                var that = this;
                return new Promise(function (resolve, reject) {
                    RESTActivityWebServiceProxy.checkSecurity(activityID, that, checkChildren, function (result, error) {
                        if (!util.success(result, error)) {
                            reject(error);
                        } else {
                            resolve(true);
                        }
                    });
                });
            },

            _getCollectionValue: function (propertyName, assemblyName) {
                var that = this;
                var propertyValue = that.getPropertyValue(propertyName);
                if (propertyValue == null) {
                    var propertyDef = that.getEntityMetadata().getPropertyDef(propertyName);
                    propertyValue = new entityCollectionBase.EntityCollectionBase(assemblyName, propertyDef.entityCollectionName, propertyDef.entityTypeName);
                    //that.setPropertyValue(propertyName, propertyValue);
                    that._setPropertyValue(propertyName, propertyValue, false);
                }
                return propertyValue;
            },

            _setPropertyValue: function (propertyName, propertyValue, changeFlag, storeOriginalValue) {
                var that = this;

                var transformedPropertyValue = propertyValue;

                if (propertyValue != null) {
                    var propertyDef = that.getEntityMetadata().getPropertyDef(propertyName);
                    if (propertyDef != null) {

                        //Convert date if necessary
                        if ((propertyDef.type === enums.propertyTypes.typeDateTime) && !(propertyValue instanceof Date)) {
                            transformedPropertyValue = new Date(propertyValue);
                        }
                    }
                }

                if (that._propertyValues[propertyName] !== transformedPropertyValue) {
                    var currentValue = that._propertyValues[propertyName];

                    that._propertyValues[propertyName] = transformedPropertyValue;
                    if (that[propertyName] !== transformedPropertyValue) {
                        that[propertyName] = transformedPropertyValue;
                    }

                    if (storeOriginalValue) {
                        that.getEntityMetadata()._storeOriginalValue(propertyName, currentValue);
                    }
                    if (util.isDefined(changeFlag) && changeFlag !== null) {
                        if (changeFlag) {
                            that._serializationMetadata.Dirty = true;
                        }
                    }
                }

                //Reset key if needed
                if (that._keyStructure.indexOf(propertyName) >= 0) {
                    that._key = null;
                }
            }

        };

        /**
         * Transforms the Entity to a plain object with its metadata.
         * 
         * @instance
         * @memberOf modularis.EntityBase
         * @function toPlainObject
         * @return {object}
         */
        EntityBase.prototype.toPlainObject = EntityBase.prototype.getInstanceWithSerializationMetadata;

        return {
            EntityBase: EntityBase
        };
    }

);
