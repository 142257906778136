define('util',
    ['jquery', 'require', 'configLoader'],
    function ($, require, configLoader) {
        'use strict';

        var okHttpCode = 200,
            sixteenBase = 16,
            three = 0x3,
            eight = 0x8;

        /**
          * Modularis Utility Functions
          * @namespace util
          * @memberof modularis
          */
        var util = {
             /**
             * Validates if the given entityDefID belongs to a dynamic entity.
             * 
             * @param {string} entityDefID - Entity definition ID to validate.
             * @returns {boolean}  - Returns true if the given given entityDefID belongs to a dynamic entity; otherwise returns false.
             * @memberOf modularis.util
             */
            isDynamicEntity: function (entityDefID) {
                return entityDefID.indexOf('Modularis.Dynamic.Entity') > -1;
            },
            /**
             * Removes all the elements from the given array.
             * @param {Array} array - Array to be cleared.
             * @memberOf modularis.util
             */
            clearArray: function (array) {
                /*eslint-disable no-param-reassign*/
                if (this.isArray(array)) {
                    array.length = 0;
                } else if (this.isFunction(array.splice)) {
                    //this case applies for object that are not true JavaScript arrays, but have a splice function
                    array.splice(0, array.length);
                }
                /*eslint-enable no-param-reassign*/
            },

            /**
             * Copies the given object into a new instance. 
             * @param {object} original  - Original object.
             * @returns {object} - New copied object instance.
             * @memberOf modularis.util
             */
            copy: function (original) {
                var that = this;
                //Implementation taken from http://stackoverflow.com/a/728694/1601530
                var copy;

                // Handle the 3 simple types, and null or undefined
                if (null == original || !that.isObject(original)) { return original; }

                // Handle Date
                if (original instanceof Date) {
                    copy = new Date();
                    copy.setTime(original.getTime());
                    return copy;
                }

                // Handle Array
                if (original instanceof Array) {
                    copy = [];
                    for (var index = 0, len = original.length; index < len; index++) {
                        copy[index] = that.copy(original[index]);
                    }
                    return copy;
                }

                // Handle Files
                if (original instanceof File) {
                    copy = new File([original], original.name, { type: original.type });
                    return copy;
                }

                if(original instanceof FileList) {
                    
                    return original;
                }

                // Handle Object
                if (original instanceof Object) {
                    copy = {};
                    for (var attr in original) {
                        if (original.hasOwnProperty(attr)) {
                            copy[attr] = that.copy(original[attr]);
                        }
                    }
                    return copy;
                }

                throw new Error('Unable to copy obj! Its type isn\'t supported.');
            },

            /**
             * Generates a new Globally Unique Identifier (GUID).
             * @function newGuid
             * @returns {string}  - Generated Globally Unique Identifier
             * @memberOf modularis.util
             */
            newGuid: function () {
                //Taken from http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (match) {
                    var random = Math.random() * sixteenBase | 0,
                        result = match === 'x' ? random : (random & three | eight);
                    return result.toString(sixteenBase);
                });
            },

            /**
             * Calls the given callback function, passing the response and error objects.
             * @function notify
             * @param {function} callback - Function to be called.
             * @param {object} responseObject - Response object.
             * @param {object} errorObject - Response error object.
             * @memberOf modularis.util
             */
            notify: function (callback, responseObject, errorObject) {
                if (typeof (callback) == 'function') {
                    callback(responseObject, errorObject);
                }
            },

            /**
             * Validates whether the given collection contains items or not.
             * @param {modularis.EntityCollectionBase} entityCollection - Entity collection to check.
             * @returns {boolean}
             * @memberOf modularis.util
             */
            hasItems: function (entityCollection) {
                return entityCollection && entityCollection.Items && (entityCollection.Items.length > 0);
            },

            /**
             * Validates if the given value is an Array instance.
             * @function isArray
             * @param {Array} array - Array to validate.
             * @returns {boolean}  - Returns true if the given parameter is an Array; otherwise returns false.
             * @memberOf modularis.util
             */
            isArray: function (array) {
                return (array instanceof Array);
            },

            /**
             * Validates whether the given object is defined or not.
             * @param {Object} object - Object to check.
             * @returns {boolean} 
             * @memberOf modularis.util
             */
            isDefined: function (object) {
                return typeof (object) !== 'undefined';
            },

            /**
             * Validates if the given object is an empty object.
             * @function isEmptyObject
             * @param {object} object - Object to validate.
             * @returns {boolean}  - Returns true if the given object is an empty object; otherwise returns false.
             * @memberOf modularis.util
             */
            isEmptyObject: function (object) {
                for (var property in object) {
                    if (object.hasOwnProperty(property)) {
                        return false;
                    }
                }

                return true;
            },

            /**
             * Validates if the given parameter is a function.
             * @function isFunction
             * @param {function} fn - Function to validate.
             * @returns {boolean}  - Returns true if the given parameter is a function; otherwise returns false.
             * @memberOf modularis.util
             */
            isFunction: function (fn) {
                return typeof (fn) === 'function';
            },

            /**
             * Validates if the given value is an object instance.
             * @function isObject
             * @param {object} object - Object to validate.
             * @returns {boolean}  - Returns true if the given parameter is an object; otherwise returns false.
             * @memberOf modularis.util
             */
            isObject: function (object) {
                return typeof (object) === 'object';
            },

            /**
             * Checks whether the given attribute is a string or not.
             * @param {string} string - Object to check.
             * @returns {boolean} 
             * @memberOf modularis.util
             */
            isString: function (string) {
                return typeof (string) === 'string';
            },

            /**
             * This functions is normally invoked to validate the result of an asynchronous task. It establishes if the task was successful
             * checking the response object and the error object.
             * @param {Object} responseData - The object that represents a successful execution of the async task.
             * @param {Object} responseError - The object that represents an error in the execution of the async task.
             * @param {boolean} [allowNullData] - Indicates if null data will be accepted as successful.
             * @returns {boolean} 
             * @memberOf modularis.util
             */
            isSuccessfulResponse: function (responseData, responseError, allowNullData) {
                return this.success(responseData, responseError, allowNullData);
            },

            /**
             * This functions is normally invoked to validate the result of an asynchronous task. It establishes if the task was successful
             * checking the response object and the error object.
             * @param {Object} responseData - The object that represents a successful execution of the async task.
             * @param {Object} responseError - The object that represents an error in the execution of the async task.
             * @param {boolean} [allowNullData] - Indicates if null data will be accepted as successful.
             * @returns {boolean} 
             * @memberOf modularis.util
             */
            success: function (responseData, responseError, allowNullData) {
                var result = (allowNullData || responseData !== null) && (responseError == null);

                if (!result) {
                    //Check scenario where error object contains XMLHTTPRequest successful response data
                    if (responseError && responseError.status && responseError.statusText) {
                        result = (responseError.status === okHttpCode);
                    }
                }

                return result;
            },

            /**
             * If available, it returns the global "require" function defined by RequireJS.
             * @returns {Function} 
             * @memberOf modularis.util
             */
            getRequireJSFunction: function () {
                var requireFunction;
                if (this.isDefined(window.requirejs)) {
                    requireFunction = window.requirejs;
                } else {
                    requireFunction = require;
                }
                return requireFunction;
            },

            /**
             * Gets the url suffix to use in order to prevent file caching, using the configured url args.
             * @returns {string}  - Url args.
             * @memberOf modularis.util
             */
            getCacheUrlArgs: function () {
                var urlArgsValue = configLoader.appConfig.urlArgs;
                var urlArgs = '?' + urlArgsValue;
                return urlArgs;
            },

            /**
            * Sets the property display value as the value of the property or as value of another target property.
            * @param {Object} entity - Entity instance.
            * @param {string} properyName - Property to get the associated display value.
            * @param {string} targetProperty - Property to set the display value. If not provided, the value will be set to the same property.
            */
            setPropertyDisplayValue: function (entity, properyName, targetProperty) {
                var displayValueAttributeName = properyName + '.DV';

                var displayValue = entity._Entity.Attributes.find(function (arg) { return arg[displayValueAttributeName] != null; });

                if (displayValue) {
                    if (targetProperty) {
                        properyName = targetProperty;
                    }
                    entity[properyName] = displayValue[displayValueAttributeName];
                }
            },

            /**
             * Return a custom boolean editor to match the kendo theme styles for the checkbox and besides prevent an issue with the boolean required values. 
             * @param {jquery} container - The jquery object representing the container element.
             * @param {Object} options - The column options.
             * @param {String} options.field - The name of the field to which the column is bound.
             */
            boolEditor: function (container, options) {
                var guid = util.newGuid();
                $('<input class="k-checkbox" id="' + guid + '" type="checkbox" name="' + options.field + '" data-type="boolean" data-bind="checked:' + options.field + '">').appendTo(container);
                $('<label class="k-checkbox-label" for="' + guid + '">​</label>').appendTo(container);
            },

            /**
             * Gets the web server base URL.
             * @returns {string}  - Web server base URL.
             * @memberOf modularis.util
             */
            getWebServerBaseUrl: function () {
                var webServerBaseUrl = window.location.origin;

                if (window.location.pathname) {
                    webServerBaseUrl += window.location.pathname;
                }

                if (!webServerBaseUrl.endsWith('/')) {
                    webServerBaseUrl += '/';
                }

                return webServerBaseUrl;
            },

            /**
             * Convert an Object to a JSON Object.
             * @function toJSONObject
             * @param {object} object - Object to convert.
             * @returns {object}  - Returns a JSON object.
             * @memberOf modularis.util
             */
            toJSON: function (object) {
                var objectString = JSON.stringify(object);
                var result = JSON.parse(objectString);

                return result;
            }

        };

        return util;

    }
);
