define('mediator/colleagueBase',
    ['util', 'mediator/mainMediator'],
    function (util, MainMediator) {
        'use strict';

        /**
        * @classdesc Base class that enables the colleague to send and receive messages using a mediator.
        * 
        * @param {string} [colleagueId] - Unique identifier of the colleague. 
        * If the unique identifier is provided in the constructor, the colleague will be automatically registered to the specified {@link modularis.web.mediator.MediatorBase|Mediator Base} instance.
        * @param {MediatorBase} [mediator] - Mediator to be used by this colleague. If no mediator is provided, the {@link modularis.web.mediator.MainMediator|Main Mediator} will be used.
        * @constructs modularis.web.mediator.ColleagueBase
        */
        var ColleagueBase = function (colleagueId, mediator) {
            var that = this;

            //#region Properties
            that._colleagueId = colleagueId;
            that._mediator = mediator;

            that.registerAsColleague();
            //#endregion
        };


        //#region Event handlers

        /**
        * Gets the instance of the mediator used by this colleague. 
        * If there is not an assigned mediator, the singleton instance of {@link modularis.web.mediator.MainMediator|Main Mediator} used.
        * @function getMediator
        * @instance
        * @memberOf modularis.web.mediator.ColleagueBase
        * @returns {modularis.web.mediator.MediatorBase}
        * 
        */
        ColleagueBase.prototype.getMediator = function () {
            var that = this;
            if (!that._mediator) {
                var mediator = MainMediator.getInstance();
                that._mediator = mediator;
            }
            return that._mediator;
        };

        /**
         * Sets the instance of the mediator to be used by this colleague.
         * If the colleague already have a mediator, it will be unregistered.
         * 
         * @function setMediator
         * @param {modularis.web.mediator.MediatorBase} mediator - Mediator to be assigned to this colleague.
         * @instance
         * @memberOf modularis.web.mediator.ColleagueBase
         */
        ColleagueBase.prototype.setMediator = function (mediator) {
            var that = this;

            if (that._mediator) {
                that._mediator.unregister(that._colleagueId);
            }

            that._mediator = mediator;

            if (mediator && that._colleagueId) {
                that.registerAsColleague(that._colleagueId);
            }
        };

        /**
         * Registers the colleague instance to the mediator, using the given colleague id.
         * 
         * @function registerAsColleague
         * @param {string} colleagueId - Unique identifier of the current colleague instance.
         * @instance
         * @memberOf modularis.web.mediator.ColleagueBase
         */
        ColleagueBase.prototype.registerAsColleague = function (colleagueId) {
            var that = this;
            var resolvedColleagueId = colleagueId ? colleagueId : that._colleagueId;
            that.unregisterAsColleague();

            var mediator = that.getMediator();
            that._colleagueId = resolvedColleagueId;
            mediator.register(that);
        };

        /**
        * Unregisters the current instance as a colleague of the mediator.
        * 
        * @function unregisterAsColleague
        * @instance
        * @memberOf modularis.web.mediator.ColleagueBase
        */
        ColleagueBase.prototype.unregisterAsColleague = function () {
            var that = this;
            var mediator = that.getMediator();
            mediator.unregister(that._colleagueId);
        };

        /**
        * Sends a message to other colleagues using the mediator.
        * @function sendMessage
        * @param {string} messageType - Message type.
        * @param {object} [payload] - Payload object to be included in the message.
        * @param {string} [targetColleagueId] - Unique identifier of the target colleague. If it is not provided, the message is sent to all the colleagues registered in the mediator.
        * @param {boolean} [isAsync] - Indicates if the message must be sent asynchronously to the colleagues. Its default value is true.
        * @instance
        * @memberOf modularis.web.mediator.ColleagueBase
        */
        ColleagueBase.prototype.sendMessage = function (messageType, payload, targetColleagueId, isAsync) {
            var that = this;
            var mediator = that.getMediator();
            mediator.sendMessage(that._colleagueId, messageType, payload, targetColleagueId, isAsync);
        };

        ColleagueBase.prototype._receiveMessage = function (senderId, messageType, payload, isAsync) {
            var that = this;

            if (util.isFunction(that.onReceiveMessage)) {
                that.onReceiveMessage(senderId, messageType, payload, isAsync);
            }

        };

        /*eslint-disable no-unused-vars*/

        /**
        * Function used by the mediator to pass messages to the Colleague.
        * This function must be overwritten by the colleagues in order to be able to receive messages.
        * @function onReceiveMessage
        * @param {string} senderId - Unique identifier of the sender colleague.
        * @param {string} messageType - Message type.
        * @param {object} [payload] - Payload object included in the message.
        * @param {boolean} [isAsync] - Indicates if the message was received asynchronously.
        * @instance
        * @memberOf modularis.web.mediator.ColleagueBase
        */
        ColleagueBase.prototype.onReceiveMessage = function (senderId, messageType, payload, isAsync) {

        };

        /*eslint-enable no-unused-vars*/

        //#endregion

        return ColleagueBase;

    }
);
