define('dataSource',
    ['jquery', 'kendo', 'modularisData/commandAdaptee', 'modularisData/activityAdaptee', 'modularisData/modelAdaptee', 'RESTNotificationWebServiceProxy', 'entityCollectionBase', 'entityHelper', 'util', 'enums'],
    function ($, kendo, CommandAdaptee, ActivityAdaptee, ModelAdaptee, notificationServiceProxy, entityCollectionBaseFactory, entityHelper, util, enums) {
        'use strict';

        var deleteMode = enums.editModes.deleteMode,
            updateMode = enums.editModes.update,
            notificationEvent = enums.notificationEventName;

        var entityNotificationEvents = [notificationEvent.onAddEntity, notificationEvent.onUpdateEntity, notificationEvent.onDeleteEntity,
        notificationEvent.onAddEntityCollection, notificationEvent.onUpdateEntityCollection, notificationEvent.onDeleteEntityCollection];
        var notificationReceivedEvent = 'notificationReceived';

        var KendoDataSource = kendo.data.DataSource;
        var defaultOptions = {
            modularis: {
                filterDeleteModeEntities: false,
                realTimeNotifications: false
            }
        };

        var getDataAccessAdaptee = function (options) {

            var adaptee = null;
            if (options && options.modularis) {

                //command adaptee
                if (options.modularis.command) {
                    adaptee = new CommandAdaptee(options.modularis.command);
                } else if (options.modularis.activity) {
                    //activity adaptee
                    adaptee = new ActivityAdaptee(options.modularis.activity);
                } else if (options.modularis.model) {
                    //model adaptee
                    adaptee = new ModelAdaptee(options.modularis.model);
                }
                if (adaptee) {
                    adaptee.setDataSourceOptions(options);
                }
            }
            return adaptee;
        };

        var getDefaultDataAccessAdaptee = function (options) {
            var defaultAdaptee = new ActivityAdaptee({});
            defaultAdaptee.setDataSourceOptions(options);
            return defaultAdaptee;
        };

        /*eslint-disable no-param-reassign*/
        var buildDataAccessOptions = function (adaptee, defaultAdaptee, options) {


            if (!options.transport) {
                options.transport = {};
            }

            if (!options.schema) {
                options.schema = {
                    type: 'json',
                    model: {}
                };
            }

            if (!options.schema.model) {
                options.schema.model = {};
            }

            if (!options.schema.model.id) {
                options.schema.model.id = '_key';
            }

            if (options.serverPaging && !options.schema.total) {
                options.schema.total = function () {
                    return adaptee.paginationTotalCount;
                };
            }

            //Set function to query data.
            if (adaptee && (!options.transport.read)) {
                options.transport.read = $.proxy(adaptee.read, adaptee);
            }

            var context, crudFunction;

            //Set function to save existing records
            if (!options.transport.update) {
                crudFunction = util.isFunction(adaptee.update) ? adaptee.update : defaultAdaptee.update;
                context = util.isFunction(adaptee.update) ? adaptee : defaultAdaptee;
                options.transport.update = $.proxy(crudFunction, context);
            }

            //Set function to save a new record
            if (!options.transport.create) {
                crudFunction = util.isFunction(adaptee.create) ? adaptee.create : defaultAdaptee.create;
                context = util.isFunction(adaptee.create) ? adaptee : defaultAdaptee;
                options.transport.create = $.proxy(crudFunction, context);
            }

            //Set function to delete record
            if (!options.transport.destroy) {
                crudFunction = util.isFunction(adaptee.destroy) ? adaptee.destroy : defaultAdaptee.destroy;
                context = util.isFunction(adaptee.destroy) ? adaptee : defaultAdaptee;
                options.transport.destroy = $.proxy(crudFunction, context);
            }

            //delete options.modularis;
        };

        var buildFilterOptions = function (dataSourceOptions) {

            dataSourceOptions.filter = dataSourceOptions.filter || [];
            dataSourceOptions.filter.push({
                operator: function (item) {
                    var editMode = item.getEntityMetadata().getEditMode();
                    return editMode !== deleteMode;
                }
            });
        };

        /*eslint-enable no-param-reassign*/

        //The following line was added to avoid ESLint invalid warning.
        /*eslint-disable valid-jsdoc*/

        /**
         * @classdesc DataSource class. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/data/datasource|kendo.data.DataSource}
         * @constructs modularis.web.DataSource
         * @param {Object} options - Configuration options. Check the options property to see the available settings.
         * 
         * @property {Object} options.modularis - Initialization options.
         * @property {string} [options.modularis.entityTypeName] - Entity type name. Needed if the data source will be bound to a grid that performs inline operations.
         * @property {boolean} [options.modularis.filterDeleteModeEntities] - If true, the data source will mark entities
         * with edit mode delete when the remove function is called. The deleted entities will remain in the data source
         * but will not be visible in a bound control. To get all the entities, including the deleted ones, use the {@link http://docs.telerik.com/kendo-ui/api/javascript/data/datasource#methods-data|data function};
         * to get the entities that were not deleted, use the {@link http://docs.telerik.com/kendo-ui/api/javascript/data/datasource#methods-view|view function}.
         * @property {boolean|object} [options.modularis.realTimeNotifications] -  If it is set to true or an object, the data source
         * will listen for {@link modularis.enums.notificationEventName|real-time notifications related to entities}  (onAddEntity, onUpdateEntity,
         * onDeleteEntity, onAddEntityCollection, onUpdateEntityCollection, onDeleteEntityCollection) and it will call the read function every time a notification
         * of interest is received.

         * @property {Array} options.modularis.realTimeNotifications.entitiesToMonitor - Entity type names of the entities whose real-time notifications are of interest.
         * @property {Function} options.modularis.realTimeNotifications.notificationReceived - Event handler that will called every time a notification of interest is received.
         */
        var DataSource = function (options) {
            var that = this;
            var dataSourceOptions = $.extend(true, {}, defaultOptions, options);
            that._adaptee = getDataAccessAdaptee(dataSourceOptions);
            //The default adaptee will be used in scenarios where the main adaptee does not provide the functions needed to create, update or delete.
            that._defaultAdaptee = getDefaultDataAccessAdaptee(dataSourceOptions);

            that._filterDeleteModeEntities = dataSourceOptions.modularis.filterDeleteModeEntities;
            //This might happen when a dataSource with no configuration is created.
            if (that._adaptee) {
                buildDataAccessOptions(that._adaptee, that._defaultAdaptee, dataSourceOptions);
            }

            if (that._filterDeleteModeEntities) {
                buildFilterOptions(dataSourceOptions);
            }
            KendoDataSource.fn.init.call(that, dataSourceOptions);
            that._realTimeEntitiesToMonitor = null;
            that._bindToRealTimeNotifications();
        };

        /*eslint-enable valid-jsdoc*/

        DataSource.prototype = new KendoDataSource();

        /**
         * This function overrides base class equivalent. If options.modularis.filterDeleteModeEntities is set to true and the entity is already in the data source as deleted,
         * the passed entity will be marked with the update mode and won't be added again preventing repeated items; otherwise it will implement the default
         * behavior.
         * @param {modularis.EntityBase|kendo.data.Model} item - Object to add.
         */
        DataSource.prototype.add = function (item) {
            var that = this;
            var entityMetadata = item.getEntityMetadata();
            var isAdd = true;
            if (that._filterDeleteModeEntities) {
                var data = that.data();
                var itemIndex = data.indexOf(item);
                if (itemIndex >= 0) {
                    isAdd = false;
                    var internalItem = data[itemIndex];
                    var itemMetadata = internalItem.getEntityMetadata();
                    if (itemMetadata === deleteMode) {
                        entityMetadata.setEditMode(updateMode);
                        var currentFilter = that.filter();
                        that.filter(currentFilter);
                    }
                }
            }

            if (isAdd) {
                KendoDataSource.fn.add.apply(this, arguments);
            }
        };

        /**
         * This function overrides base class equivalent. If options.modularis.filterDeleteModeEntities is set to true,
         * the passed entity will be marked with delete mode and won't be removed; otherwise it will implement the default
         * behavior.
         * @param {modularis.EntityBase|kendo.data.Model} item - Object to delete.
         */
        DataSource.prototype.remove = function (item) {
            var entityMetadata = item.getEntityMetadata();
            if (this._filterDeleteModeEntities && entityMetadata.getEditMode() === updateMode) {
                //Just mark the entity with delete editMode. Don't remove it from the DataSource.
                entityMetadata.setEditMode(deleteMode);
                var currentFilter = this.filter();
                this.filter(currentFilter);
            } else {
                KendoDataSource.fn.remove.apply(this, arguments);
            }
        };

        /**
         * Removes all the entities from the data source.
         */
        DataSource.prototype.clear = function () {
            this.data([]);
        };

        /**
         * Count the number of items in the datasource. 
         * If the filterDeleteModeEntities is set to true, the items marked as deleted are removed from the count.
         * @returns {number} - The total number of items in the datasource.
         * @memberOf modularis.web
         */
        DataSource.prototype.count = function () {
            var data = this.data();
            var totalCount = 0;

            if (!util.isDefined(data)) {
                return totalCount;
            }

            totalCount = data.length;

            if (this._filterDeleteModeEntities) {
                totalCount = data.filter(function (item) {
                    return item.getEntityMetadata().getEditMode() !== deleteMode;
                }).length;
            }

            return totalCount;
        };

        /**
         * Changes the initial parameters given to the data source to filter the data using the activity service, the command service or a model.
         * @param {Array} newParameters - Array with the new parameter values.
         * @param {boolean} executeRead - true if the read method should be executed after the parameters are changed.
         */
        DataSource.prototype.updateParameters = function (newParameters, executeRead) {
            this._adaptee.updateParameters(newParameters, executeRead);
            if (executeRead) {
                this.read();
            }
        };


        DataSource.prototype.updateEntityParameters = function (newParameters, executeRead) {
            this._adaptee.updateEntityParameters(newParameters, executeRead);
            if (executeRead) {
                this.read();
            }
        };

        /**
         * Updates the reference to the object that is used to extract the parameters needed to execute a query.
         * @param {Object} source - Parameter source.
         * @param {boolean} executeRead - If true, the read function will be called and the data will fetched again.
         */
        DataSource.prototype.updateParameterSource = function (source, executeRead) {
            this._adaptee.updateParameterSource(source);
            if (executeRead) {
                this.read();
            }
        };

         /**
         * Return the reference to the object that is used to to extract the parameters needed to execute a query.
         * @returns {object}
         */
        DataSource.prototype.getParameterSource = function () {
            return this._adaptee.getParameterSource();
        };

        /*eslint-disable consistent-return*/
        /**
        * Gets or sets the data items of the data source.
        * @param {Array|kendo.data.ObservableArray|modularis.EntityCollectionBase} [entityCollection] - Data to assign.
        * @returns {Array|kendo.data.ObservableArray} Data items when the parameter is null.
        */
        DataSource.prototype.data = function (entityCollection) {
            if (arguments.length === 0) {
                return KendoDataSource.fn.data.call(this);
            }

            var dataCollection = entityCollection;

            if (entityCollection instanceof entityCollectionBaseFactory.EntityCollectionBase) {
                dataCollection = entityCollection.Items;
            }

            KendoDataSource.fn.data.call(this, dataCollection);
        };
        /*eslint-enable consistent-return*/

        DataSource.prototype._handleNotification = function (event) {
            var that = this;
            //Entity event?
            if (entityNotificationEvents.indexOf(event.type) >= 0) {
                var notificationMessage = event.notificationMessage;
                if (that._isEntityOfInterest(notificationMessage)) {
                    //Refresh data source.
                    that.read();
                    //Trigger event
                    that.trigger(notificationReceivedEvent, {
                        notificationMessage: notificationMessage,
                        type: event.type
                    });
                }
            }
        };

        DataSource.prototype._isEntityOfInterest = function (notificationMessage) {
            var result = false;
            var messageObject = notificationMessage.MessageObject;
            var entityTypeName;
            if (messageObject._Entity) {
                entityTypeName = entityHelper.getEntityTypeByEntityName(messageObject._Entity.Name);
                result = this._realTimeEntitiesToMonitor.indexOf(entityTypeName) >= 0;
            }

            if (messageObject._EntityCollection) {
                entityTypeName = entityHelper.getEntityTypeByCollectionName(messageObject._EntityCollection.CollectionName);
                result = this._realTimeEntitiesToMonitor.indexOf(entityTypeName) >= 0;
            }

            return result;
        };

        DataSource.prototype._bindToRealTimeNotifications = function () {
            var that = this;
            if (!that.options.modularis || !that.options.modularis.realTimeNotifications) { return; }

            var entitiesToMonitor = that.options.modularis.realTimeNotifications.entitiesToMonitor;
            if (entitiesToMonitor) {
                that._realTimeEntitiesToMonitor = util.copy(entitiesToMonitor);
            } else {
                that._realTimeEntitiesToMonitor = that._adaptee.getRealTimeEntitiesToMonitor();
            }

            if (!util.isArray(that._realTimeEntitiesToMonitor) || that._realTimeEntitiesToMonitor.length <= 0) {
                throw new Error('Please provide an array of entities to monitor.');
            }

            //Bind to entity notification events
            var proxyFunction = $.proxy(that._handleNotification, that);
            notificationServiceProxy.onAddEntity(proxyFunction);
            notificationServiceProxy.onAddEntityCollection(proxyFunction);
            notificationServiceProxy.onUpdateEntity(proxyFunction);
            notificationServiceProxy.onUpdateEntityCollection(proxyFunction);
            notificationServiceProxy.onDeleteEntity(proxyFunction);
            notificationServiceProxy.onDeleteEntityCollection(proxyFunction);

            var notificationReceivedHandler = that.options.modularis.realTimeNotifications.notificationReceived;
            if (notificationReceivedHandler && util.isFunction(notificationReceivedHandler)) {
                that.bind(notificationReceivedEvent, notificationReceivedHandler);
            }
        };

        return DataSource;
    }
);
