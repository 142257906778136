define('viewModelBase',
    ['jquery', 'kendo', 'RESTActivityWebServiceProxy', 'RESTSecurityWebServiceProxy',
       'RESTCommandWebServiceProxy', 'RESTLogicalLockWebServiceProxy', 'RESTNotificationWebServiceProxy', 'localization', 'modularisBase/debug', 'util'],
    function ($, kendo, RESTActivityWebServiceProxy, RESTSecurityWebServiceProxy,
       RESTCommandWebServiceProxy, RESTLogicalLockWebServiceProxy, RESTNotificationWebServiceProxy, localization, debug, util) {
        'use strict';

        /**
         * @classdesc Base class for all the view-models. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/data/observableobject|kendo.data.ObservableObject}.
         * @constructs modularis.web.ViewModelBase
         * 
         * @property  {Function} translate - Reference to {@link modularis.localization.translate|modularis.localization.translate}
         * @property  {Function} translateEntityName - Reference to {@link modularis.localization.translateEntityName|modularis.localization.translateEntityName}
         * @property  {Function} translatePropertyName - Reference to {@link modularis.localization.translatePropertyName|modularis.localization.translatePropertyName}
         * @property  {Function} translateStringResource - Reference to {@link modularis.localization.translateStringResource|modularis.localization.translateStringResource}
         */

        var ViewModelBase = function() {

            var that = this;

            kendo.data.ObservableObject.fn.init.call(that);

            that._containerSelector = '#container';
            that.message = null;
            that.isMessageVisible = false;
            that.isSuccessMessage = true;
            that.showBusyIndicator = true;
           
            that._eventNameSpaceMapping = {};
        };


        ViewModelBase.prototype = new kendo.data.ObservableObject();
        
        //#region References to service proxies

        ViewModelBase.prototype.activityServiceProxy = RESTActivityWebServiceProxy;
        ViewModelBase.prototype.securityServiceProxy = RESTSecurityWebServiceProxy;
        ViewModelBase.prototype.commandServiceProxy = RESTCommandWebServiceProxy;
        ViewModelBase.prototype.logicalLockServiceProxy = RESTLogicalLockWebServiceProxy;
        ViewModelBase.prototype.notificationServiceProxy = RESTNotificationWebServiceProxy;

        //#endregion

        //#region Reference to localization functions

        // this properties are documented in the class definition
        ViewModelBase.prototype.translate = $.proxy(localization.translate, localization);
        ViewModelBase.prototype.translateEntityName = $.proxy(localization.translateEntityName, localization);
        ViewModelBase.prototype.translatePropertyName = $.proxy(localization.translatePropertyName, localization);
        ViewModelBase.prototype.translateStringResource = $.proxy(localization.translateStringResource, localization);

        //#endregion

        /**
         * Displays a loading mask on top of the DOM element specified by the _containerSelector attribute.
         * If the loading mask should be displayed in a different area, a jQuery selector must be provided indicating that 
         * area in the view.
         * @param {boolean} value - If true, the loading mask will be shown; otherwise, it will be hidden.
         * @param {string|jQuery} [targetSelector] - jQuery selector of the area to display the loading mask.
         */
        ViewModelBase.prototype.busy = function(value, targetSelector) {
            if (this.showBusyIndicator) {
                var selector = targetSelector;
                if (!selector) {
                    selector = this._containerSelector ? this._containerSelector : 'body';
                }
                kendo.ui.progress($(selector), value);
            }
        };

        /**
         * Hides all loading masks that are displayed on the screen.
         * If the loading mask is displayed in a different area, a jQuery selector must be provided indicating that 
         * area in the view.
         * @param {string[]|jQuery} [targetSelector] -  String array of jQuery selectors of the areas where the loading masks should be hidden.
         */
        ViewModelBase.prototype.hideLoadingMasks = function (targetSelector) {
            if (this.showBusyIndicator) {
                var selectors = targetSelector;

                if (!selectors) {
                    var loadingMasks = $('.k-loading-mask');

                    selectors = [];

                    for (var spinnerIndex = 0; spinnerIndex < loadingMasks.length; spinnerIndex++) {
                        var spinner = loadingMasks[spinnerIndex];
                        selectors.push(String.format('#{0}', spinner.parentElement.id));
                    }
                }

                for (var selectorIndex = 0; selectorIndex < selectors.length; selectorIndex++) {
                    var selector = selectors[selectorIndex];
                    kendo.ui.progress($(selector), false);
                }
            }
        };

         /**
         * Exposes a message to the view through  the message variable. The message variable is used in the generated views to show notifications about performed tasks.
         * @param {string} newMessage -  Message to be displayed.
         * @param {boolean} isSuccessMessage - Indicates if it is a success message or not.
         */
        ViewModelBase.prototype.displayMessage = function(newMessage, isSuccessMessage) {
            var that = this;
            that.set('message', newMessage);
            that.set('isMessageVisible', true);
            that.set('isSuccessMessage', isSuccessMessage);
        };

         /**
         * Exposes a translated message to the view through the message variable. The message variable is used in the generated views to show notifications about performed tasks.
         * 
         * @param {string} messageKey -  Translate key of the message to be displayed.
         * @param {boolean} isSuccessMessage - Indicates if it is a success message or not.
         */
        ViewModelBase.prototype.displayTranslatedMessage = function (messageKey, isSuccessMessage) {
            this.displayMessage(this.translate(messageKey), isSuccessMessage);
        };

        /**
         * Clears the value of the message variable. The message variable is used in the generated views to show notifications about performed tasks.
         */
        ViewModelBase.prototype.clearMessage = function() {
            this.set('message', null);
            this.set('isMessageVisible', false);
        };

        /**
         * Returns the CSS class to use in the message HTML element depending on the state of the isSuccessMessage flag.
         * 
         * @return {string}
         */
        ViewModelBase.prototype.messageClass = function () {
            var commonClass = 'k-widget k-notification ';
            return this.get('isSuccessMessage') ? commonClass + 'k-notification-success' : commonClass + 'k-notification-error';
        };

        /**
         * Function invoked when the associated view is going to be destroyed.
         * 
         */
        ViewModelBase.prototype.disposeViewModel = function () {

        };

        /**
         * Function called after the associated view is displayed.
         * 
         */
        ViewModelBase.prototype.afterViewIsDisplayed = function () {

        };

        /*eslint-disable no-unused-vars*/

        /**
         * Sets a property in the ViewModel.
         * 
         * @param {string} key -  Property name.
         * @param {any} value - Property value.
         */
        ViewModelBase.prototype.setValue = function (key, value) {
            var that = this;
            if (name in that) {
                that.set(name, value);
            }
        };

        /*eslint-enable no-unused-vars*/

       //#region Functions to simplify event binding from view-models.

         /**
         * Attaches a function handler to a global event..
         * 
         * @param {string} eventName - The name of the event.
         * @param {Function} eventListener - A function to execute each time the event is triggered. That function should have a single parameter which will contain any event specific data.
         *
         * @returns {string} The formatted event name.
         * */
        ViewModelBase.prototype.bindGlobalEvent = function (eventName, eventListener) {
           var namespace = util.newGuid();
           var fullEventName = String.format('{0}.{1}', eventName, namespace);
           $(document).on(fullEventName, $.proxy(eventListener, this));

           if (debug.isEnabled()) { debug.registerEventHandler(eventName); }

           this._eventNameSpaceMapping[eventName] = fullEventName;
           return fullEventName;
        };

         /**
         * Removes a previously attached event handler.
         * 
         * @param {string} eventName - The name of the event.
         * 
         * */
       ViewModelBase.prototype.unbindGlobalEvent = function(eventName) {
          if (eventName in this._eventNameSpaceMapping) {
             var fullEventName = this._eventNameSpaceMapping[eventName];
             $(document).off(fullEventName);
             if (debug.isEnabled()) { debug.unregisterEventHandler(eventName); }
             delete this._eventNameSpaceMapping[eventName];
          }
       };

        /**
         * Executes all handlers attached to the given event.
         * 
         * @param {string} eventName - The name of the event.
         * @param {object} [eventData] - Optional event data which will be passed as an argument to the event handlers.
         * */
       ViewModelBase.prototype.triggerGlobalEvent = function (eventName, eventData) {

          var eventObject = $.extend({}, eventData, {
             type: eventName
          });

          $.event.trigger(eventObject);
       };

       //#endregion

       return {
            ViewModelBase: ViewModelBase
        };
    }
);
