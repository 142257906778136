define('RESTWebServiceProxy',
    ['jquery', 'clientCache', 'objectBuilder', 'proxyHelper', 'configLoader', 'eventHelper', 'util', 'enums', 'libs/pako'],
    function ($, clientCache, objectBuilder, proxyHelper, configLoader, eventHelper, util, enums, pako) {
        'use strict';
        /*eslint-disable no-param-reassign*/

        /**
         * Request callback for asynchronous operations where the back-end platform or the web server are involved.
         * @callback requestCallback
         * @param {Object|string|modularis.EntityBase|modularis.EntityCollectionBase} responseObject - Response object when the request was successfully executed.
         * @param {Object|string} responseError - Error object when the request failed.
         */

        var defaultServerOption = enums.options.defaultServer,
            convertToJSEntityOption = enums.options.convertToJavaScriptEntity;
        var fullEventHandlerIDFormat = '{0}.{1}';

        var optionsToIgnore = [defaultServerOption, convertToJSEntityOption];

        var sendRequest = function (serviceName, methodName, requestData, parseToJSONString, callback, dataType, httpMethod, requestOptions) {
            var modifiedServiceName = serviceName,
                parseResponseToJSEntity = false,
                isDataCompressed = false,
                customHeaders = {};

            var activeServer = clientCache.getActiveServer();

            if (requestOptions) {

                if (defaultServerOption in requestOptions) {
                    activeServer = requestOptions[defaultServerOption];
                    delete requestOptions[defaultServerOption];
                }

                if (convertToJSEntityOption in requestOptions) {
                    parseResponseToJSEntity = requestOptions[convertToJSEntityOption];
                    delete requestOptions[convertToJSEntityOption];
                }
            }

            if (proxyHelper.handleServerConfigurationException(activeServer, callback)) { return; }

            var primaryURI = activeServer.primaryURI;
            if (primaryURI.endsWith('/')) {
                modifiedServiceName = serviceName + '.svc';
            } else {
                primaryURI += '/';
            }

            var url = primaryURI + modifiedServiceName + '/' + methodName;
            var ajaxRequestData = requestData;
            if ((!requestOptions) || util.isEmptyObject(requestOptions) || (requestOptions.processData)) {
                if (parseToJSONString) {
                    ajaxRequestData = JSON.stringify(requestData);

                    if (configLoader.appConfig.compressJSONRequests) {
                        ajaxRequestData = pako.gzip(ajaxRequestData, { to: 'string' });
                        isDataCompressed = true;
                        customHeaders = {
                            'Content-Encoding': 'gzip'
                        };
                    }
                }                
            }

            var responseDataType = dataType == null ? 'json' : dataType;
            var requestMethod = httpMethod == null ? 'POST' : httpMethod;
            var requestContentType = String.format('application/{0}; charset=utf-8', responseDataType);

            var request = {
                type: requestMethod,
                url: url,
                data: ajaxRequestData,
                dataType: responseDataType,
                contentType: requestContentType,
                cache: false
            };
            if (Object.keys(customHeaders).length) {
                request.headers = customHeaders;
            }

            if (requestOptions && !util.isEmptyObject(requestOptions)) {
                $.extend(request, requestOptions);
            }

            //Add required option for binary data
            if (responseDataType === 'binary' || isDataCompressed) {
                request.processData = false;
            }

            if (isDataCompressed) {
                request.contentEncoding = 'gzip';
            }

            $.ajax(request)
                .done(function (responseResult) {

                    var responseObject = responseResult,
                        errorObject = null;
                    try {

                        //if(responseDataType )

                        if (typeof (responseResult) === 'string' && (responseResult.length > 0) & responseDataType === 'json') {
                            responseObject = JSON.parse(responseResult);
                        }
                        //Check whether the response should be parsed to JavaScript entity
                        if (parseResponseToJSEntity) {

                            if (responseObject._Entity) {
                                //it is a single entity
                                objectBuilder.createEntityObject({
                                    copyFrom: responseObject,
                                    callback: callback
                                });
                                return;
                            } else if (responseObject._EntityCollection) {
                                //it is a collection
                                objectBuilder.createEntityCollection({
                                    copyFrom: responseObject,
                                    callback: callback
                                });
                                return;
                            }
                        }

                        //responseObject = simpleObject;
                    } catch (parseError) {
                        errorObject = parseError;
                    }
                    util.notify(callback, responseObject, errorObject);

                })
                .fail(function (errorResult) {

                    var resultObject = {
                        status: errorResult.status,
                        statusText: errorResult.statusText
                    };
                    var response = errorResult.responseText;
                    if (response && response.length > 0) {
                        try {
                            response = JSON.parse(response);

                            if (response.Message && response.Message.length > 0) {
                                try {
                                    response.Message = JSON.parse(response.Message);
                                } catch (messageParseError) {
                                    //that's fine. Message is not a JSON string.
                                }
                            }

                            resultObject.response = response;

                        } catch (parseError) {
                            resultObject.response = response;
                        }

                    }
                    
                    eventHelper.trigger(enums.proxyEventName.httpError, { url: url, code: resultObject.status });
                    util.notify(callback, null, resultObject);
                }
                );

        };

        var processOptions = function (requestData, requestOptions) {
            var processedOptions = requestOptions || {};
            var optionsParent = requestData;
            var options = requestData.Options;
            if (!options && requestOptions && requestOptions.headers && requestOptions.headers.Options) {
                optionsParent = requestOptions.headers;
                options = requestOptions.headers.Options;
            }

            if (options) {
                if (defaultServerOption in options) {
                    processedOptions[defaultServerOption] = options[defaultServerOption];
                }
                if (convertToJSEntityOption in options) {
                    processedOptions[convertToJSEntityOption] = options[convertToJSEntityOption];
                }
            }

            optionsParent.Options = getOptionsString(options);

            return processedOptions;
        };

        var getOptionsString = function (options) {
            var optionsString = '';

            if (options) {

                if (util.isObject(options)) {
                    var separator = '|';
                    for (var field in options) {
                        if (options.hasOwnProperty(field) && optionsToIgnore.indexOf(field) < 0) {
                            optionsString += field + separator + options[field] + separator;
                        }
                    }

                    optionsString = optionsString.substr(0, optionsString.length - 1);

                } else if (util.isString(options)) {
                    optionsString = options;
                }
            }

            return optionsString;
        };

        var processRequestData = function (requestData, requestOptions) {
            var data = requestData;

            var payload = null;

            if (requestOptions && requestOptions.isPayload) {
                payload = requestData;
            } else {
                payload = requestData.Payload;
            }

            if (payload) {

                var serializedPayload = null;
                if (util.isFunction(payload.getInstanceWithSerializationMetadata)) {
                    serializedPayload = payload.getInstanceWithSerializationMetadata();
                } else if (util.isFunction(payload.toJSON)) {
                    //In some cases, specially when the entity comes from the UI, it's possible that the toJSON function is defined.
                    serializedPayload = payload.toJSON();
                }

                if (serializedPayload) {
                    if (requestOptions && requestOptions.isPayload) {
                        data = serializedPayload;
                    } else {
                        data = util.copy(requestData);
                        data.Payload = serializedPayload;
                    }
                }

            }

            if (requestOptions && util.isDefined(requestOptions.convertToFormData)) {

                //Transform request data object to a FormData JavaScript object. Commonly needed when binary data needs to be send.
                var formData = new FormData();
                for (var property in data) {
                    if (data.hasOwnProperty(property)) {

                        if (!(data[property] instanceof Array) && !(data[property] instanceof FileList)) {
                            formData.append(property, data[property]);
                        } else {
                            //Special code to handle scenario where data is an array with binary data on each position.
                            var array = data[property];
                            for (var index = 0; index < array.length; index++) {
                                var currentItem = array[index];
                                if (currentItem instanceof File) {
                                    formData.append(currentItem.name, currentItem);
                                } else {
                                    formData.append(currentItem.name, currentItem.data);
                                }

                            }
                        }
                    }
                }
                data = formData;
            }

            return data;
        };

        var unsubscribeHandlers = function (handlerIDs, handlersContainer) {
            if ((typeof handlerIDs === 'string') && handlerIDs.lastIndexOf('.') > 0) {
                handlerIDs = [handlerIDs];
            }

            var handlerInfo;
            var handlerIdPosition = 2;

            if ((typeof handlerIDs === 'string') && handlerIDs.lastIndexOf('.') < 0) {
                delete handlersContainer[handlerIDs];
            } else if (handlerIDs && util.isObject(handlerIDs)) {
                for (var index = 0; index < handlerIDs.length; index++) {
                    handlerInfo = handlerIDs[index].split('.');

                    if (handlersContainer[handlerInfo[0]][handlerInfo[handlerIdPosition]]) {
                        delete handlersContainer[handlerInfo[0]][handlerInfo[handlerIdPosition]];
                    }
                }
            }
        };

        var subscribeHandlers = function (methodName, handlersContainer, handler) {
            var guid = util.newGuid();

            if (!handlersContainer[methodName]) {
                handlersContainer[methodName] = {};
            }

            handlersContainer[methodName][guid] = handler;

            return methodName + '.' + handlersContainer.name + '.' + guid;
        };

        var proxyHandlers = { beforeHandlers: { name: 'Before' }, afterHandlers: { name: 'After' } };

        var RESTWebServiceProxy = function (webServiceName) {

            var serviceName = webServiceName;

            this.getServiceName = function () {
                return serviceName;
            };

        };

        RESTWebServiceProxy.prototype = {

            sendJSONRequest: function (methodName, requestData, callback, responseDataType, httpMethod, requestOptions) {
                var processedRequestOptions = processOptions(requestData, requestOptions);
                var data = processRequestData(requestData, requestOptions);
                sendRequest(this.getServiceName(), methodName, data, true, callback, responseDataType, httpMethod, processedRequestOptions);
            },

            sendPlainTextRequest: function (methodName, requestData, callback, responseDataType, httpMethod) {
                var data = processRequestData(requestData);
                sendRequest(this.getServiceName(), methodName, data, false, callback, responseDataType, httpMethod);
            },

            bind: function (eventName, callback) {

                //Use jQuery's event namespaces to assign unique identifiers to the event handlers
                var eventHandlerID = util.newGuid();
                $(document).on(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID), callback);
                return eventHandlerID;
            },

            unbind: function (eventName, eventHandlerID) {

                if (eventHandlerID == null) {
                    //In this case, all event handlers for the given event will be removed.
                    $(document).off(eventName);
                } else {
                    $(document).off(String.format(fullEventHandlerIDFormat, eventName, eventHandlerID));
                }
            },

            _trigger: function (eventName, eventData) {

                var triggerObject = {
                    type: eventName
                };
                $.extend(true, triggerObject, eventData);
                $.event.trigger(triggerObject);
            },

            _getActiveServer: function (options) {
                var activeServer = clientCache.getActiveServer();
                if (options && options[defaultServerOption]) {
                    activeServer = options[defaultServerOption];
                }
                return activeServer;
            },

            _updateOptions: function (currentOptions, convertToJSEntity) {

                var options = currentOptions;
                if ((!currentOptions) || (!(convertToJSEntityOption in currentOptions))) {
                    var transformToJSEntity = false;
                    if (util.isDefined(convertToJSEntity)) {
                        transformToJSEntity = Boolean(convertToJSEntity) && configLoader.appConfig.entity.convertToJavaScriptEntity;
                    }
                    options = util.copy(currentOptions) || {};
                    options[convertToJSEntityOption] = transformToJSEntity;
                }
                return options;
            },

            _subscribeBeforeHandler: function (methodName, handler) {
                var handlerID = subscribeHandlers(methodName, proxyHandlers.beforeHandlers, handler);

                return handlerID;
            },

            _subscribeAfterHandler: function (methodName, handler) {
                var handlerID = subscribeHandlers(methodName, proxyHandlers.afterHandlers, handler);

                return handlerID;
            },

            _unsubscribeBeforeHandlers: function (handlerIDs) {
                unsubscribeHandlers(handlerIDs, proxyHandlers.beforeHandlers);
            },

            _unsubscribeAfterHandlers: function (handlerIDs) {
                unsubscribeHandlers(handlerIDs, proxyHandlers.afterHandlers);
            },

            _invokeBeforeHandlers: function (methodName, params) {
                var availableHandlers = proxyHandlers.beforeHandlers[methodName];

                var cancel = false;

                if (availableHandlers) {
                    for (var availableHandler in availableHandlers) {
                        if (availableHandlers.hasOwnProperty(availableHandler)) {
                            cancel = proxyHandlers.beforeHandlers[methodName][availableHandler].call(this, params);
                            if (cancel) {
                                return true;
                            }
                        }
                    }
                }
                return cancel;
            },

            _afterCallback: function (methodName, params, originalCallback) {
                var that = this;
                var options = params.Options || undefined;
                var payload = params.Payload || undefined;

                return function (response, error) {
                    params = proxyHelper.changeFirstLetterToLowerCase(params);
                    params.responseError = error;
                    params.responseObject = response;
                    if (options) {
                        params.options = options;
                    }

                    if (payload) {
                        params.payload = payload;
                    }

                    var availableHandlers = proxyHandlers.afterHandlers[methodName];
                    if (availableHandlers) {

                        for (var availableHandler in availableHandlers) {
                            if (availableHandlers.hasOwnProperty(availableHandler)) {
                                proxyHandlers.afterHandlers[methodName][availableHandler].call(that, params);
                            }
                        }
                    }
                    util.notify(originalCallback, params.responseObject, params.responseError);
                };
            }
        };

        return {
            RESTWebServiceProxy: RESTWebServiceProxy
        };

    }
);

