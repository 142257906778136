define('modularisRoutingManager',
    ['jquery', 'kendo', 'modularisLayoutManager', 'modularisTemplateLoader', 'modularisGeneral/browsingContextHelper', 'configLoader',
        'clientCache', 'clientStorage', 'logger', 'util', 'webUtil'],
    function ($, kendo, layoutManager, templateLoader, browsingContextHelper, configLoader,
        clientCache, clientStorage, logger, util, webUtil) {
        'use strict';

        /**
           * Provides functions for routing management in the application.
           * @namespace routingManager
           * @memberof modularis.web
           */

        var currentRouteInfo = null;

        var returnUrlParameterName = 'returnUrl';

        var isAuthorizationRequired = function (route) {
            return ('authorize' in route) && route.authorize;
        };

        var backArgumentPropertyName = '_back';

        var isBackNavigation = function (routeArguments) {
            var isBack = false;

            if (!routeArguments) {
                return isBack;
            }

            for (var index = 0; index < routeArguments.length && !isBack; index++) {
                var argument = routeArguments[index];
                if (argument && argument.hasOwnProperty(backArgumentPropertyName)) {
                    isBack = argument[backArgumentPropertyName] ? true : false;
                }
            }

            return isBack;
        };

        var createRouteInfo = function (route, routeParameterNames, routeParameters, routeArguments) {
            var routeInfo = {
                route: route,
                parameters: routeParameters,
                parameterNames: routeParameterNames,
                arguments: routeArguments,
                isBack: isBackNavigation(routeArguments)
            };

            return routeInfo;
        };

        var setCurrentRoute = function (route, routeParameters, routeArguments) {
            var routeInfo = createRouteInfo(route, routeParameters, routeArguments);
            setCurrentRouteInfo(routeInfo);
        };

        var setCurrentRouteInfo = function (routeInfo) {
            currentRouteInfo = routeInfo;
        };



        var handleRoute = function (router, route) {

            //Get parameters for route
            var routeParameterNames = getRouteParameterNames(route.routePath);

            router.route(route.routePath, function () {

                var routeArguments = arguments;

                browsingContextHelper.restoreBrowsingContext(route, function () {

                    var routeParameters = getRouteParameters(route, routeParameterNames, routeArguments);
                    var routeInfo = createRouteInfo(route, routeParameterNames, routeParameters, routeArguments);

                    if (routingManager._onBeforeNavigate) {
                        var beforeNavigateEvent = {
                            origin: currentRouteInfo,
                            target: routeInfo,
                            isBack: routeInfo.isBack,
                            preventDefault: false
                        };
                        routingManager._onBeforeNavigate(route, routeParameters, beforeNavigateEvent);

                        if (beforeNavigateEvent.preventDefault) {
                            return;
                        }
                    }

                    if (isAuthorizationRequired(route)) {

                        if (!clientCache.isAuthenticated()) {

                            if (routingManager._validateSessionHandler) {

                                routingManager._validateSessionHandler(route, routeParameters, routeArguments, function (isValid) {
                                    if (isValid) {
                                        loadView(router, routeInfo);
                                    } else {
                                        navigateToLogin(router, routeInfo);
                                    }
                                });

                            }
                            else {
                                navigateToLogin(router, routeInfo);
                            }

                            return;

                        }
                    }

                    loadView(router, routeInfo);
                });
            });

        };

        var navigateToLogin = function (router, routeInfo) {
            //Check session cookie
            if (clientCache.sessionCookieEnabled()) {
                var cookieData = clientCache.getSessionCookieData();
                if (cookieData != null) {
                    if (typeof (clientCache.recoverSessionCallback) === 'function') {

                        clientCache.recoverSessionCallback(cookieData.sessionID, cookieData.deviceName, function () {
                            loadView(router, routeInfo);
                        });
                    }
                } else {
                    navigateToLoginWithReturnUrl(router);
                }

            } else {
                navigateToLoginWithReturnUrl(router);
            }
        };

        var navigateToLoginWithReturnUrl = function (router) {
            var requestedPath = window.location.hash;

            var loginRoute = routingManager.findRouteByName('login');
            //Set current route
            setCurrentRoute(loginRoute);
            router.navigate(String.format('#{0}?{1}={2}', loginRoute.routePath, returnUrlParameterName, requestedPath));
        };

        var getRouteParameters = function (route, routeParameterNames, routeFunctionArguments) {

            var parameterNames = routeParameterNames;

            if (parameterNames == null) {
                parameterNames = getRouteParameterNames(route.routePath);
            }

            var routeParameters = {};
            for (var paramIndex = 0; paramIndex < parameterNames.length; paramIndex++) {
                var paramName = parameterNames[paramIndex];
                routeParameters[paramName] = routeFunctionArguments[paramIndex];
            }

            return routeParameters;

        };

        var loadView = function (router, routeInfo) {

            var route = routeInfo.route;
            var parameters = routeInfo.parameters;

            //Check if the view should be opened in a new browsing context (such as a window or a tab)
            if (browsingContextHelper.isNewBrowsingContext(route)) {

                var continueViewLoading = browsingContextHelper.prepareForNewBrowsingContext(router, route);
                if (!continueViewLoading) { return; }

            }

            //Establish require function to use.
            var requireFunction = util.getRequireJSFunction();
            if (util.isDefined(route.module) && route.module.indexOf('modularis') === 0) {
                requireFunction = require;
            }

            if (typeof (route.templatePath) !== 'undefined') {
                templateLoader.loadTemplate(route.templatePath, function (data, error) {

                    if (error != null) {
                        //console.log(error);
                        return;
                    }

                    var kendoViewOptions;
                    var templateId = data.templateId;

                    var additionalOptions = {
                        hide: layoutManager._handleViewHiding
                    };

                    if (typeof (route.module) !== 'undefined') {
                        requireFunction([route.module], function (viewModelModule) {

                            var initializeOptions = {
                                routeParameters: parameters
                            };

                            //Change current route
                            setCurrentRouteInfo(routeInfo);

                            if (!viewModelModule) {
                                logger.error('The module "' + route.module + '" does not return any value.');
                                return;
                            }

                            if (!util.isDefined(viewModelModule.initializeForView)) {
                                logger.error('The module "' + route.module + '" does not expose an initializeForView function');
                                return;
                            }

                            viewModelModule.initializeForView(function (viewModel /*, viewModelCallbackError*/) {
                                additionalOptions.model = viewModel;
                                kendoViewOptions = webUtil.addViewSettings(route.templatePath, templateId, additionalOptions);
                                viewModel._logOffEventHandlerID = webUtil.bindToLogOffEvent(viewModel);
                                viewModel._unauthorizedEventHandlerID = webUtil.bindToUnauthorizedEvent(viewModel);
                                var kendoView = new kendo.View(templateId, kendoViewOptions);
                                displayView(kendoView, route, data, viewModel);
                            }, initializeOptions);

                        });
                    } else {
                        kendoViewOptions = webUtil.addViewSettings(route.templatePath, templateId, additionalOptions);
                        var view = new kendo.View(templateId, kendoViewOptions);
                        //Change current route
                        setCurrentRouteInfo(routeInfo);
                        displayView(view, route, data);
                    }

                });
            } else {
                if (typeof (route.module) !== 'undefined') {
                    requireFunction([route.module], function (viewModelModule) {

                        var handleRouteOptions = {
                            routeParameters: parameters
                        };

                        //Change current route
                        setCurrentRouteInfo(routeInfo);

                        viewModelModule.handleRoute(handleRouteOptions);

                    });
                } else {
                    //console.log('A route must define a module, a templatePath or both.');
                }
            }

        };

        var displayView = function (kendoView, route, data, viewModel) {

            //Initialize layout in case it hasn't been loaded yet.
            var layout = configLoader.appConfig.layout.defaultAuthenticatedUserLayoutPath;
            var transitionClass = route.transitionClass;
            if (browsingContextHelper.isNewBrowsingContext(route)) {

                var newBrowsingContextLayout = route.newBrowsingContextLayout;
                if (!newBrowsingContextLayout) {
                    newBrowsingContextLayout = configLoader.appConfig.layout.defaultNewBrowsingContextLayoutPath;
                }

                if (newBrowsingContextLayout) { layout = newBrowsingContextLayout; }
            }

            if (isAuthorizationRequired(route) && !layoutManager.isLayoutDisplayed(layout)) {
                layoutManager.initializeLayout(layout, function () {
                    layoutManager.showInLayout(kendoView, transitionClass, null, function () {
                        webUtil.callAfterViewModelIsDisplayed(viewModel);
                    });
                });
            } else {
                layoutManager.showInLayout(kendoView, transitionClass, null, function () {
                    webUtil.callAfterViewModelIsDisplayed(viewModel);
                });
            }

            //Change page title if needed
            if (data.title) {
                $('head title').text(data.title);
            }
        };

        var getRouteParameterNames = function (routePath) {

            var boundParameters = [];
            //Split the route path using the special characters * and :. --- * --> route globbing, : --> bound parameter
            var tokens = routePath.split(/[*:]+/);

            //Remove the first element in the array. It will contain 'garbage'
            tokens.shift();

            for (var index = 0; index < tokens.length; index++) {
                var routeToken = tokens[index];

                //If the token contains a slash, we can safely remove what it is after it.
                if (routeToken.indexOf('/') >= 0) {
                    routeToken = routeToken.substr(0, routeToken.indexOf('/'));
                }

                //Remove open parent and close parent chars. They are used for optional parameters.
                routeToken = routeToken.replace(/[\(\)]+/, '');
                boundParameters.push(routeToken);
            }

            return boundParameters;

        };

        var routingManager = {

            _routes: null,
            /**
            * A {@link https://docs.telerik.com/kendo-ui/api/javascript/router | kendo router} instance used for tracking the application state and navigating between the application states.
            * @memberof modularis.web.routingManager
            */
            router: null,

            _validateSessionHandler: null,

            /**
            * @typedef {Object} route Modularis route object.
            * @property {string} name - Name of the route.
            * @property {string} routePath - The path of the route to be use in the browser.
            * @property {string} templatePath - The view template to be show when this route is active.
            * @property {string} module - The viewModel that controls the view.
            * @property {string} authorize - Indicates if the user should be authenticate to see the view.
            * @property {boolean} newBrowsingContext - indicates if a new browsing context will be create for the new route.
            */

            /**
            * @typedef {Object} routeInfo Object with additional information about the Modularis route object.
            * @property {route} route - Modularis route object.
            * @property {Object} parameters - Route parameters.
            * @property {Object} parameterNames - Route parameters names.
            * @property {Object} arguments - Route arguments.
            * @property {boolean} isBack - Indicates if the route is result of a back navigation.
            */

            /**
             * Initializes the route manager using the routes set up in the routes.js file.
             * @name init
             * @function
             * 
             * @param {route[]} routes - An array of modularis routes objects. Normally this array is loaded from the routes.js file.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @memberof modularis.web.routingManager
              */
            init: function (routes, options) {
                var that = this;
                that.router = new kendo.Router();
                if (options.onRouteChange) {
                    that.router.bind('change', options.onRouteChange);
                }

                if (routes == null) {
                    return;
                }

                that._validateSessionHandler = options.validateSessionHandler;
                that._onBeforeNavigate = options.onBeforeNavigate;

                //Load routes
                for (var index = 0; index < routes.length; index++) {
                    var route = routes[index];
                    if (typeof (route.routePath) !== 'undefined') {
                        //The route path cannot start or end with ':' or '*'
                        if (route.routePath.startsWith(':') || route.routePath.startsWith('*') || route.routePath.endsWith(':') || route.routePath.endsWith('*')) {
                            logger.error('A route path cannot start or end with ":" or "*": ' + route.routePath);
                        } else {
                            handleRoute(that.router, route);
                        }
                    }
                }

                //Add a routeMissing event handler
                that.router.bind('routeMissing', function (event) {
                    logger.info('Route missing: ', event.url);
                });
                that._routes = routes;
                that.router.start();
            },

            /**
             * Returns the route object whose name matches with the given parameter.
             * @name findRouteByName
             * @function
             * 
             * @param {string} name -The route name to be resolved.
             * @memberof modularis.web.routingManager
             * @returns {route}
              */
            findRouteByName: function (name) {
                var result = null;
                for (var index = 0; (index < this._routes.length) && (!result); index++) {
                    var route = this._routes[index];
                    if (route.name === name) { result = route; }
                }
                return result;
            },

            /**
            * Returns the modularis route object that belongs to the current route. 
            * @name getCurrentRoute
            * @function
            * 
            * @memberof modularis.web.routingManager
            * @returns {route}
            */
            getCurrentRoute: function () {
                return currentRouteInfo.route;
            },

            /**
            * Returns an object that contains the modularis route object for the current route and the parameters received during navigation. 
            * @name getCurrentRoute
            * @function
            * 
            * @memberof modularis.web.routingManager
            * @returns {routeInfo}
            */
            getCurrentRouteInfo: function () {
                return currentRouteInfo;
            },

            /**
             * Returns the value of the returnUrl URL parameter if exists. 
             * @name getQueryStringParameter
             * @function
             * 
             * @memberof modularis.web.routingManager
             * @returns {string}
             */
            getReturnUrl: function () {
                return this.getQueryStringParameter(returnUrlParameterName);
            },

            /**
             * Returns the value of the given URL parameter if exists. 
             * @name getQueryStringParameter
             * @function
             * 
             * @param {string} parameterName - The parameter name to be resolved.
             * @memberof modularis.web.routingManager
             * @returns {string}
             */
            getQueryStringParameter: function (parameterName) {
                var queryString = window.location.hash.split('?');

                if (queryString.length <= 1) {
                    return '';
                }

                var params = queryString[1].split('&');
                for (var index = 0; index < params.length; index++) {
                    var pair = params[index].split('=');
                    if (decodeURIComponent(pair[0]) === parameterName) {
                        return decodeURIComponent(pair[1]);
                    }
                }

                return '';
            }

        };

        return routingManager;
    }
);
