define('modularis/web/widgets/modularisView',
    ['jquery', 'kendo', 'configLoader', 'modularisTemplateLoader', 'objectBuilder', 'util', 'webUtil', 'enums'],
    function ($, kendo, configLoader, templateLoader, objectBuilder, modularisUtil, webUtil, enums) {
        'use strict';

        var ui = kendo.ui, Widget = ui.Widget;

        //#region Widget definition

        var saveCompleted = 'saveCompleted';
        var deleteCompleted = 'deleteCompleted';
        var changeEventName = 'change';

        /**
         * @classdesc ModularisView widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/widget|kendo.ui.Widget}.
         * @constructs modularis.web.widgets.ModularisView
         * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
		 *
		 * @property {Object} options - Widget options.
		 * @property {string} options.view - Sets the path of the custom view to be associated and embedded in the Modularis View.
		 * @property {string} options.viewModel - Sets the path of the ViewModel related to the view defined by the option [options.view].
		 * @property {string} options.entityTypeName - The full name of the entity that will be bound to the widget.
		 * @property {modularis.enums.editModes} [options.editMode=enums.editModes.insert] - The enumeration indicating the transaction type. Check {@link modularis.enums.editModes} for available values.
		 * @property {string} options.entityInstanceKey - Sets the key of an entity that needs to be displayed on the view.
		 * @property {boolean} [options.initializeEntityOnServer=false] - Sets whether or not the entity associated with the view is initialize on the server. The default values is false.
		 * @property {Object} [options.additionalOptions] - Sets the object with the options that must be passed to the viewModel.
		 */
        var ModularisView = Widget.extend({

            _viewPath: null,
            _viewInstance: null,
            _viewModelInstance: null,

            _logOffEventHandlerID: null,
            _unauthorizedEventHandlerID: null,

            _entityEditListElement: null,

            //The following object temporarily stores values that should passed to the view-model, but the view-model hasn't been loaded yet.
            _tempViewModelValues: null,
            _tempViewModelEventHandlers: null,
            _propertyChangedEventNames: null,
            _waitingViewModelForChangeBinding: null,
            init: function (element, options) {
                var that = this;
                Widget.fn.init.call(that, element, options);
                that._tempViewModelValues = {};
                that._tempViewModelEventHandlers = {};
                that._propertyChangedEventNames = {};
                that._waitingViewModelForChangeEventBinding = false;
                var path = that._getViewPath();
                templateLoader.loadTemplate(path, function (data, error) {

                    if (!modularisUtil.isSuccessfulResponse(data, error)) {
                        //console.log(error);
                        return;
                    }
                    var templateId = data.templateId;
                    var viewModelModuleName = that._getViewModelModuleName();
                    var viewOptions;
                    if (viewModelModuleName == null) {
                        viewOptions = webUtil.addViewSettings(path, templateId);
                        that._setViewInstance(new kendo.View(templateId, viewOptions));
                        that._render();
                        return;
                    }

                    var requireFunction = modularisUtil.getRequireJSFunction();

                    if (viewModelModuleName.startsWith('modularis')) {
                        requireFunction = require;
                    }

                    requireFunction([viewModelModuleName], function (viewModelModule) {

                        var initializeViewCallback = function (viewModel, initializeViewError) {

                            if (modularisUtil.isSuccessfulResponse(viewModel, initializeViewError)) {
                                that._setViewModelInstance(viewModel);
                                viewOptions = webUtil.addViewSettings(path, templateId, { model: that._viewModelInstance });
                                that._logOffEventHandlerID = webUtil.bindToLogOffEvent(that._viewModelInstance);
                                that._unauthorizedEventHandlerID = webUtil.bindToUnauthorizedEvent(that._viewModelInstance);
                                that._setViewInstance(new kendo.View(data.templateId, viewOptions));
                                that._render();

                                //Assign entity key if needed
                                if (that.options.entityInstanceKey && that.options.entityTypeName) {
                                    that._viewModelInstance.loadEntityByKey({
                                        key: that.options.entityInstanceKey,
                                        getChildren: false,
                                        callback: function () {
                                            webUtil.callAfterViewModelIsDisplayed(that._viewModelInstance);
                                        }
                                    });
                                } else {
                                    webUtil.callAfterViewModelIsDisplayed(that._viewModelInstance);
                                }

                            } else {
                                //console.log(error);
                            }

                        };

                        var initializeOptions = that._createInitializeViewOptions();
                        viewModelModule.initializeForView(initializeViewCallback, initializeOptions);

                    });
                });

                that._bindWidgetViewModelEvents();
            },

            destroy: function () {
                var that = this;

                webUtil.destroyViewModel(that._viewModelInstance);

                if (that._viewInstance) {
                    that._viewInstance.destroy();
                }

                that._viewInstance = null;
                that._viewModelInstance = null;

            },

            options: {
                name: 'ModularisView',
                view: null,
                viewModel: null,

                //#region Settings specific to ModularisView loading generated views and view-models

                entityTypeName: null,
                editMode: enums.editModes.insert,
                entityInstanceKey: null,
                initializeEntityOnServer: false,

                //#endregion

                additionalOptions: null
            },

            events: [saveCompleted, deleteCompleted, enums.modularisViewEventName.beforeViewModelAssigned, enums.modularisViewEventName.afterViewModelAssigned],

            _bindWidgetViewModelEvents: function () {
                var that = this;
                for (var eventIndex = 0; eventIndex < that.events.length; eventIndex++) {
                    var eventName = that.events[eventIndex];
                    that._bindViewModelEvent(eventName);
                }
            },

            _bindViewModelEvent: function (eventName) {
                var that = this;
                that.bindViewModelEvent(eventName, function (event) {
                    that.trigger(eventName, event);
                });
            },

            /**
			 * @name setAdditionalOptions
			 * @function modularis.web.widgets.ModularisView#setAdditionalOptions
             * @description Allows to pass additional or different options to the associated view model.
             * @memberof modularis.web.widgets.ModularisView
			 * @param {Object} additionalOptions - Object that contains the additional options to pass to the view model.
			 */
            setAdditionalOptions: function (additionalOptions) {
                var that = this;
                if (that._viewModelInstance != null && that._viewModelInstance.setAdditionalOptions instanceof Function) {
                    that._viewModelInstance.setAdditionalOptions(additionalOptions);
                }
            },

            /**
			 * @name getViewModel
			 * @function modularis.web.widgets.ModularisView#getViewModel
             * @description Gets the current view model instance associated with the Modularis View.
             * @memberof modularis.web.widgets.ModularisView
			 * @returns {Object} - The current view model instance.
			 */
            getViewModel: function () {
                return this._viewModelInstance;
            },

            /**
			 * @name assignEntityEditList
			 * @function modularis.web.widgets.ModularisView#assignEntityEditList
             * @description Assigns the {@link modularis.web.widgets.entityEditList} element associated with the Modularis View.
             * @memberof modularis.web.widgets.ModularisView
			 * @param {modularis.web.widgets.entityEditList} listDOMElement - The {@link modularis.web.widgets.entityEditList} to associate with the Modularis View.
			 */
            assignEntityEditList: function (listDOMElement) {
                this._entityEditListElement = listDOMElement;
            },

            /**
			 * @name setViewModelValue
			 * @function modularis.web.widgets.ModularisView#setViewModelValue
             * @description Sets the value of a property defined in the current view model.
             * @memberof modularis.web.widgets.ModularisView
             * @param {string} key - The name of the property to set in the view model.
			 * @param {Object} value - The value to assign to the property in the view model that is identify by the passed key.
			 */
            setViewModelValue: function (key, value) {
                var viewModel = this.getViewModel();
                if (!viewModel) {
                    this._tempViewModelValues[key] = value;
                } else {
                    this._passViewModelValue(key, value);
                }
            },

            /**
			 * @name setActiveEntity
			 * @function modularis.web.widgets.ModularisView#setActiveEntity
             * @description Sets the active entity associated with the current view model.
             * @memberof modularis.web.widgets.ModularisView
             * @param {modularis.EntityBase} activeEntity - The entity instance to associate with the view model. Check {@link modularis.EntityBase}.
			 */
            setActiveEntity: function (activeEntity) {
                if (activeEntity) {
                    this.setViewModelValue('activeEntity', activeEntity);
                }
            },

            _passViewModelValue: function (key, value) {
                var viewModel = this.getViewModel();
                if (key !== 'activeEntity') {
                    viewModel.setValue(key, value);
                } else if (modularisUtil.isDefined('activeEntity')) {
                    viewModel.activeEntity = value;
                }
            },

            _passStoredTempValues: function () {
                var that = this;
                var tempValues = that._tempViewModelValues;
                for (var key in tempValues) {
                    if (tempValues.hasOwnProperty(key)) {
                        that._passViewModelValue(key, tempValues[key]);
                    }
                }
                that._tempViewModelValues = {};
            },

            _render: function () {
                var that = this;
                that.element.empty();
                that._viewInstance.render(that.element);
                //Avoids a possible memory leak.
                that._viewInstance.element.prevObject = null;
            },

            _getViewPath: function () {
                var that = this;
                if (that._viewPath == null) {
                    that._viewPath = that.options.view;

                    //Try to resolve the view path using the entity type name.
                    if (that._viewPath == null && that.options.entityTypeName != null) {
                        var normalizedEntityTypeName = objectBuilder.getNormalizedEntityTypeName(that.options.entityTypeName);
                        var lastSlashPosition = normalizedEntityTypeName.lastIndexOf('/');
                        var entityName = normalizedEntityTypeName.substr(lastSlashPosition + 1);

                        //All entity views are saved in the 'entity' folder, located under the views folder.
                        that._viewPath = 'entity/' + normalizedEntityTypeName.substr(0, lastSlashPosition + 1) + '_' + entityName;
                    }
                }
                return that._viewPath;
            },

            _getViewModelModuleName: function () {
                var that = this;
                var result = that.options.viewModel;
                var config = configLoader.appConfig;

                //Try to resolve the view model name using the entity type name.
                if (result == null && that.options.entityTypeName != null) {
                    result = objectBuilder.getNormalizedEntityTypeName(that.options.entityTypeName);
                }

                if (result != null && !result.startsWith('modularis') && !result.startsWith(config.viewModelsBasePath)) {
                    result = config.viewModelsBasePath + result;
                }

                return result;
            },

            _createInitializeViewOptions: function () {
                var that = this;

                var initializeOptions = {
                    initializeOnServer: that.options.initializeEntityOnServer
                };

                if (that.options.entityTypeName != null) {
                    //The editMode is only valid when the entityTypeName is provided
                    initializeOptions.editMode = that.options.editMode;
                }

                if (that.options.additionalOptions != null) {
                    initializeOptions.additionalOptions = that.options.additionalOptions;
                }

                return initializeOptions;
            },

            _setViewModelInstance: function (newViewModelInstance) {
                var that = this;

                /**
                 * @name beforeViewModelAssigned
                 * @event modularis.web.widgets.ModularisView#beforeViewModelAssigned
                 * @description Event triggered before the view model is assigned to the view.
                 * @memberof modularis.web.widgets.ModularisView
                 * @type {Object}
                 * @property {Object} previousInstance - The previous view model instance.
                 */
                that.trigger(enums.modularisViewEventName.beforeViewModelAssigned, { previousInstance: that._viewModelInstance });
                that._viewModelInstance = newViewModelInstance;

                /**
                 * @name afterViewModelAssigned
                 * @event modularis.web.widgets.ModularisView#afterViewModelAssigned
                 * @description Event triggered after the view model is assigned to the view.
                 * @memberof modularis.web.widgets.ModularisView
                 * @type {Object}
                 * @property {Object} previousInstance - The newly assigned view model instance.
                 */
                that.trigger(enums.modularisViewEventName.afterViewModelAssigned, { newInstance: that._viewModelInstance });
                that._passStoredTempValues();
                that._passStoredViewModelEventHandlers();

                //Bind to event
                that._viewModelInstance.bind(enums.viewModelEventName.saveCompleted, function (event) {

                    if (that._entityEditListElement) {
                        kendo.widgetInstance(that._entityEditListElement).refreshData();
                    }

                    /**
                     * @name saveCompleted
                     * @event modularis.web.widgets.ModularisView#saveCompleted
                     * @description Event triggered when the save operation is completed.
                     * @memberof modularis.web.widgets.ModularisView
                     * @type {Object}
                     * @property {Object} event - Event object
                     * @property {string} event.entityName - The type name of the saved entity.
                     * @property {modularis.EntityBase} event.data - The saved entity.
                     */
                    that.trigger(saveCompleted, {
                        entityName: event.entity,
                        data: event.data
                    });
                });

                that._viewModelInstance.bind(enums.viewModelEventName.deleteCompleted, function (event) {

                    if (that._entityEditListElement) {
                        kendo.widgetInstance(that._entityEditListElement).refreshData();
                    }

                    /**
                     * @name deleteCompleted
                     * @event modularis.web.widgets.ModularisView#deleteCompleted
                     * @description Event triggered when the delete operation is completed.
                     * @memberof modularis.web.widgets.ModularisView
                     * @type {Object}
                     * @property {Object} event - Event object
                     * @property {string} event.entityName - The type name of the deleted entity.
                     */
                    that.trigger(deleteCompleted, {
                        entityName: event.entity
                    });
                });
            },

            _setViewInstance: function (newViewInstance) {
                var that = this;
                that.trigger(enums.modularisViewEventName.beforeViewAssigned, { previousInstance: that._viewInstance });
                that._viewInstance = newViewInstance;
                that.trigger(enums.modularisViewEventName.afterViewAssigned, { newInstance: that._viewInstance });
            },

            /**
			 * @name bindViewModelEvent
			 * @function modularis.web.widgets.ModularisView#bindViewModelEvent
             * @description Binds an event and its associated handler to the current view model.
             * @memberof modularis.web.widgets.ModularisView
             * @param {string} eventName - The name of the event to bind to the view model.
			 * @param {Object} handler - The handler to trigger when the bound event occurs.
			 */
            bindViewModelEvent: function (eventName, handler) {
                var that = this;
                var viewModel = that.getViewModel();
                if (!viewModel) {

                    if (!(eventName in that._tempViewModelEventHandlers)) {
                        that._tempViewModelEventHandlers[eventName] = [];
                    }

                    that._tempViewModelEventHandlers[eventName].push(handler);

                } else {
                    that._passViewModelEventHandler(eventName, handler);
                }
            },

            _passStoredViewModelEventHandlers: function () {
                var that = this;
                var tempEventHandlers = that._tempViewModelEventHandlers;
                for (var eventName in tempEventHandlers) {
                    if (tempEventHandlers.hasOwnProperty(eventName)) {
                        var handlers = tempEventHandlers[eventName];
                        for (var handlerIndex = 0; handlerIndex < handlers.length; handlerIndex++) {
                            that._passViewModelEventHandler(eventName, handlers[handlerIndex]);
                        }
                    }
                }
            },

            _passViewModelEventHandler: function (eventName, handler) {
                var that = this;
                var viewModel = that.getViewModel();

                if (viewModel._events && !viewModel._events[eventName]) {
                    viewModel.bind(eventName, handler);
                }
            },

            /**
			 * @name bindPropertyChanged
			 * @function modularis.web.widgets.ModularisView#bindPropertyChanged
             * @description Binds an event when a property in the view model changes.
             * @memberof modularis.web.widgets.ModularisView
             * @param {string} propertyName - The name of the property for which is desired to handle the value changes.
			 * @param {Object} eventHandler - The handler to trigger when the property changes.
			 */
            bindPropertyChanged: function (propertyName, eventHandler) {
                var that = this;

                var viewModel = that.getViewModel();
                if (!viewModel && !that._waitingViewModelForChangeEventBinding) {
                    that._waitingViewModelForChangeEventBinding = true;
                    that.bind(enums.modularisViewEventName.afterViewModelAssigned, function () {
                        viewModel = that.getViewModel();
                        viewModel.bind(changeEventName, $.proxy(that._onViewModelPropertyChange, that));
                    });
                }


                if (!(propertyName in that._propertyChangedEventNames)) {
                    var eventName = String.format('{0}PropertyChanged', propertyName);
                    that._propertyChangedEventNames[propertyName] = eventName;
                }

                that.bind(that._propertyChangedEventNames[propertyName], eventHandler);

            },

            _onViewModelPropertyChange: function (event) {
                var that = this;

                var field = event.field;

                if (!(field in that._propertyChangedEventNames)) {
                    return;
                }

                var propertyChangedEventName = that._propertyChangedEventNames[field];

                var value = event.sender.get(field);

                var changeEvent = { field: field, value: value };

                that.trigger(propertyChangedEventName, changeEvent);
            }
        });

        //#endregion

        return {
            widgetClass: ModularisView
        };
    }
);
