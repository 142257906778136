define('modularis/web/widgets/entityEditList',
    ['jquery', 'kendo', 'metadataCache', 'dataSource', 'localization', 'webUtil',
        'util', 'enums', 'modularisRoutingManager', 'logger', 'modularisData/dataSourceFactory',
        'securityHelper', 'modularisGeneral/widgetHelper', 'clientCache'],
    function ($, kendo, metadataCache, ModularisDataSource, localization, webUtil,
        util, enums, routingManager, logger, dataSourceFactory,
        securityHelper, widgetHelper, clientCache) {
        'use strict';

	    /**
		 * Modularis popup options available to customize the EntityEditList Popup. For more information please visit {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/window}
		 * @typedef {Object} modularis.web.widgets.EntityEditList~modularisPopupOptions
		 * @property {string} [title] - Title of the popup. This is a localizable property and the key/value pair must be in the string resources. In case that it can't find the key in the resources the same value will be left.
		 * @property {boolean} [draggable=false] - Sets to true to enable the ability to move/drag the popup. Otherwise, false.
		 * @property {Array} [action="Close"] - The buttons available to interact with the window. These values can be "Close", "Refresh", "Minimize" and "Maximize".
		 * @property {number|string} [height] -  Specifies the height of the window.
		 * @property {number|string} [width="50%"] - Specifies the width of the window.
		 * @property {boolean} [modal=true] - Sets to true to overlay the window over the page.
		 * @property {boolean} [resizable=false] - Sets to true to enable the ability to resize the Window.
		 * 
		 */

	    /**
         * Modularis inline options available to customize the EntityEditList Inline CRUD operation messages.<b> The key/value pair of the following options must be in the string resources file</b>.
         * @typedef {Object} modularis.web.widgets.EntityEditList~modularisInlineOptions
         * @property {boolean} [feedbackMessage=true] - Sets to true to enable the Feedback messages. Otherwise, false.
         * @property {string} [createSuccessMessage="entity_edit_list_create_success_message"] - String resource key for a successfully create operation.
         * @property {string} [updateSuccessMessage="entity_edit_list_update_success_message"] -  String resource key for a successfully update operation.
         * @property {string} [deleteSuccessMessage="entity_edit_list_delete_success_message"] -  String resource key for a successfully delete operation.
         * @property {string} [defaultSuccessMessage="entity_edit_list_success_message"] - Default string resource key for successful operations. 
         * @property {string} [createErrorMessage="entity_edit_list_create_error_message"] - String resource key for an unsuccessful creates operation.
         * @property {string} [updateErrorMessage="entity_edit_list_update_error_message"] - String resource key for an unsuccessful update operation. 
         * @property {string} [deleteErrorMessage="entity_edit_list_delete_error_message"] - String resource key for an unsuccessful delete operation.
         * @property {string} [defaultErrorMessage="entity_edit_list_error_message"] -  Default string resource key for unsuccessful operations. 
         * @property {string} [deleteConfirmationTitle="entity_edit_list_delete_confirmation_title"] -  Default string resource key applied to the popup title for confirming delete operations.
         * @property {string} [deleteConfirmationMessage="entity_edit_list_delete_confirmation_message"] -  Default string resource key applied to the popup message for confirming delete operations.
         * @property {string} [deleteConfirmationYes="entity_edit_list_delete_confirmation_yes"] -  Default string resource key applied to the popup 'YES' option for confirming delete operations.
         * @property {string} [deleteConfirmationNo="entity_edit_list_delete_confirmation_no"] -  Default string resource key applied to the popup 'NO' option for confirming delete operations.
         */

        var ui = kendo.ui,
            Grid = ui.Grid;

        var types = enums.propertyTypes;

        var inputEditFormat = '<input data-text-field="{0}" data-value-field="{1}" data-bind="value: {2}{3}"/>';

        var editorTemplateFormat = '#if(data.{0} && typeof(data.{0}) === "object") { #' +
            ' #: data.{0}.{1} #' +
            ' #} else if(data.{0}DV) { #' +
            ' #: data.{0}DV #' +
            ' #} else if(Boolean(data.getEntityMetadata && data.getEntityMetadata().getAttribute("{2}.DV"))) { #' +
            ' #: data.getEntityMetadata().getAttribute("{2}.DV") #' +
            ' #} else if(data.{0}) { #' +
            ' #: data.{0} #' +
            ' #} else if(data.{2}DV) { #' +
            ' #: data.{2}DV #' +
            ' #} else if(data.{2}){ #' +
            ' #: data.{2} #' +
            ' #}#';

        var rowSelectorFormat = 'tr[data-uid="{0}"]';

        var entitySelectedDoubleClick = 'entitySelectedDoubleClick';
        var entitiesSelected = 'entitiesSelected';
        var entityEnterKeyDown = 'entityEnterKeyDown';
        var defaultDoubleClickTolerance = 300;
        var effectiveDoubleClickTolerance = 0;
        var lookupPropertyIdentifier = 'Ex';
        var displayValueIdentifier = 'DV';

        var editButtonSelector = 'tbody>tr:not(.k-detail-row,.k-grouping-row):visible a.k-grid-edit';
        var deleteButtonSelector = 'tbody>tr:not(.k-detail-row,.k-grouping-row):visible a.k-grid-delete';

        var addButtonTemplate = '<a class="k-button add-button" href="\\#"><span class="k-icon k-i-plus"></span>Add</a>';

        var modularisPopupDefaultOptions = {
            title: 'edit_entity_popup_title',
            modal: true,
            visible: false,
            width: '50%',
            draggable: false,
            resizable: false
        };

        var modularisEditableModes = {
            inline: 'inline',
            popup: 'popup',
            route: 'route'
        };

        var prefix = 'entity_edit_list_',
            deleteConfirmationPrefix = prefix + 'delete_confirmation_';
        var modularisInlineDefaultOptions = {
            feedbackMessage: true,
            createSuccessMessage: prefix + 'create_success_message',
            updateSuccessMessage: prefix + 'update_success_message',
            deleteSuccessMessage: prefix + 'delete_success_message',
            defaultSuccessMessage: prefix + 'success_message',
            createErrorMessage: prefix + 'create_error_message',
            updateErrorMessage: prefix + 'update_error_message',
            deleteErrorMessage: prefix + 'delete_error_message',
            defaultErrorMessage: prefix + 'error_message',
            deleteConfirmationTitle: deleteConfirmationPrefix + 'title',
            deleteConfirmationMessage: deleteConfirmationPrefix + 'message',
            deleteConfirmationYes: deleteConfirmationPrefix + 'yes',
            deleteConfirmationNo: deleteConfirmationPrefix + 'no',
            displayRequestErrors: false
        };


        //#region Create grid columns

        var findPropertyDef = function (columnName, propDefs) {
            for (var index = 0; index < propDefs.length; index++) {
                if (propDefs[index].PropertyName === columnName) {
                    return propDefs[index];
                }
            }
            return null;
        };

        var getSystemName = function (entityDef) {
            var result = clientCache.getSystemName();
            if (entityDef.Model === 'Modularis') {
                result = entityDef.Model;
            }
            return result;
        };

        var executeSelectedValueTemplate = function (selectedLookupEntity, valueTemplateString, dataTextField) {
            var valueTemplate = kendo.template(valueTemplateString);
            var templateResult;
            try {
                templateResult = valueTemplate(selectedLookupEntity);
            } catch (exception) {
                templateResult = selectedLookupEntity[dataTextField];
            }
            return templateResult;
        };

        var configureLookupProperty = function (gridColumn, propertyDef) {
            var adjustedGridColumn = util.copy(gridColumn);
            var settings = webUtil.getLookupDropDownListSettings(propertyDef);
            var columnAttributes = gridColumn.attributes;

            adjustedGridColumn.editor = function (container, options) {
                /*eslint-disable no-param-reassign*/
                var lookupObjectName = options.field + lookupPropertyIdentifier;

                if (!options.model[lookupObjectName]) {

                    var valueObject = new kendo.data.ObservableObject();

                    valueObject[settings.dataValueField] = options.model[options.field];
                    valueObject[settings.dataTextField] = options.model[options.field + displayValueIdentifier];

                    options.model[lookupObjectName] = valueObject;

                } else {

                    //Update dataTextField and dataValueField properties to include latest DV
                    var displayValue = options.model[options.field + displayValueIdentifier];
                    var dataValue = options.model[options.field];

                    options.model[lookupObjectName][settings.dataTextField] = '';
                    options.model[lookupObjectName][settings.dataValueField] = '';

                    if (displayValue) {
                        options.model[lookupObjectName][settings.dataTextField] = displayValue;
                        options.model[lookupObjectName][settings.dataValueField] = dataValue;
                    }
                }
                /*eslint-enable no-param-reassign*/
                var parameterValues = webUtil.formatLookupQueryDefParameterValues(options.model, propertyDef.LookupQueryDefParameterValues);
                var input = $(String.format(inputEditFormat, settings.dataTextField, settings.dataValueField, options.field, lookupPropertyIdentifier));

                var effectiveOptionLabel = '';

                if (columnAttributes) {
                    var optionLabel = columnAttributes.optionLabel;
                    if (optionLabel) {

                        var localizedOptionLabel = localization.translateStringResource(optionLabel);

                        effectiveOptionLabel = localizedOptionLabel !== null ? localizedOptionLabel : optionLabel;
                    }
                }

                input.appendTo(container);
                input.kendoDropDownList({
                    autoBind: false,
                    template: settings.template,
                    optionLabel: effectiveOptionLabel,
                    valueTemplate: function (lookupEntity) {
                        return executeSelectedValueTemplate(lookupEntity, settings.valueTemplate, settings.dataTextField);
                    },
                    dataSource: new ModularisDataSource({
                        modularis: {
                            activity: {
                                method: 'getCollectionByQueryDefID',
                                entityTypeName: propertyDef.LookupEntity,
                                queryDefID: propertyDef.LookupQueryDefID,
                                parameters: parameterValues
                            }
                        }
                    }),
                    change: function () {
                        var displayValuePropertyName = options.field + displayValueIdentifier;
                        //Get a reference to the current entity edit list and row
                        var currentEntityEditList = container.closest('[data-role=entityeditlist]').getKendoEntityEditList(),
                            currentRow = container.closest('tr');
                        if (currentEntityEditList && currentRow) {
                            var dataItem = currentEntityEditList.dataItem(currentRow);
                            if (!(displayValuePropertyName in dataItem)) {
                                dataItem[displayValuePropertyName] = null;
                            }
                            var selectedLookupItem = this.dataItem();
                            dataItem.set(displayValuePropertyName, executeSelectedValueTemplate(selectedLookupItem, settings.valueTemplate, settings.dataTextField));
                            dataItem.set(options.field, selectedLookupItem[settings.dataValueField]);
                        }
                    }
                });
            };

            adjustedGridColumn.template = String.format(editorTemplateFormat, settings.dataValueField, settings.dataTextField, propertyDef.PropertyName);

            // Force the UI of the filter search box to be a textbox. 
            adjustedGridColumn.filterable = { ui: 'textbox' };

            return adjustedGridColumn;
        };

        var configureGridColumn = function (entityTypeName, systemName, originalColumn, propertyDefs, isDynamicEntity) {

            var adjustedGridColumn = {};

            var columnName = originalColumn.field;
            var columnTitle = originalColumn.title;

            if (columnName) { adjustedGridColumn.field = columnName; }
            if (columnTitle) { adjustedGridColumn.title = columnTitle; }

            adjustedGridColumn.attributes = originalColumn.attributes;

            if (util.isDefined(columnName)) {

                var propDef = findPropertyDef(columnName, propertyDefs);
                if (propDef) {
                    var propertyType = propDef.PropertyType;

                    //Default template for date fields
                    if (!originalColumn.template && propertyType === types.typeDateTime) {
                        adjustedGridColumn.template = kendo.template('#= kendo.toString(' + columnName + ',"d") #');
                    }

                    //Check if the property corresponds to a lookup.
                    if (propDef.LookupEntity) {
                        adjustedGridColumn = configureLookupProperty(adjustedGridColumn, propDef);
                    }

                    //Check if the Property Type is Boolean to set a custom boolean editor
                    if (propertyType === types.typeBoolean) {
                        adjustedGridColumn.editor = util.boolEditor;
                    }
                }

                //In case a title wasn't provided or there is not localization value, try to use the friendly name of the PropertyDef.
                if ((!adjustedGridColumn.title || adjustedGridColumn.title.length === 0) && propDef) {
                    adjustedGridColumn.title = propDef.FriendlyName;
                }

                if (!originalColumn.hasOwnProperty('hidden') && propDef) {
                    //Establish if the column will be visible or not.
                    var functionApproved = securityHelper.isFunctionApproved(propDef.AllowViewToken, systemName);

                    //If the column does not have the function approved with its system name and is a dynamic entity property, 
                    //try to find the function using the Modularis system name. 
                    if (!functionApproved && isDynamicEntity) {
                        functionApproved = securityHelper.isFunctionApproved(propDef.AllowViewToken, 'Modularis');
                    }
                    //If the column does not have the function approved (allowViewToken) then add a allowViewFalse property to eventually hide the whole column visible checkbox
                    if (!functionApproved) {
                        adjustedGridColumn.allowView = false;
                    }

                    adjustedGridColumn.hidden = propDef.Hidden || !functionApproved;
                }
            }

            return adjustedGridColumn;
        };

        var getColumnsArray = function (widgetColumns, propertyDefs, batch, entityDef, editable, allowDelete) {

            var result = null,
                assignedPropertyDefs = false;

            //Clone widgetColumns array
            if (widgetColumns && widgetColumns.length) {
                result = widgetColumns.slice();
            }

            var propIndex;

            if (!result) {
                result = [];
                //create checkboxes columns in case batch option is true or has an array of commands.
                if (batch) {
                    result.push(
                        {
                            selectable: true, width: '50px'
                        });
                }
                //Create columns array in case the developer didn't provide any configuration for the columns
                for (propIndex = 0; propIndex < propertyDefs.length; propIndex++) {
                    var propName = propertyDefs[propIndex].PropertyName;

                    if (propName !== '_Entity' && propName !== 'Timestamp' && (propertyDefs[propIndex].PropertyType !== types.typeEntityCollection)) {
                        var friendlyName = propertyDefs[propIndex].FriendlyName;
                        result.push({ field: propName, title: friendlyName });
                    }

                }
                //create edit and destroy buttons column based on the entity security options
                var systemName = getSystemName(entityDef),
                    isEditable = securityHelper.isFunctionApproved(entityDef.AllowEditToken, systemName) && editable,
                    isDeletable = securityHelper.isFunctionApproved(entityDef.AllowDeleteToken, systemName) && allowDelete;

                if (isEditable || isDeletable) {
                    var command = [];
                    if (isEditable) {
                        command.push({ name: 'edit', text: '' });
                    }
                    if (isDeletable) {
                        command.push({ name: 'destroy', text: '' });
                    }
                    result.push({ command: command, title: '&nbsp;' });
                }

                assignedPropertyDefs = true;
            }

            if (!assignedPropertyDefs) {
                //Parse prop defs passed as strings instead of objects
                for (propIndex = 0; propIndex < result.length; propIndex++) {
                    var currentColumn = result[propIndex];
                    if (util.isString(currentColumn)) {
                        result[propIndex] = { field: currentColumn };
                    }
                }
            }
            return result;
        };

        var createColumns = function (entityTypeName, widgetColumns, entityDef, batch, editable, allowDelete) {

            var gridColumns = [];
            var systemName = getSystemName(entityDef);
            var propertyDefs = entityDef.PropertyDefs.Items;
            var columnsArray = getColumnsArray(widgetColumns, propertyDefs, batch, entityDef, editable, allowDelete);
            var isDynamicEntity = util.isDynamicEntity(entityDef.EntityDefID);

            for (var columnIndex = 0; columnIndex < columnsArray.length; columnIndex++) {

                var originalColumn = columnsArray[columnIndex];
                var gridColumn = configureGridColumn(entityTypeName, systemName, originalColumn, propertyDefs, isDynamicEntity);

                gridColumns.push($.extend(originalColumn, gridColumn));

            }

            return gridColumns;
        };

        var localizeColumns = function (options) {
            /*eslint-disable no-param-reassign*/
            if (options && options.columns) {
                for (var index = 0; index < options.columns.length; index++) {
                    var currentColumn = options.columns[index];
                    if (util.isString(currentColumn)) {
                        currentColumn = options.columns[index] = { field: currentColumn };
                    }

                    if (!currentColumn.title) {
                        var newColumnTitle = currentColumn.field;
                        if (currentColumn.titleLocalize) {
                            newColumnTitle = localization.translateStringResource(currentColumn.titleLocalize);
                        } else {
                            var columnName = currentColumn.field;
                            if (columnName.indexOf('.') >= 0) { columnName = columnName.replace(/\./g, localization.keySeparator); }
                            newColumnTitle = localization.translatePropertyName(options.entityTypeName, columnName);
                        }

                        currentColumn.title = newColumnTitle;
                    }

                    if (currentColumn.headerAttributes && currentColumn.headerAttributes.title) {

                        var title = currentColumn.headerAttributes.title;

                        var newTitle = localization.translatePropertyName(options.entityTypeName, title);

                        if (!newTitle) {
                            newTitle = localization.translateStringResource(title);
                            if (!newTitle) {
                                newTitle = title;
                            }
                        }
                        currentColumn.headerAttributes.title = newTitle;
                    } else if (options.defaultHeaderTooltips) {
                        if (util.isObject(currentColumn)) {
                            if (!currentColumn.headerAttributes) {
                                currentColumn.headerAttributes = {};
                            }
                            currentColumn.headerAttributes.title = currentColumn.title;
                        }
                    }
                }
            }
            /*eslint-enable no-param-reassign*/
        };

        //#endregion

        //#region Grid Events

        var shouldFocus = function () {
            return !kendo.support.browser.msie;
        };

        var bindExtraEvents = function (sender) {

            // Bind the filter events to validate the display value filters
            sender.bind('filterMenuInit', filterMenuInit);
            sender.bind('filter', filter);

            if (!sender._dataBoundConfigured) {
                sender.bind('dataBound', entityEditListDataBound);
                sender._dataBoundConfigured = true;
            }

            var dataBind = sender.element.attr('data-bind');
            var selectEntityOnNavigation = sender.options.selectEntityOnNavigation;

            if (dataBind) {
                var hasEntityDoubleClick = dataBind.match(new RegExp(entitySelectedDoubleClick, 'i'));
                var hasEntitiesClick = dataBind.match(new RegExp(entitiesSelected, 'i'));
                var hasEntityEnterKeyDown = dataBind.match(new RegExp(entityEnterKeyDown, 'i'));

                if (hasEntityDoubleClick || hasEntitiesClick) {

                    var instanceDoubleClickTolerance = sender.element.attr('data-doubleclick-tolerance');

                    if (instanceDoubleClickTolerance) {
                        effectiveDoubleClickTolerance = instanceDoubleClickTolerance;
                    } else {
                        effectiveDoubleClickTolerance = defaultDoubleClickTolerance;
                    }

                    $(sender.tbody).off('click.entityEditListBinding', 'tr');
                    $(sender.tbody).on('click.entityEditListBinding', 'tr', function (event) {
                        event.preventDefault();

                        //Get row id
                        var rowId = $(event.target).closest('tr').data('uid');

                        //Bind click and doubleClick events.
                        onRowSelected(sender, rowId);
                    });
                }

                $(sender.table).off('keydown.entityEditList');
                $(sender.table).on('keydown.entityEditList', function (event) {

                    if (selectEntityOnNavigation) {
                        var arrows = [kendo.keys.UP, kendo.keys.DOWN];

                        //Validate up and down arrows
                        if (arrows.indexOf(event.keyCode) >= 0) {
                            event.preventDefault();
                            var currentRow = $('#' + sender._cellId).closest('tr'),
                                currentRowIndex = currentRow.index();

                            if (!event.shiftKey) {
                                //Shift key is not pressed
                                sender.clearSelection();
                                sender.select(currentRow);
                                sender._firstNavigationRowIndex = currentRowIndex;
                            } else {
                                //Shift key is pressed
                                var startIndex = Math.min(currentRowIndex, sender._firstNavigationRowIndex),
                                    endIndex = Math.max(currentRowIndex, sender._firstNavigationRowIndex);
                                var selector = '';
                                for (var index = startIndex; index <= endIndex; index++) {
                                    selector += 'tr:eq(' + index + '),';
                                }
                                selector = selector.substr(0, selector.length - 1);
                                sender.clearSelection();
                                sender.select(selector);
                            }
                        }
                    }

                    //Validate enter key
                    if (hasEntityEnterKeyDown) {
                        if (event.which === kendo.keys.ENTER) {
                            event.preventDefault();
                            var entity = sender.dataItem(sender.select());

                            var enterKeyDownevent = {
                                sender: sender,
                                selectedEntity: entity
                            };

	                        /**
							 * 
							 * Event triggered when the <i>Enter key</i> is pressed on an EntityEditList row.
							 * 
							 * @event modularis.web.widgets.EntityEditList#entityEnterKeyDown
							 * @type {Object}
							 * @property {Object} event - Event object
							 * @property {modularis.web.widgets.EntityEditList} event.sender - EntityEditList that launched the event.
							 * @property {modularis.EntityBase} event.selectedEntity - Selected entity.
							 */
                            sender.trigger(entityEnterKeyDown, enterKeyDownevent);
                        }
                    }

                });
            }

            if (sender.options.navigatable && selectEntityOnNavigation) {

                sender.bind('navigate', function (event) {
                    var container = event.sender.content;
                    container.data('lastScrollTop', container.scrollTop());
                });

                //Validate when grid gets the focus to select the first row if necessary.
                $(sender.table).off('focus.entityEditList');
                $(sender.table).on('focus.entityEditList', function () {
                    var focusTimeout = 100;
                    setTimeout(function () {
                        var rowSelected = sender.select();
                        var firstCell;

                        if (rowSelected.length === 0) {
                            if (sender.options.firstRowSelectedByDefault) {
                                //Select the first row
                                var firstRow = sender.tbody.find('tr:first');
                                sender.select(firstRow);

                                //Select the first cell so that the keyboard navigation starts from there.
                                firstCell = firstRow.children('td:first');
                                sender.current(firstCell);
                            }
                        } else if (sender.current()) {
                            var currentCell = sender.current();
                            currentCell.addClass('k-state-focused');
                            if (!webUtil.isInViewport(currentCell)) {
                                var container = sender.content;
                                container.scrollTop(container.data('lastScrollTop'));
                            }
                        }
                    }, focusTimeout);
                });

                $(sender.tbody).off('click.entityEditListNavigation', 'tr');
                $(sender.tbody).on('click.entityEditListNavigation', 'tr', function (event) {
                    event.preventDefault();

                    //Select clicked cell if the keyboard navigation is being used.
                    var target = $(event.target);
                    if (sender.options.navigatable && target.is('td')) {
                        if (shouldFocus()) {
                            $(sender.table).focus();
                        }
                        sender.current(target);
                    }

                    if (selectEntityOnNavigation && target.is('td')) {
                        sender._firstNavigationRowIndex = target.closest('tr').index();
                    }
                });
            }

            if ((sender.options.modularisEditable === modularisEditableModes.inline ||
                sender.options.modularisEditable === modularisEditableModes.route ||
                sender.options.modularisEditable === modularisEditableModes.popup) && !sender._modularisRemoveHandlerBound && sender.options.modularisAllowDelete) {

                sender.bind('remove', function (event) {
                    if (sender._removedFromHandler) {
                        sender._removedFromHandler = false;
                        return;
                    }

                    event.preventDefault();
                    event.sender.cancelChanges();
                    var inlineOptions = event.sender._modularisInlineOptions;
                    var translate = localization.translateStringResource;
                    var yes = translate(inlineOptions.deleteConfirmationYes);

                    widgetHelper.showMessageWindow({
                        title: translate(inlineOptions.deleteConfirmationTitle),
                        message: translate(inlineOptions.deleteConfirmationMessage),
                        buttonConfiguration: [yes, translate(inlineOptions.deleteConfirmationNo)],
                        buttonPressed: function (buttonText) {
                            if (buttonText === yes) {
                                sender._removedFromHandler = true;
                                var row = event.sender.getRowByDataItemID(event.model.id);
                                event.sender.removeRow(row);
                                if (sender.options.modularisEditable === modularisEditableModes.route ||
                                    sender.options.modularisEditable === modularisEditableModes.popup) {
                                    sender.dataSource.sync();
                                }
                            }
                        }
                    });
                });
                sender._modularisRemoveHandlerBound = true;
            }

        };

        var rowClick = function (sender) {
            var rows = sender.select();

            var entity = {};
            var event = {};
            var entities = [];

            for (var rowIndex = 0; rowIndex < rows.length; rowIndex++) {
                var row = rows[rowIndex];
                entity = sender.dataItem(row);
                entities.push(entity);
            }

            event = {
                sender: sender,
                selectedEntities: entities
            };

	        /**
			 * 
			 * Event triggered when an EntityEditList row is <i>clicked</i>. <p>Hold the CTRL key and click the items in a EntityEditList to choose them. Click all the entities you want to select. They don't have to be next to each other.
             * If you want to select items that are adjacent, you can use the SHIFT key.</p>
			 * 
			 * @event modularis.web.widgets.EntityEditList#entitiesSelected
			 * @type {Object}
			 * @property {Object} event - Event object
			 * @property {modularis.web.widgets.EntityEditList} event.sender - EntityEdit list that throw the event.
			 * @property {modularis.EntityBase} event.selectedEntities - Selected entities.
			 */
            sender.trigger(entitiesSelected, event);
        };

        var rowDoubleClick = function (sender) {
            var entity = sender.dataItem(sender.select());

            var event = {
                sender: sender,
                selectedEntity: entity
            };

	        /**
			 * 
			 * Event triggered when an EntityEditList row is <i>double-clicked</i>.
			 * 
			 * @event modularis.web.widgets.EntityEditList#entitySelectedDoubleClick
			 * @type {Object}
			 * @property {Object} event - Event object
			 * @property {modularis.web.widgets.EntityEditList} event.sender - EntityEditList that launched the event.
			 * @property {modularis.EntityBase} event.selectedEntity - Selected entity.
			 */
            sender.trigger(entitySelectedDoubleClick, event);
        };

        var onRowSelected = function (sender, rowId) {
            webUtil.clickHandler(sender, rowClick, rowDoubleClick, effectiveDoubleClickTolerance, rowId);
        };

        var filterMenuInit = function (event) {
            var entityEditList = event.sender;
            var model = getModel(entityEditList);
            var dataSource = entityEditList.dataSource;
            var fieldNameDV = event.field + displayValueIdentifier;
            var filterMenuInfo = entityEditList._filterMenuInfos[event.field];

            // Validates if the model contains the display value field name
            if (model.hasOwnProperty(fieldNameDV) && !filterMenuInfo) {

                // Binds the click event of the clear filter button for Display Values
                /*eslint-disable no-unused-vars*/
                $(event.container).find('[type=\'reset\']').click(function (event) {
                    var filter = dataSource.filter();

                    if (filter) {
                        var filters = filter.filters;

                        // Removes the filters for the display value field name
                        filters = $.grep(filters, function (filter) {
                            return filter.field === fieldNameDV;
                        }, true);

                        dataSource.filter(filters);
                    }
                });

                //Binds the _bind event of the FilterMenu widget to update the filter popup model
                var filterMenu = $(entityEditList.element).find('[data-role=\'filtermenu\'][data-field=\'' + event.field + '\']').getKendoFilterMenu();

                if (!filterMenu) {
                    // In case the sortable feature is activated the data-role of the FilterMenu changes.
                    filterMenu = $(entityEditList.element).find('[data-role=\'columnsorter\'][data-field=\'' + event.field + '\']').getKendoFilterMenu();
                }

                // Save the filter menu widget for the display value field in the cache
                filterMenuInfo = {
                    oroginalBindMethod: filterMenu._bind
                };

                entityEditList._filterMenuInfos[event.field] = filterMenuInfo;

                // Overrides the _bind method of the FilterMenu widget
                filterMenu._bind = function (event) {
                    var filterMenuInfo = entityEditList._filterMenuInfos[this.field];
                    var optionsCopy = $.extend({}, event);
                    var filters = optionsCopy.filters;
                    var newFilters = [];

                    for (var filterIndex = 0; filterIndex <= filters.length - 1; filterIndex++) {
                        var filter = filters[filterIndex];
                        var newFilter = $.extend({}, filter);

                        // Validates if the model contains the display value field name and removes the display value identifier (DV)
                        // to update the FilterMenu Popup model.
                        if (model.hasOwnProperty(newFilter.field) && newFilter.field.slice(-displayValueIdentifier.length) === displayValueIdentifier) {
                            newFilter.field = newFilter.field.slice(0, -displayValueIdentifier.length);
                        }

                        newFilters.push(newFilter);
                    }

                    // Set the filters without the display value identifier (DV)
                    optionsCopy.filters = newFilters;

                    // Call the original _bind method.
                    var found = filterMenuInfo.oroginalBindMethod.apply(this, [optionsCopy]);
                    return found;
                };
            }
        };

        var filter = function (event) {
            var entityEditList = event.sender;
            var model = getModel(entityEditList);
            var dataSource = entityEditList.dataSource;

            var fieldNameDV = event.field + displayValueIdentifier;

            // Validate if the model contains the DisplayValue property
            if (model.hasOwnProperty(fieldNameDV) && event.filter !== null) {
                var currentFilter = [];

                if (dataSource.filter()) {
                    // Retain the other filters
                    currentFilter = dataSource.filter().filters;

                    // Remove the filters for the display value field name
                    currentFilter = $.grep(currentFilter, function (filter) {
                        return filter.field === fieldNameDV;
                    }, true);
                }

                // Add the new filters for the display value field name
                for (var filterIndex = 0; filterIndex < event.filter.filters.length; filterIndex++) {
                    var filter = event.filter.filters[filterIndex];
                    var value = filter.value;
                    var operator = filter.operator;

                    currentFilter.push({ field: fieldNameDV, operator: operator, value: value });
                }

                event.preventDefault();
                dataSource.filter({ logic: event.filter.logic, filters: currentFilter });
            }
        };

        //#endregion

        var getModel = function (entityEditList) {
            var data = entityEditList.dataSource.data();

            if (data.length > 0) {
                return data[0];
            }

            return {};
        };

        var checkEntityTypeName = function (options) {
            if (!options.entityTypeName) {
                throw new Error('The entity type name is required for the EntityEditList widget.');
            }
        };

        var hasSourceDatabind = function (element) {
            var databind = $(element).data('bind');
            databind = databind ? databind.replace(' ', '') : '';
            var result = databind.length > 0 && databind.indexOf('source:') > -1;
            return result;
        };

        var showCrudFeedBackMessage = function (type, response, inlineOptions, extraInfo) {
            var messageType = response ? 'Success' : 'Error';

            var messagePropertyOption = String.format('{0}{1}Message', type ? type.replace('destroy', 'delete') : 'default', messageType);
            var messageKey = inlineOptions[messagePropertyOption];

            var feedBackMessage = localization.translateStringResource(messageKey);

            var messageNotification = (feedBackMessage ? feedBackMessage : messageKey) + (extraInfo ? extraInfo : '');

            widgetHelper.showMessageNotification(messageNotification, messageType.toLowerCase());
        };

        var dataSourceRequestEnd = function (inlineOptions) {
            return function (event) {
                var type = event.type;
                var response = event.response;

                switch (type) {
                    case 'update':
                    case 'create':
                    case 'destroy':
                        showCrudFeedBackMessage(type, response, inlineOptions);
                        break;
                    default:
                        break;
                }
            };
        };

        var dataSourceError = function (inlineOptions) {
            return function (event) {
                var sender = event.sender;
                sender.cancelChanges();

                var extraInformation = null;
                if (inlineOptions.displayRequestErrors) {
                    extraInformation = String.format('Code: {0}< br/>Message: {1}', event.xhr.response.Code, event.xhr.response.Message);
                }

                showCrudFeedBackMessage(null, null, inlineOptions, extraInformation);
                inlineOptions = null;
            };
        };

        var entityEditListDataBound = function (event) {
            var entityEditList = event.sender;

            if (!entityEditList._feedbackMessageEnvetConfigured) {
                $.extend(true, entityEditList._modularisInlineOptions, modularisInlineDefaultOptions, entityEditList.options.modularisInlineOptions);
                var inlineOptions = entityEditList._modularisInlineOptions;
                if (entityEditList._modularisInlineOptions.feedbackMessage) {

                    //entityEditList.dataSource.unbind('requestEnd', dataSourceRequestEnd);
                    //entityEditList.dataSource.unbind('error', dataSourceError);
                    entityEditList.dataSource.bind('requestEnd', dataSourceRequestEnd(inlineOptions));
                    entityEditList.dataSource.bind('error', dataSourceError(inlineOptions));
                    entityEditList._feedbackMessageEnvetConfigured = true;
                }
            }
        };

	    /**
		 * @classdesc Modularis EntityEditList widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/grid|kendo.ui.Grid}.
		 * @constructs modularis.web.widgets.EntityEditList
		 * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
		 * 
		 * @property {Object} options - Widget options.
		 * @property {string} options.entityTypeName - Full name of the entities that will be bound to the widget.
         * @property {Object} [options.entityEditView] - Indicates the detail view identifier. Uses this option to configure a Master-detail data representation.
		 * @property {boolean} [options.defaultHeaderTooltips=false] - If true, a title header attribute will be added to each column.
		 * @property {boolean} [options.optimizeForWeb=true] - If true, adds the display value for each lookup property.
		 * @property {boolean} [options.selectEntityOnNavigation=false] - If true, the entity row will be selected when the user is navigating using the keyboard. The navigable option must be true.
		 * @property {string} [options.modularisEditable=inline] - Set to <b>"popup"</b> to show a popup window. Set to <b>"route"</b> to show the details in a different route. Set to <b>"inline"</b> to enable inline editing.
		 * @property {boolean} [options.modularisAllowAdd=false] - If true, and if the security allows the creation of new records, it displays a "add new" button in the toolbar.
		 * @property {boolean} [options.modularisAllowDelete=false] - If true, and if the security allows delete, it displays a remove button for each row in the grid.
		 * @property {Object} [options.modularisPopupOptions] - Sets custom properties to the popup. Check {@link modularis.web.widgets.EntityEditList~modularisPopupOptions|modularisPopupOptions} object for available values.
		 * @property {string} [options.modularisEditableView] - Sets the path of the custom view to be displayed in the Popup or New Window.
		 * @property {string} [options.modularisEditableViewModel] - Sets the path of the ViewModel related to the custom editable view.
		 * @property {string} [options.modularisEditableRouteName] - Sets the route name of the custom view configured in the routes file. This setting applies only for "route" value of the modularisEditable attribute.
		 * @property {Object} [options.modularisInlineOptions] - Sets custom properties to the inline CRUD operation messages. Check {@link modularis.web.widgets.EntityEditList~modularisInlineOptions|modularisInlineOptions} object for available values.
		 * @property {boolean} [options.toolbarColumnMenu=false] - If true, it displays a toolbar menu to toggle visibility for each column in the grid.
		 * @property {boolean} [options.print=false] - If true, it displays a toolbar print button.
		 * @property {boolean|Object} [options.batch=false] - Sets custom items for a toolbar menu and adds an extra checkbox selection column. {@link http://docs.telerik.com/kendo-ui/controls/navigation/menu/overview#initialize-the-menu}
         * @property {boolean} [options.firstRowSelectedByDefault=true] - If true, when unselecting a selected row, the first one by default is going to be selected.
		 * 
		 */
        //enable this and options.search when the command is ready @property {boolean} [options.search=false] - If true, it display a toolbar search bar.
        var EntityEditList = Grid.extend({

            _tempColumns: null,
            _modularisInlineOptions: {},
            _toolbarInitialized: false,

            _gridInitialized: false,
            _assignNewDataSource: false,
            _dataRead: false,
            _lookupConfigured: false,
            _feedbackMessageEnvetConfigured: false,
            _dataBoundConfigured: false,
            _modularisRemoveHandlerBound: false,
            _columsSelection: [],
            _batchEvents: [],
            _entityDefLoaded: false,
            _filterMenuInfos: {},

            init: function (element, options) {
                var that = this;
                that._assignNewDataSource = !hasSourceDatabind(element) && !options.dataSource;
                checkEntityTypeName(options);
                if (that._tempColumns) {
                    options.columns = that._tempColumns;
                } else {
                    localizeColumns(options);
                }
                if ((options.toolbarColumnMenu || options.print || options.batch || options.search) && !options.toolbar) {
                    options.toolbar = true;
                }
                Grid.fn.init.call(that, element, options);

                that._filterMenuInfos = {};
                that._initComponents();
            },

            options: {
                name: 'EntityEditList',
                entityTypeName: null,
                entityEditView: null,
                optimizeForWeb: true,
                defaultHeaderTooltips: false,
                selectEntityOnNavigation: false,
                modularisEditable: '',
                modularisAllowAdd: false,
                modularisAllowDelete: false,
                modularisPopupOptions: {},
                modularisEditableView: '',
                modularisEditableViewModel: '',
                modularisEditableRouteName: '',
                modularisInlineOptions: {},
                toolbarColumnMenu: false,
                print: false,
                batch: false,
                firstRowSelectedByDefault: true
            },

            events: [
                entitySelectedDoubleClick,
                entitiesSelected,
                entityEnterKeyDown
            ],

            _initComponents: function () {
                var that = this;

                //Check if the grid was already initialized.
                if (that._gridInitialized) {
                    if (that._tempColumns) {
                        that._updateColumnTitles(that._tempColumns);
                        that._tempColumns = null;
                    }
                    if ((that.options.print || that.options.toolbarColumnMenu || that.options.batch || that.options.search || that.options.modularisAllowAdd) && !that._toolbarInitialized) {
                        that._setToolbarExtend();
                    }

                    bindExtraEvents(that);

                    return;
                }

                that._assignAdditionalAttributes();

                if (that._entityDefLoaded) {
                    return;
                }

                //Prevent the EntityDef to be loaded multiple times after setting the options, using the setOptions function.
                that._entityDefLoaded = true;

                metadataCache.getEntityDef({
                    entityDefId: that.options.entityTypeName,
                    includePropertyDefs: true,
                    callback: function (entityDef, entityDefError) {
                        if (util.success(entityDef, entityDefError)) {

                            if (!$.isEmptyObject(entityDef)) {
                                //Configure dataSource
                                if (that._assignNewDataSource) {
                                    var newDataSource = dataSourceFactory._createSync(entityDef, {
                                        modularis: {
                                            activity: {
                                                entityTypeName: that.options.entityTypeName,
                                                method: 'getAll',
                                                getChildren: false,
                                                options: {
                                                    OptimizeForWeb: that.options.optimizeForWeb
                                                }
                                            }
                                        }
                                    });
                                    that._assignNewDataSource = false;
                                    that.setDataSource(newDataSource);
                                }

                                //Configure filtering
                                if (that.options.filterable) {
                                    /*eslint-disable no-param-reassign*/
                                    kendo.ui.Grid.fn.options.filterable = true;
                                    /*eslint-enable no-param-reassign*/
                                }

                                that._handleEntityEditView();

                                //Configure columns
                                var gridColumns = createColumns(that.options.entityTypeName, that.options.columns, entityDef, that.options.batch, that.options.modularisEditable, that.options.modularisAllowDelete);
                                var isAddNewAvailable = securityHelper.isFunctionApproved(entityDef.AllowAddToken, getSystemName(entityDef)) && that.options.modularisAllowAdd;

                                that._gridInitialized = true;
                                that._tempColumns = gridColumns;
                                //The following lines will invoke the init function again. The _gridInitialized flag prevents an infinite loop.
                                var newOptions = {
                                    columns: gridColumns
                                };
                                if (isAddNewAvailable) {
                                    var addButton = that._createAddButton();
                                    if (util.isArray(that.options.toolbar)) {
                                        newOptions.toolbar = that.options.toolbar;
                                        newOptions.toolbar.unshift(addButton);
                                    } else if (!(util.isFunction(that.options.toolbar) || util.isString(that.options.toolbar))) {
                                        newOptions.toolbar = [addButton];
                                    }
                                }
                                if (that.options.modularisEditable && (that.options.modularisEditable.toLowerCase() === modularisEditableModes.inline || that.options.modularisEditable.toLowerCase() === modularisEditableModes.route)) {
                                    newOptions.editable = { mode: that.options.modularisEditable.toLowerCase(), confirmation: false };
                                }
                                that.setOptions(newOptions);

                                //Validate if the EntityEditList is Editable and create popup or view functionalities.
                                that._modularisEditable();
                            } else {
                                logger.error('Please provide a valid EntityTypeName.');
                            }
                        } else {
                            that.element.text('Error loading metadata');
                        }
                    }
                });

            },

            _createAddButton: function () {
                var addButton = { template: addButtonTemplate };
                return addButton;
            },

            //custom fix for checkbox selection kendo ui unsolved bug
            _headerCheckboxClick: function (event) {
                var that = this, checkBox = $(event.target), checked = checkBox.prop('checked'), parentGrid = kendo.widgetInstance(checkBox.closest('.k-grid.k-widget'));
                if (that !== parentGrid) {
                    return;
                }
                if (checked) {
                    that.select(parentGrid.items());
                } else {
                    that.clearSelection();
                }
            },
            _checkboxClick: function (event) {
                var that = this, row = $(event.target).closest('tr'), isSelecting = !row.hasClass('k-state-selected');
                if (that !== kendo.widgetInstance(row.closest('.k-grid.k-widget'))) {
                    return;
                }
                if (isSelecting) {
                    that.select(row);
                } else {
                    that._deselectCheckRows(row);
                }
            },

            //add new feature using pop up
            addWithPopUp: function (event) {
                var that = this;
                event.preventDefault();
                that._createModularisPopup();
            },

            //add new feature using generated view
            addWithView: function () {
                var that = this;
                event.preventDefault();
                var entityName = that.options.entityTypeName.substr(that.options.entityTypeName.lastIndexOf('.') + 1).toLowerCase(),
                    routeToNavigate = String.format('#/entity/{0}/new', entityName);
                routingManager.router.navigate(routeToNavigate);
            },

            //Make this widget a value-bound widget
            value: function (widgetValue) {
                var that = this;
                var result = null,
                    index = 0;
                if (util.isDefined(widgetValue)) {

                    //A null value should clear any selected row.
                    if (widgetValue === null) {
                        that.clearSelection();
                        return null;
                    }

                    //Set the value
                    var rowsSelector;
                    if (!that._isMultipleSelectionEnabled() || !webUtil.isArray(widgetValue)) {
                        rowsSelector = String.format(rowSelectorFormat, widgetValue.uid);
                    } else {
                        var selectors = [];
                        for (index = 0; index < widgetValue.length; index++) {
                            selectors.push(String.format(rowSelectorFormat, widgetValue[index].uid));
                        }
                        rowsSelector = selectors.join();
                    }
                    that.select(rowsSelector);
                } else {
                    //return the current value
                    var selectedElements = that.select();
                    if (selectedElements && selectedElements.length > 0) {

                        if (!that._isMultipleSelectionEnabled()) {
                            result = that.dataItem(selectedElements[0]);
                        } else {
                            result = [];
                            for (index = 0; index < selectedElements.length; index++) {
                                result.push(that.dataItem(selectedElements[index]));
                            }
                        }
                    }
                }
                return result;
            },

            refreshData: function () {
                this.dataSource.read();
            },

            destroy: function () {
                var that = this;
                //unbind from events
                $(that.tbody).off('click.entityEditListBinding', 'tr');
                $(that.tbody).off('click.entityEditListNavigation', 'tr');
                $(that.table).off('keydown.entityEditList');
                $(that.table).off('focus.entityEditList');
                $(that.table).off('click.entityEditList', '.k-checkbox');
                $(that.wrapper).off('click.EditableEntityEditList', editButtonSelector);
                $(that.wrapper).off('click.EditableEntityEditList', deleteButtonSelector);
                $(that.wrapper).find('.k-grid-toolbar').off('click.entityEditListToolbar', '.k-grid-print');

                if ($(that.wrapper).find('.m-column-menu').data('kendoMenu')) {
                    $(that.wrapper).find('.m-column-menu').data('kendoMenu').destroy();
                }

                clearTimeout($(that.tbody).data('alreadyclickedTimeout'));
                that._lookupConfigured = false;

                Grid.fn.destroy.apply(that, arguments);
            },

            refreshRestoringCurrentCell: function () {
                var that = this;
                var focusedCell = that.current();
                var focusedCellIndex = focusedCell.index();
                var rowIndex = focusedCell.closest('tr').index();
                var lastScrollTop = that.content.scrollTop();

                that.refresh();

                var row = that.tbody.find('tr:eq(' + rowIndex + ')');
                var cell = row.find('td:eq(' + focusedCellIndex + ')');
                that.select(row);
                that.current(cell);
                that.content.scrollTop(lastScrollTop);
            },

            getRowByDataItemID: function (dataItemID) {
                var dataItem = this.dataSource.get(dataItemID);
                var row = this.tbody.find('tr[data-uid="' + dataItem.uid + '"]');
                return row;
            },

            searchGrid: function (keyword) {
                var entityNameArray = this.options.entityTypeName.split('.');
                var entityName = entityNameArray[entityNameArray.length - 1];
                routingManager.router.navigate('#/search/' + entityName + '/' + keyword);
            },

            printGrid: function () {
                var gridElement = this.wrapper,
                    printableContent = '',
                    hasCheckboxSelection = this.options.batch ? 'tr>th:first-child, tr>td:first-child{display:none;}' : '';

                var htmlStart =
                    '<!DOCTYPE html>' +
                    '<html>' +
                    '<head>' +
                    '<meta charset="utf-8" />' +
                    '<title>Print</title>' +
                    '<style>' +
                    'html { font: 11pt sans-serif; }' +
                    '.k-grid { border-top-width: 1px; text-align:left; }' +
                    '.k-grid, .k-grid-content { height: auto !important; }' +
                    '.k-grid-content { overflow: visible !important; }' +
                    'div.k-grid table { table-layout: auto; width: 100% !important; border-spacing:0;border-collapse:collapse;}' +
                    'td { border-width: 1px 0; border-style:solid; border-color:#b9b9b9}.k-grid .k-grid-header th{border-width:0 0 2px;border-style:solid;}.k-grid .k-grid-header th a{text-decoration:none;color:#000;}' +
                    '.k-grouping-header, .k-grid-toolbar, .k-grid-pager > .k-link,.k-command-cell{ display: none; }' +
                    hasCheckboxSelection +
                    '</style>' +
                    '</head>' +
                    '<body>';

                var htmlEnd =
                    '</body>' +
                    '</html>';
                var gridHeader = gridElement.children('.k-grid-header');
                if (gridHeader[0]) {
                    var thead = gridHeader.find('thead').clone().addClass('k-grid-header');
                    printableContent = gridElement
                        .clone()
                        .children('.k-grid-header').remove()
                        .end()
                        .children('.k-grid-content')
                        .find('table')
                        .first()
                        .children('tbody').before(thead)
                        .end()
                        .end()
                        .end()
                        .end()[0].outerHTML;
                } else {
                    printableContent = gridElement.clone()[0].outerHTML;
                }

                var contentTotal = htmlStart + printableContent + htmlEnd;
                var blob = new Blob([contentTotal], { type: 'text/html' }),
                    uri = URL.createObjectURL(blob),
                    printWindow = window.open(uri, '', 'width= 800, height = 500, resizable = 1, scrollbars = 1');
                printWindow.addEventListener('load', function () {
                    URL.revokeObjectURL(uri); // release these
                    blob = null; //from memory
                    printWindow.print();
                });
            },

            _isMultipleSelectionEnabled: function () {
                var selectableOption = this.getOptions().selectable;
                var multipleSelectionEnabled = (selectableOption && selectableOption.toLowerCase().indexOf('multiple') >= 0);
                return multipleSelectionEnabled;
            },

            _handleEntityEditView: function () {

                var that = this;
                var entityEditViewSelector = that.options.entityEditView;
                if (!entityEditViewSelector) { return; }

                if (!entityEditViewSelector.startsWith('#')) {
                    entityEditViewSelector = '#' + entityEditViewSelector;
                }

                var modularisView = $(entityEditViewSelector).data('kendoModularisView');

                if (modularisView) {
                    modularisView.assignEntityEditList(that.element);
                }
            },

            _updateColumnTitles: function (gridColumns) {
                var format = 'th[data-field="{0}"]';
                for (var index = 0; index < gridColumns.length; index++) {
                    //Solve Kendo limitation updating column titles using setOptions
                    var currentColumn = gridColumns[index];

                    if (currentColumn.title) {

                        var columnHead = this.thead.find(String.format(format, currentColumn.field));

                        var headLink = columnHead.find('a.k-link');
                        if (headLink.length) {
                            //When the column head contains a link, we should update the content of that link.
                            headLink.html(currentColumn.title);
                        } else if (currentColumn.headerAttributes && !currentColumn.headerAttributes.title) {
                            columnHead.attr('title', currentColumn.title);
                        }
                    }
                }
            },

            _setToolbarExtend: function () {
                var that = this;
                if (that.options.toolbarColumnMenu) {
                    that._createColumnMenu();
                }
                if (that.options.print) {
                    that._setToolbarPrint();
                }
                if (typeof that.options.batch === 'object') {
                    that._setToolbarBatch();
                }
                if (that.options.search) {
                    that._setToolbarSearch();
                }
                if (that.options.modularisAllowAdd) {
                    that._setAddButtonBind();
                }
                that._toolbarInitialized = true;
            },

            _setAddButtonBind: function () {
                var that = this;
                var $toolbar = $(that.wrapper).find('.k-grid-toolbar');

                // Adds the add button if it has not been added.
                if ($toolbar.find('.add-button').length === 0) {
                    $toolbar.prepend(addButtonTemplate);
                }

                if (that.options.modularisEditable === 'popup') {
                    $(that.wrapper).find('.k-grid-toolbar').on('click', '.add-button', $.proxy(that.addWithPopUp, that));
                } else {
                    $(that.wrapper).find('.k-grid-toolbar').on('click', '.add-button', $.proxy(that.addWithView, that));
                }
            },

            _setToolbarPrint: function () {
                var that = this, container = that.wrapper.find('.k-grid-toolbar');
                container.append('<a role="button" class="k-button k-button-icontext k-grid-print"><span class="k-icon k-i-print"></span>' + localization.translateStringResource('entity_edit_list_print') + '</a>');
                container.on('click.entityEditListToolbar', '.k-grid-print', function (event) {
                    event.preventDefault();
                    that.printGrid();
                });
            },

            _setToolbarBatch: function () {
                var that = this;
                that._batchEvents = [];
                if (that.options.batch instanceof Array) {
                    for (var idx = 0; idx < that.options.batch.length; idx++) {
                        that._batchEvents.push({
                            encoded: false,
                            text: that.options.batch[idx].text,
                            cssClass: 'm-batch-' + that.options.batch[idx].selector || that.options.batch[idx].text.replace(/\s+/g, '')
                        });
                    }
                }

                that.wrapper.find('.k-grid-toolbar').append('<div class="m-batch-menu"/>');
                that.wrapper.find('.m-batch-menu').kendoMenu({
                    dataSource: [{
                        text: 'Batch Actions',
                        items: that._batchEvents
                    }],
                    openOnClick: true,
                    closeOnClick: true
                });
            },

            _setToolbarSearch: function () {
                var that = this, container = that.wrapper.find('.k-grid-toolbar');
                container.append('<div class="m-float-right m-search-menu"><input type="search" placeholder="Search" class="k-textbox search-input"/><button role="button" class="k-button k-button-icontext k-grid-search"><span class="k-icon k-i-search"></span></button></div>');
                container.on('click.entityEditListToolbar', '.k-grid-search', function (event) {
                    event.preventDefault();
                    that.searchGrid(container.find('.search-input').val());
                });
            },

            _createColumnMenu: function () {
                var that = this;
                that._columsSelection = [];
                for (var idx = 0, max = that.columns.length; idx < max; idx++) {
                    if (that.columns[idx].field && !(that.columns[idx].allowView != null)) {
                        that._columsSelection.push({
                            encoded: false,
                            text: '<input type="checkbox" checked="checked" ' +
                                ' class="check k-checkbox" id="' + that.columns[idx].headerAttributes.id + '" data-field="' + that.columns[idx].field +
                                '"/><label class="k-checkbox-label" for="' + that.columns[idx].headerAttributes.id + '">' + that.columns[idx].field + '</label>'
                        });
                    }
                }
                if (that.wrapper.find('.k-grid-toolbar').find('.m-column-menu').length === 0) {
                    that.wrapper.find('.k-grid-toolbar').append('<div class="m-column-menu"/>');
                    that.wrapper.find('.m-column-menu').kendoMenu({
                        dataSource: [{
                            text: '',
                            spriteCssClass: 'k-icon k-i-gear',
                            items: that._columsSelection
                        }],
                        openOnClick: true,
                        closeOnClick: false,
                        open: function () {
                            var selector;

                            $.each(that.columns, function () {
                                if (this.hidden) {
                                    selector = 'input[data-field="' + this.field + '"]';
                                    $(selector).prop('checked', false);
                                }
                            });
                        },
                        select: function (event) {

                            if ($(event.item).parent().filter('div').length) {
                                return;
                            }

                            var input = $(event.item).find('input.check');
                            var field = $(input).data('field');
                            if ($(input).is(':checked')) {
                                that.showColumn(field);
                            } else {
                                that.hideColumn(field);
                            }
                        }
                    });
                }
            },

            _assignAdditionalAttributes: function () {
                var that = this;
                var additionalAtributes = webUtil.getAdditionalAttributes(that.element, that.options);
                if (util.isEmptyObject(additionalAtributes)) { return; }

	            /* The additional attributes will be assigned to a hidden field created after the grid.
				 * This simplifies the implementation of custom validations on the grid.
				 */
                var hiddenField = $('<input type="hidden"/>');

                for (var attributeName in additionalAtributes) {
                    if (additionalAtributes.hasOwnProperty(attributeName)) {
                        hiddenField.attr(attributeName, additionalAtributes[attributeName]);
                    }
                }

                $(that.element).after(hiddenField);
            },

            _modularisEditable: function () {
                var that = this;

                that.wrapper.on('click.EditableEntityEditList', editButtonSelector, function (event) {
                    event.preventDefault();
                    that._modularisEditRow($(this).closest('tr'));
                });

                if (that.options.modularisEditable === modularisEditableModes.popup) {
                    that.wrapper.on('click.EditableEntityEditList', deleteButtonSelector, function (event) {
                        //debugger;
                        event.preventDefault();
                        event.stopPropagation();
                        that.removeRow($(this).closest('tr'));
                    });
                }
            },

            _modularisEditRow: function (row) {
                var that = this;

                row = $(row);

                var entity = that.dataItem(row);
                var editableMode = that.options.modularisEditable;

                if (entity) {
                    switch (editableMode) {
                        case modularisEditableModes.route:
                            that._navigateToRoute(entity);
                            break;
                        case modularisEditableModes.popup:
                            that._createModularisPopup(entity);
                            break;
                        //no default
                    }
                }
            },

            _navigateToRoute: function (entity) {
                var that = this;

                var routeToNavigate = '';
                var entityKey = entity.getKey();

                if (!that.options.modularisEditableRouteName) {
                    var entityName = that.options.entityTypeName.substr(that.options.entityTypeName.lastIndexOf('.') + 1);
                    routeToNavigate = String.format('/entity/{0}/update/{1}', entityName, entityKey);
                } else {
                    var route = routingManager.findRouteByName(that.options.modularisEditableRouteName);

                    if (route) {
                        routeToNavigate = route.routePath.replace(':key', entityKey);
                    } else {
                        logger.error('Route name not found. RouteName [ ' + that.options.modularisEditableRouteName + ' ]');
                    }
                }

                routingManager.router.navigate(routeToNavigate);
            },

            _createModularisPopup: function (entity) {
                var that = this;

                var popupOptions = {};
                $.extend(true, popupOptions, modularisPopupDefaultOptions, that.options.modularisPopupOptions);

                var customView = '';
                var windowId = 'entity-edit-list-popup-' + util.newGuid();

                var modularisViewTemplateFormat = '<div id="{0}"><div data-role="modularisview"  data-entity-type-name="{1}"  data-bind="activeEntity: selectedEntity" {2}></div></div>';

                if (that.options.modularisEditableView && that.options.modularisEditableViewModel) {
                    customView = 'data-view="' + that.options.modularisEditableView + '" data-view-model="' + that.options.modularisEditableViewModel + '"';
                }

                var modularisViewTemplateMarkup = String.format(modularisViewTemplateFormat, windowId, that.options.entityTypeName, customView);

                //Add the ModularisView to the DOM
                $('body').append(modularisViewTemplateMarkup);

                //Bind the ModularisView to the Entity
                var modularisViewObject = $('#' + windowId + ' [data-role=modularisview]');
                kendo.bind(modularisViewObject, { selectedEntity: entity });

                //Localize the Window Title
                var localizedTitle = localization.translateStringResource(popupOptions.title);

                if (localizedTitle) {
                    popupOptions.title = localizedTitle;
                }

                //Create the popup
                var popup = $('#' + windowId).kendoWindow(popupOptions).getKendoWindow();

                var modularisView = modularisViewObject.getKendoModularisView();

                modularisView.bind(enums.modularisViewEventName.afterViewModelAssigned, function (eventData) {
                    var originalAfterViewIsDisplayed = eventData.newInstance.afterViewIsDisplayed.bind(eventData.newInstance);

                    eventData.newInstance.afterViewIsDisplayed = function () {
                        popup.open();
                        popup.center();
                        popup.bind('close', function (event) {
                            event.sender.destroy();
                        });

                        originalAfterViewIsDisplayed();
                    };
                });

                //Bind the events
                modularisView.bind('saveCompleted', function (event) {
                    that._closePopup(event, popup);
                });

                modularisView.bind('deleteCompleted', function (event) {
                    that._closePopup(event, popup);
                });
            },

            _closePopup: function (event, popup) {
                var that = this;
                event.preventDefault();
                that.refreshData();
                popup.close();
            }

        });

        return {
            widgetClass: EntityEditList
        };

    }
);
