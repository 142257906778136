define('RESTSecurityWebServiceProxy',
    ['jquery', 'RESTWebServiceProxy', 'clientCache', 'logger', 'enums', 'util', 'proxyHelper'],
    function ($, proxyBase, clientCache, logger, enums, util, proxyHelper) {
        'use strict';
        
        /**
         * Modularis Security Service Proxy
         * @namespace securityServiceProxy
         * @memberof modularis.proxy
         */

        var RESTSecurityWebServiceProxy = function (serviceName) {

            var that = this;
            proxyBase.RESTWebServiceProxy.call(that, serviceName);

            /**
             * Authenticates the user and initializes Session.
             * @name authenticate
             * @function
             * 
             * @param {String} username - The user's username.
             * @param {String} password - The user's password.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.authenticate = function (username, password, options, callback) {

                var activeServer = that._getActiveServer(options);

                if (proxyHelper.handleServerConfigurationException(activeServer, callback)) { return; }

                //Default options for authenticate operation
                var authenticateOptions = {};
                authenticateOptions[enums.options.returnEntireSession] = true;

                //Merge options
                $.extend(authenticateOptions, options);

                var params = {
                    CustomerID: activeServer.customerID,
                    DeviceName: clientCache.getDeviceName(),
                    Options: authenticateOptions,
                    Password: password,
                    UIDefID: clientCache.getUIDefID(),
                    Username: username
                };

                that.sendJSONRequest('Authenticate', params, function (data, error) {

                    var responseData = data;
                    var responseError = error;

                    // 1 --> AuthenticationSuccessful
                    if (util.success(data, error) && (data.Status === enums.authenticationStatus.authenticationSuccessful)) {
                        clientCache.setSession(data);
                        that._trigger(enums.securityEventName.authenticationSuccessful, { session: data });
                    } else {
                        responseData = {};
                        responseError = 'Authentication failed';
                        that._trigger(enums.securityEventName.authenticationFailed);
                    }
                    util.notify(callback, responseData, responseError);
                });
            };

            /**
             * Destroys the Session from the System.
             * @name logOff
             * @function
             * 
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.logOff = function (options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    SessionID: clientCache.getSessionID(),
                    Options: options
                };
                that.sendJSONRequest('LogOff', params, function (data, error) {
                    if (error == null) {
                       clientCache.setSession(null);
                       try {
                          that._trigger(enums.securityEventName.logOff);
                       } catch (error) {
                           logger.error('Error in log-off event handler: ' + error);
                       }
                       
                    }                    
                    util.notify(callback, data, error);
                }, 'text');
            };

            /**
             * Creates a new role with the same function assignments assigned to the role identified by roleID.
             * @name copySystemRole
             * @function
             * 
             * @param {Number} roleID - The unique identifier of the source role.
             * @param {String} name - The name of the new role.
             * @param {String} description - The description of the new role.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.copySystemRole = function (roleID, name, description, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Description: description,
                    Name: name,
                    RoleID: roleID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('CopySystemRole', params, callback);
            };

            /**
             * Deletes the role from the Modularis Accelerator Security database.
             * @name deleteSystemRole
             * @function
             * 
             * @param {EntityBase} systemRoleEntity - The role instance to delete.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.deleteSystemRole = function (systemRoleEntity, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: systemRoleEntity,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('DeleteSystemRole', params, callback);
            };

            /**
             * Deletes the user entity from the security storage.
             * @name deleteUser
             * @function
             * 
             * @param {EntityBase} userEntity - The user entity to delete.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.deleteUser = function (userEntity, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: userEntity,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('DeleteUser', params, callback);
            };

            /**
             * Returns approved functions for the specified System and Role.
             * @name getApprovedFunctions
             * @function
             * 
             * @param {Number} roleID - Role ID.
             * @param {String} systemName - System Name.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getApprovedFunctions = function (roleID, systemName, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    RoleID: roleID,
                    SessionID: clientCache.getSessionID(),
                    SystemName: systemName
                };
                that.sendJSONRequest('GetApprovedFunctions', params, callback);
            };

            /**
             * Returns accessible modules based on authentication.
             * @name getAvailableModules
             * @function
             * 
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getAvailableModules = function (callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetAvailableModules', params, callback);
            };

            /**
             * Creates and initializes a new system role instance.
             * @name getNewSystemRole
             * @function
             * 
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getNewSystemRole = function (callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetNewSystemRole', params, callback);
            };

            /**
             * Gets a new user instance from the security storage.
             * @name getNewUser
             * @function
             * 
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getNewUser = function (callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetNewUser', params, callback);
            };

            /**
             * Gets a collection of user system role junction entities that represent the assignment of users to the role identified by roleID.
             * @name getRoleSystemUsers
             * @function
             * 
             * @param {Number} roleID - The unique identifier of the role.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getRoleSystemUsers = function (roleID, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    ID: roleID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetRoleSystemUsers', params, callback);
            };

            /**
             * Gets the system functions assigned to the role identified by roleID.
             * @name getSystemFunctionRoles
             * @function
             * 
             * @param {Number} roleID - The unique identifier of the role.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getSystemFunctionRoles = function (roleID, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    ID: roleID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetSystemFunctionRoles', params, callback);
            };

            /**
             * Fetches a system role by its name.
             * @name getSystemRole
             * @function
             * 
             * @param {String} name - The name of the system role to fetch.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getSystemRole = function (name, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Name: name,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetSystemRole', params, callback);
            };

            /**
             * Gets the system roles for the system where the session was acquired.
             * @name getSystemRoles
             * @function
             * 
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getSystemRoles = function (callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetSystemRoles', params, callback);
            };

            /**
             * Gets all the users registered in the Modularis Accelerator Security database.
             * @name getUsers
             * @function
             * 
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getUsers = function (callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetUsers', params, callback);
            };

            /**
             * Gets a collection of junction entities that represent the association of a user with his/her assigned system roles.
             * @name getUserSystemRoles
             * @function
             * 
             * @param {Number} userID - The user unique identifier.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.getUserSystemRoles = function (userID, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    ID: userID,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('GetUserSystemRoles', params, callback);
            };

            /**
             * Deletes any existing role system users and replaces them with the new role system users.
             * @name saveRoleSystemUsers
             * @function
             * 
             * @param {Number} roleID - The unique identifier of the role.
             * @param {EntityCollectionBase} userSystemRoles - The new user system roles.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.saveRoleSystemUsers = function (roleID, userSystemRoles, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: userSystemRoles,
                    SessionID: clientCache.getSessionID(),
                    ID: roleID
                };
                that.sendJSONRequest('SaveRoleSystemUsers', params, callback);
            };

            /**
             * Saves the given SystemFunctionRoles in the Modularis Accelerator Security database.
             * @name saveSystemFunctionRoles
             * @function
             * 
             * @param {Number} roleID - The unique identifier of the role.
             * @param {EntityCollectionBase} systemFunctionRoles - The system function roles to save.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.saveSystemFunctionRoles = function (roleID, systemFunctionRoles, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: systemFunctionRoles,
                    SessionID: clientCache.getSessionID(),
                    ID: roleID
                };
                that.sendJSONRequest('SaveSystemFunctionRoles', params, callback);
            };

            /**
             * Saves the role in the Modularis Accelerator Security database.
             * @name saveSystemRole
             * @function
             * 
             * @param {EntityBase} systemRole - The system role to save.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.saveSystemRole = function (systemRole, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: systemRole,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SaveSystemRole', params, callback);
            };

            /**
             * Saves the user entity to the security storage.
             * @name saveUser
             * @function
             * 
             * @param {EntityBase} user - The user entity to save.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.saveUser = function (user, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    Payload: user,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('SaveUser', params, callback);
            };

            /**
             * Deletes the existing user system roles and replaces them with a new collection of user system roles.
             * @name saveUserSystemRoles
             * @function
             * 
             * @param {Number} userID - User unique identifier.
             * @param {EntityCollectionBase} userSystemRoles - A collection of user system roles.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.securityServiceProxy
             */
            that.saveUserSystemRoles = function (userID, userSystemRoles, callback) {
                var params = {
                    CustomerID: that._getActiveServer().customerID,
                    SessionID: clientCache.getSessionID(),
                    ID: userID,
                    Payload: userSystemRoles
                };
                that.sendJSONRequest('SaveUserSystemRoles', params, callback);
            };

            //Propagate unauthorized event
            that.bind(enums.proxyEventName.httpError, function (event) {
                var code = event.data ? event.data.code : (event.originalEvent.detail.code),
                    url = event.data ? event.data.url : (event.originalEvent.detail.url);
                if (code === enums.httpCodes.unauthorized) {
                    that._trigger(enums.securityEventName.unauthorized, { url: url });
                }
            });

        };

        //Inheritance
        RESTSecurityWebServiceProxy.prototype = new proxyBase.RESTWebServiceProxy();
        RESTSecurityWebServiceProxy.prototype.constructor = RESTSecurityWebServiceProxy;

        return new RESTSecurityWebServiceProxy('RESTSecurityWebService');

    }
);
