define('objectBuilder',
    ['jquery', 'entityHelper', 'configLoader', 'util', 'enums', 'clientCache'],
    function ($, entityHelper, configLoader, util, enums, clientCache) {
        'use strict';

        var createEntityDefaultOptions = {
            entityTypeName: '',
            copyFrom: null,
            assignIdentifier: false,
            callback: null
        };

        var createCollectionDefaultOptions = {
            assemblyName: '',
            entityTypeName: '',
            entityCollectionName: '',
            copyFrom: null,
            callback: null,
            checkDuplicatedKeys: false
        };

        var assignIdentifier = function (entityObject, options) {
            /*eslint-disable no-param-reassign*/

            //Assign a identifier based on the entity key structure.
            if (options.assignIdentifier) {
                var keyStructures = entityObject.getEntityMetadata().getKeyStructure().split('.');

                keyStructures.forEach(function (keyStructure) {

                    var property = entityObject.getEntityMetadata().getPropertyDef(keyStructure);
                    if (keyStructure === 'TenantID') {
                        entityObject[keyStructure] = clientCache.getActiveTenantID();
                    }
                    else
                    {
                        if (property != null && property.type === enums.propertyTypes.typeGuid) {
                            entityObject[keyStructure] = util.newGuid();
                        }
                    }
                });
            }

            /*eslint-enable no-param-reassign*/
        };

        /* Helper methods for entity names manipulation*/
        var getEntityName = function (entityTypeName) {
            var result = entityTypeName.substr(entityTypeName.lastIndexOf('.') + 1);
            return result;
        };

        /*
        Takes a string, tokenize it and normalize each of those tokens, according to the following standard:
        DepartmentManager --> departmentManager, Person --> person, WIP --> wip, IPAddress --> ipaddress
        */
        var normalizeTokens = function (stringWithTokens, separator) {
            var tokens = separator ? stringWithTokens.split(separator) : [stringWithTokens];
            var normalizedTokens = '';

            for (var tokensIndex = 0; tokensIndex < tokens.length; tokensIndex++) {
                var token = tokens[tokensIndex];

                //Normalize token
                var normalizedToken = '';
                var upperCaseNormalized = false;
                for (var index = 0; index < token.length; index++) {
                    var character = token.charAt(index);
                    if (!upperCaseNormalized) {
                        //Following validation checks for upper case and avoids numeric characters
                        if (character === character.toUpperCase() && character !== character.toLowerCase()) {
                            normalizedToken += character.toLowerCase();
                        } else {
                            normalizedToken += character;
                            upperCaseNormalized = true;
                        }
                    } else {
                        normalizedToken += character;
                    }
                }

                normalizedTokens += normalizedToken;
                if (tokensIndex !== (tokens.length - 1)) {
                    normalizedTokens += separator;
                }
            }

            return normalizedTokens;
        };

        var processAttributes = function (entityObject) {
            if (!entityObject || !entityObject._Entity || !entityObject._Entity.Attributes) {
                return;
            }

            var attributes = entityObject._Entity.Attributes;

            if (attributes) {
                for (var idx = 0; idx < attributes.length; idx++) {
                    var attr = attributes[idx];
                    var attrName = Object.keys(attr)[0];
                    var dvProperty = attrName.replace('.', '');
                    if (!entityObject.hasOwnProperty(dvProperty)) {
                        entityObject[dvProperty] = attr[attrName];
                    }
                }
            }
        };

         /**
          * Modularis Object Builder
          * @namespace objectBuilder
          * @memberof modularis
          */
        var objectBuilder = {

            /**
             * Includes the assembly name of the given entity type name in a callback function.
             * @param {string} entityTypeName - The entity type name.
             * @param {Function} callback - The function to be executed when the process is completed. This callback has as parameter the required assembly name.
             * 
             * @memberOf modularis.objectBuilder
             */
            getAssemblyName: function (entityTypeName, callback) {
                var systemName = configLoader.appConfig.systemName;
                if (entityTypeName.startsWith(systemName)) {
                    var format = 'app/{0}/common';
                    var remaining = entityTypeName.replace(systemName + '.', '').replace('Entity.', '');
                    var commonModuleName = String.format(format, configLoader.appConfig.entity.folderName);
                    if (remaining.indexOf('.') >= 0) {
                        var modelName = entityHelper.getModelByEntityTypeName(entityTypeName);
                        if (modelName) {
                            //Remove system name from model name
                            modelName = normalizeTokens(modelName.substr(systemName.length + 1));
                            if (modelName) {
                                commonModuleName = String.format(format, configLoader.appConfig.entity.folderName + '/' + modelName);
                            }
                        }
                    }

                    var requireFunction = util.getRequireJSFunction();
                    requireFunction([commonModuleName], function (commonModule) {
                        util.notify(callback, commonModule.AssemblyName);
                    });
                } else {
                    util.notify(callback, null);

                }
            },

            /**
             * Returns the normalized entity type name.
             * @param {string} entityTypeName - The regular entity type name.
             * 
             * @memberOf modularis.objectBuilder
             * @returns {string}
             */
            getNormalizedEntityTypeName: function (entityTypeName) {

                //1. Remove system name and "Entity." from namespace
                var normalizedEntityTypeName = entityTypeName.replace(configLoader.appConfig.systemName + '.', '').replace('Entity.', '');

                //2. Create normalized entity type name
                normalizedEntityTypeName = normalizeTokens(normalizedEntityTypeName, '.');

                //3. Replace dots with slashes
                normalizedEntityTypeName = normalizedEntityTypeName.replace(/\./g, '/');

                return normalizedEntityTypeName;
            },

            /**
             * Returns the entity module path.
             * @param {string} entityTypeName - The entity type name.
             * 
             * @memberOf modularis.objectBuilder
             * @returns {string}
             */
            getEntityModulePath: function (entityTypeName) {

                if (util.isDynamicEntity(entityTypeName)) {
                    return 'modularis/entity/dynamic/dynamicEntity';
                }

                var normalizedEntityTypeName = this.getNormalizedEntityTypeName(entityTypeName);
                var entityModulePath = configLoader.appConfig.entity.folderName + '/' + normalizedEntityTypeName;
                if (entityTypeName.indexOf(configLoader.appConfig.systemName + '.') === 0) {
                    entityModulePath = 'app/' + entityModulePath;
                }
                return entityModulePath;
            },

            /**
             * Creates an entity collection.
             * @param {object} createCollectionOptions - The Object with the options for the collection creation.
             * @param {object} createCollectionOptions.copyFrom - A plain object with the collection data.
             * @param {string} [createCollectionOptions.collectionName] - The entity collection name.
             * @param {object} [createCollectionOptions.entityTypeName] - The entity type name.
             * @param {Function} [createCollectionOptions.callback] - The function to be executed when the process is completed. This callback has as parameter the new entity collection.
             * 
             * @memberOf modularis.objectBuilder
             * @returns {jquery.Promise}
             */
            createEntityCollection: function (createCollectionOptions) {
                var options = {};
                $.extend(options, createCollectionDefaultOptions, createCollectionOptions);

                var deferred = $.Deferred();
                var promise = deferred.promise();

                var collectionName = options.entityCollectionName;
                var isDynamicEntity = util.isDynamicEntity(collectionName);

                if ((!collectionName || collectionName.length === 0) && options.copyFrom) {
                    collectionName = options.copyFrom._EntityCollection.CollectionName;

                    if (collectionName == null) {
                        isDynamicEntity = util.isDynamicEntity(options.copyFrom.EntityDefID);
                    }
                }

                var entityTypeName = options.entityTypeName;
                if ((!entityTypeName || entityTypeName.length === 0) && collectionName) {
                    entityTypeName = entityHelper.getEntityTypeByCollectionName(collectionName);
                }

                if (!entityTypeName) {
                    deferred.resolve(options.copyFrom);
                    util.notify(options.callback, options.copyFrom);
                    return promise;
                }

                var requireFunction = util.getRequireJSFunction();

                if (isDynamicEntity) {
                    requireFunction = require;
                    collectionName = 'Modularis.Dynamic.Entity.DynamicEntities';
                    entityTypeName = 'Modularis.Dynamic.Entity.DynamicEntity';
                }

                var entityModulePath = this.getEntityModulePath(entityTypeName);

                var loadCollectionModule = function () {
                    //Load module where collection is contained.
                    requireFunction([entityModulePath], function (entityModule) {

                        var entityCollection = new entityModule[collectionName](options.assemblyName, collectionName, entityTypeName);

                        entityCollection.checkDuplicatedKeys = options.checkDuplicatedKeys;

                        if (options.copyFrom) {
                            entityCollection.copyFrom(options.copyFrom, function (updatedCollection, copyCollectionError) {

                                var responseObject = null;
                                var responseError = copyCollectionError;
                                if (util.success(updatedCollection, copyCollectionError)) {
                                    deferred.resolve(updatedCollection);
                                    responseObject = updatedCollection;
                                } else {
                                    deferred.reject(responseError);
                                }

                                util.notify(options.callback, responseObject, responseError);

                            });
                        } else {
                            deferred.resolve(entityCollection);
                            util.notify(options.callback, entityCollection, null);
                        }

                    });
                };

                if (!options.assemblyName || options.assemblyName.length === 0) {
                    //Try to resolve assembly name based on the entityTypeName
                    this.getAssemblyName(entityTypeName, function (assemblyName) {
                        options.assemblyName = assemblyName;
                        loadCollectionModule();
                    });
                } else {
                    loadCollectionModule();
                }

                return promise;
            },

             /**
             * Creates an entity.
             * @param {object} createEntityOptions - The Object with the options for the entity creation.
             * @param {object} createEntityOptions.copyFrom - A plain object with the entity data.
             * @param {object} [createEntityOptions.entityTypeName] - The entity type name.
             * @param {Function} [createEntityOptions.callback] - The function to be executed when the process is completed. This callback has as parameter the new entity.
             * @memberOf modularis.objectBuilder
             * @returns {jquery.Promise}
             */
            createEntityObject: function (createEntityOptions) {

                var options = {};
                $.extend(options, createEntityDefaultOptions, createEntityOptions);

                var entityTypeName = options.entityTypeName;
                var isDynamicEntity = util.isDynamicEntity(entityTypeName);

                if ((!entityTypeName || entityTypeName.length === 0) && options.copyFrom) {
                    entityTypeName = entityHelper.getEntityTypeByEntityName(options.copyFrom._Entity.Name);

                    if (entityTypeName == null) {
                        isDynamicEntity = util.isDynamicEntity(options.copyFrom.EntityDefID);
                    }
                }

                var requireFunction = util.getRequireJSFunction();

                if (isDynamicEntity) {
                    requireFunction = require;
                    entityTypeName = 'Modularis.Dynamic.Entity.DynamicEntity';
                }

                var entityModulePath = this.getEntityModulePath(entityTypeName);

                var deferred = $.Deferred();
                var promise = deferred.promise();

                requireFunction([entityModulePath], function (entityModule) {
                    var entityName = getEntityName(entityTypeName);
                    var entityObject = new entityModule[entityName]();
                    if (options.copyFrom != null) {

                        if (options.processAttributes) {
                           processAttributes(options.copyFrom);
                        }

                        entityObject.copyFrom(options.copyFrom, function (updatedEntityObject, copyEntityError) {
                            var responseObject = null;
                            var responseError = copyEntityError;
                            if (util.success(updatedEntityObject, copyEntityError)) {

                                assignIdentifier(updatedEntityObject, options);
                                deferred.resolve(updatedEntityObject);
                                responseObject = updatedEntityObject;
                            } else {
                                deferred.reject(copyEntityError);
                            }

                            util.notify(options.callback, responseObject, responseError);

                        });
                    } else {
                        if (util.isDynamicEntity(createEntityOptions.entityTypeName)) {
                            entityHelper.initializeDynamicEntity(entityObject, createEntityOptions);
                        }

                        assignIdentifier(entityObject, options);
                        deferred.resolve(entityObject);
                        util.notify(options.callback, entityObject, null);
                    }

                });

                return promise;
            }
        };

        return objectBuilder;
    }
);
