define('modularis/web/widgets/locale',
   ['jquery', 'kendo', 'localization', 'modularisBase/debug', 'modularis/i18n/localizationHelper'],
   function ($, kendo, localization, debug, localizationHelper) {
       'use strict';
       var ui = kendo.ui, Widget = ui.Widget;

       var click = 'click', suffix = '.kendoLocale';

       /**
         * @deprecated Please use the {@link modularis.web.widgets.localeBinderContainer|LocaleBinder} instead.
         * @classdesc Modularis Locale widget. Inherits from {@link https://docs.telerik.com/kendo-ui/framework/localization/overview|kendo.ui.Localization}
         * <p>This class provides a mechanism for translating the message of the elements according to their specific location, with a simple configuration.</p>
         * @constructs modularis.web.widgets.Locale
         * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
         * @property {Object} options - Widget options.
         * @property {?string} [options.key] - The key that must be used to find a translation for the element.
         * @property {string} [options.target = 'text'] - Where the translation must be shown.
         * @property {?string} [options.propertyName] - Name of the property that is going to be translated.
         * @property {?Object} [options.config] - Allows the localization of multiple attributes at the same time <i>(e.g. text and title)</i>.
         **/
       var Locale = Widget.extend({

           init: function (element, options) {
               var that = this;

               //Initialize the widget by calling init on the extended class.
               Widget.fn.init.call(that, element, options);

               localizationHelper._localize(that.element, that.options);

               //Bind click event
               that.element.on(click + suffix, $.proxy(that._click, that));

               if (debug.isEnabled()) {
                   debug.registerEventHandler(click + suffix);
               }
           },

           destroy: function () {
               var that = this;
               //detach event handler
               that.element.off(click + suffix);
               Widget.fn.destroy.call(that);
               that.element = null;

               if (debug.isEnabled()) {
                   debug.unregisterEventHandler(click + suffix);
               }
           },

           options: {
               name: 'Locale', //Name of the plug-in.
               key: null, //[Optional] Key that must be used to find a translation for the element.
               target: 'text', //Where the translation must be shown.
               propertyName: null, //[Optional]Name of the property that is going to be translated.
               config: null //Allows the localization of multiple attributes at the same time (e.g. text and title)
           },

           events: [click],

           _click: function (evt) {
               this.trigger(click, { event: evt });
           }
       });

        /**
         * <p>The <i>click</i> event associate to the element where the locale widget was configured, only triggered after this exact series of events: </p>
         * <p><ul><li>The mouse button is depressed while the pointer is inside the element.</li>
         * <li>The mouse button is released while the pointer is inside the element.</li></ul></p>
         * @event modularis.web.widgets.Locale#click
         * @type {Object}
         * @property {Object} event - Event object.
         * 
         * @example <caption>How to use <b>Locale Widget</b></caption>
         *  <a href="#/theme" data-role="locale" data-key="theme_link_text" data-config="{title:'Modularis_Examples_SimpleProductCatalog_Entity_Customer', text:'theme_link_text'}">Theme</a>
         */

       return {
           widgetClass: Locale
       };

   }
);
