define('RESTLogicalLockWebServiceProxy',
    ['RESTWebServiceProxy', 'clientCache'],
    function (proxyBase, clientCache) {
        'use strict';

        /**
         * Modularis Logical Lock Service Proxy.
         * @namespace logicalLockServiceProxy
         * @memberof modularis.proxy
         */

        var RESTLogicalLockWebServiceProxy = function (serviceName) {

            var that = this;
            proxyBase.RESTWebServiceProxy.call(that, serviceName);

            /**
             * Acquires a pessimistic lock on every entity in the collection and its children.
             * @param {EntityBase} entityCollection - The collection of entities to lock.
             * @param {Number} duration - The duration of the lock in milliseconds. The System default lock duration is used if the value is 0.
             * @param {Boolean} lockChildren - Flag indicating whether or not child entities should be locked.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.acquireLockOnEntityCollection = function (entityCollection, duration, lockChildren, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Duration: duration,
                    LockChildren: lockChildren,
                    Options: options,
                    Payload: entityCollection,
                    SessionID: clientCache.getSessionID()
                };
                this.sendJSONRequest('AcquireLockOnEntityCollection', params, callback);
            };

            /**
             * Acquires a pessimistic lock on an entity and its children.
             * @param {EntityBase} entity - The entity to lock.
             * @param {Number} duration - The duration of the lock in milliseconds.
             * @param {Boolean} lockChildren - Flag indicating whether or not child entities should be locked.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.acquireLockOnEntity = function (entity, duration, lockChildren, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Duration: duration,
                    LockChildren: lockChildren,
                    Options: options,
                    Payload: entity,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('AcquireLockOnEntity', params, callback);
            };

            /**
             * Acquires a lock associated with a logical name.
             * @param {String} lockName - The name associated with the lock.
             * @param {Number} duration - The duration of the lock in milliseconds.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.acquireLock = function (lockName, duration, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Duration: duration,
                    LockName: lockName,
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('AcquireLock', params, callback);
            };

            /**
             * Checks if the lock identified by the lockName exists or not.
             * @param {String} lockName - The name associated with the lock.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.checkForLock = function (lockName, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    LockName: lockName,
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('CheckForLock', params, callback);
            };

            /**
             * Checks if the entity is locked or not
             * @param {EntityBase} entity - The entity of interest
             * @param {Boolean} fullCheck - Indicates whether to check for locks on every entity in the graph or not. If it is set to false, the method will stop checking as soon as it finds the first lock
             * @param {Object} options - A plain object representing any custom actions to be performed during execution
             * @param {requestCallback} callback - The function to be executed when the process is completed
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.checkEntityForLock = function (entity, fullCheck, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    FullCheck: fullCheck,
                    Options: options,
                    Payload: entity,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('CheckEntityForLock', params, callback);
            };

            /**
             * Checks if the entity collection is locked or not.
             * @param {EntityBase} entityCollection - The collection of entities to analyze.
             * @param {Boolean} fullCheck - Indicates whether to check for locks on every entity in the graph or not. If it is set to false, the method will stop checking as soon as it finds the first lock.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.checkEntityCollectionForLock = function (entityCollection, fullCheck, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    FullCheck: fullCheck,
                    Options: options,
                    Payload: entityCollection,
                    SessionID: clientCache.getSessionID()
                };
                this.sendJSONRequest('CheckEntityCollectionForLock', params, callback);
            };

            /**
             * Releases an existing lock.
             * @param {Number} lockID - The lock unique identifier obtained by calling any of the AcquireLock methods.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.releaseLock = function (lockID, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    LockID: lockID,
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('ReleaseLock', params, callback);
            };

            /**
             * Renews the lock
             * @param {Number} lockID - The lock unique identifier obtained by calling any of the AcquireLock methods.
             * @param {Number} duration - The duration of the lock in milliseconds.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.logicalLockServiceProxy
             */
            that.heartbeat = function (lockID, duration, options, callback) {
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Duration: duration,
                    LockID: lockID,
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('Heartbeat', params, callback);
            };

        };

        //Inheritance
        RESTLogicalLockWebServiceProxy.prototype = new proxyBase.RESTWebServiceProxy();
        RESTLogicalLockWebServiceProxy.prototype.constructor = RESTLogicalLockWebServiceProxy;

        return new RESTLogicalLockWebServiceProxy('RESTLogicalLockWebService');

    }
);
