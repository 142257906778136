define('modularis/modularis',
    ['require','libs/promises','modularisBase/extensions','RESTActivityWebServiceProxy','RESTCommandWebServiceProxy','RESTLogicalLockWebServiceProxy','RESTNotificationWebServiceProxy','RESTSecurityWebServiceProxy','configLoader','entityBase','entityCollectionBase','enums','objectBuilder','util','modularisBase/debug','modularis/entity/notification/notificationMessage','modularis/entity/security/userPreference','modularis/entity/dynamic/dynamicEntity','localization','modularis/web/validation/serverSideValidationRule','modularis/viewModels/theme/themeSettings','modularis/viewModels/entity/entityDataContainer','modularis/viewModels/widgets/itemDataContainer','modularis/viewModels/widgets/moduleMenu','clientCache','clientStorage','entityViewModelBase','metadataCache','viewModelBase','modularisGeneral/serviceBase','modularis/base/dynamicEntityHelper','dataSource','modularisData/dataSourceFactory','modularisLayoutManager','modularisTemplateLoader','modularisRoutingManager','modularisStyleSheetManager','modularisGeneral/widgetHelper','modularis/web/widgetLoader','securityHelper','entityHelper','modularis/modularisApp','mediator/mediatorBase','mediator/mainMediator','mediator/colleagueBase','mediator/colleagueViewModelBase'],function (require) {
        /*eslint no-unused-vars: 0*/
        'use strict';
        
        require('libs/promises');
        
        var extensions = require('modularisBase/extensions');

        //References to proxies
        var activityServiceProxy = require('RESTActivityWebServiceProxy'),
           commandServiceProxy = require('RESTCommandWebServiceProxy'),
           logicalLockServiceProxy = require('RESTLogicalLockWebServiceProxy'),
           notificationServiceProxy = require('RESTNotificationWebServiceProxy'),
           securityServiceProxy = require('RESTSecurityWebServiceProxy');

        //References from modularisBase
        var configLoader = require('configLoader'),
           entityBaseFactory = require('entityBase'),
           entityCollectionBaseFactory = require('entityCollectionBase'),
           enums = require('enums'),
           objectBuilder = require('objectBuilder'),
           modularisUtil = require('util'),
           debug = require('modularisBase/debug');


        enums._isNamespace = true;
        enums.authenticationStatus._containsConstants = true;
        enums.editModes._containsConstants = true;
        enums.propertyTypes._isEnum = true;
        enums.notificationMessageTypes._isEnum = true;
        enums.options._containsConstants = true;
        enums.viewModelEventName._containsConstants = true;
        enums.modularisViewEventName._containsConstants = true;
        enums.notificationEventName._containsConstants = true;
        enums.securityEventName._containsConstants = true;
        //Freeze object to avoid modifications. Object.freeze provides a way to specify constant values in JavaScript.
        Object.freeze(enums);

        //References from entity
        var notificationMessageFactory = require('modularis/entity/notification/notificationMessage');
        var userPreferenceFactory = require('modularis/entity/security/userPreference');
        var dynamicEntityFactory = require('modularis/entity/dynamic/dynamicEntity');

        //References from i18n
        var localization = require('localization');

        //References from validator
        var serverSideValidationRuleFactory = require('modularis/web/validation/serverSideValidationRule');


        //Load other view models
        var themeViewModel = require('modularis/viewModels/theme/themeSettings'),
            entityViewModel = require('modularis/viewModels/entity/entityDataContainer'),
            itemSelectorViewModel = require('modularis/viewModels/widgets/itemDataContainer'),
            moduleMenuViewModel = require('modularis/viewModels/widgets/moduleMenu');


        //References from modularisGeneral
        var clientCache = require('clientCache'),
           clientStorage = require('clientStorage'),
           entityViewModelBaseFactory = require('entityViewModelBase'),
           metadataCache = require('metadataCache'),
           viewModelBaseFactory = require('viewModelBase'),
           serviceBaseFactory = require('modularisGeneral/serviceBase'),
           dynamicEntityHelperFactory = require('modularis/base/dynamicEntityHelper');

        //References from web
        var DataSource = require('dataSource'),
           dataSourceFactory = require('modularisData/dataSourceFactory'),
           layoutManager = require('modularisLayoutManager'),
           remoteTemplateLoader = require('modularisTemplateLoader'),
           modularisRoutingManager = require('modularisRoutingManager'),
           styleSheetManager = require('modularisStyleSheetManager'),
           widgetHelper = require('modularisGeneral/widgetHelper'),
           widgetLoader = require('modularis/web/widgetLoader');

        //other framework modules
        var securityHelper = require('securityHelper');
        var entityHelper = require('entityHelper');

        //app
        var modularisApp = require('modularis/modularisApp');

        //Mediator

        var mediatorBase = require('mediator/mediatorBase');
        var mainMediator = require('mediator/mainMediator');
        var colleagueBase = require('mediator/colleagueBase');
        var colleagueViewModelBase = require('mediator/colleagueViewModelBase');

        /**
         * Modularis Root Namespace
        * @namespace modularis
        */
        var modularis = {

            //app
            app: modularisApp,

            //entity helper
            entityHelper: entityHelper,

            //modularisBase
            configLoader: configLoader,
            debug: debug,

            EntityBase: entityBaseFactory.EntityBase,
            EntityCollectionBase: entityCollectionBaseFactory.EntityCollectionBase,
            /**
             * Modularis Platform Enumerations
             * @namespace enums
             * @memberof modularis
             */
            enums: enums,
            objectBuilder: objectBuilder,
            util: modularisUtil,

            /**
             * Modularis entities currently needed for the Web platform.
             * @namespace entity
             * @memberof modularis
             */
            entity: {


                _isNamespace: true,

                /**
                 * Notification namespace.
                 * @namespace notification
                 * @memberOf modularis.entity
                 * */
                notification: {


                    _isNamespace: true,

                    NotificationMessage: notificationMessageFactory.NotificationMessage,
                    NotificationMessages: notificationMessageFactory.NotificationMessages
                },

                security: {


                    _isNamespace: true,

                    UserPreference: userPreferenceFactory.UserPreference,
                    UserPreferences: userPreferenceFactory.UserPreferences

                },

                dynamic: {
                    _isNamespace: true,

                    DynamicEntity: dynamicEntityFactory.DynamicEntity,
                    DynamicEntities: dynamicEntityFactory.DynamicEntities
                }
            },

            /**
              * Modularis Localization Functions (i18n)
             * @namespace localization
             * @memberof modularis
             */
            localization: localization,

            /**
              * Modularis Functions designed to execute in a web browser
             * @namespace web
             * @memberof modularis
             */
            web: {


                _isNamespace: true,

                //modularisGeneral
                clientCache: clientCache,
                clientStorage: clientStorage,
                DataSource: DataSource,
                dataSourceFactory: dataSourceFactory,
                EntityViewModelBase: entityViewModelBaseFactory.EntityViewModelBase,
                metadataCache: metadataCache,
                ModelBase: serviceBaseFactory.ServiceBase,
                ServiceBase: serviceBaseFactory.ServiceBase,
                ViewModelBase: viewModelBaseFactory.ViewModelBase,
                dynamicEntityHelper: dynamicEntityHelperFactory,

                //modularis/web
                layoutManager: layoutManager,
                remoteTemplateLoader: remoteTemplateLoader,

                /** Modularis wrapper for the kendo.Router object. Handles initialization code for routes in the application */
                routingManager: modularisRoutingManager,
                styleSheetManager: styleSheetManager,

                /** Object that provides utility functions for Kendo widgets */
                widgetHelper: widgetHelper,
                widgetLoader: widgetLoader,

                /** Object that provides useful functions for the Accelerator security sub-system */
                securityHelper: securityHelper,

                //validator
                /**
                  * Modularis validation
                  * @namespace validation
                  * @memberof modularis.web
                  */
                validation: {

                    _isNamespace: true,
                    ServerSideValidationRule: serverSideValidationRuleFactory.ServerSideValidationRule
                },

                viewModels: {
                    _isNamespace: true,

                    entity: entityViewModel,
                    theme: themeViewModel,
                    itemSelector: itemSelectorViewModel,
                    moduleMenu: moduleMenuViewModel
                },

                /**
                  * Modularis widgets
                  * @namespace widgets
                  * @memberof modularis.web
                  */
                widgets: {


                    _isNamespace: true,

                    EntityDropDownList: widgetLoader.EntityDropDownList,
                    EntityEditList: widgetLoader.EntityEditList,
                    ItemSelector: widgetLoader.ItemSelector,
                    ModularisPropDropDown: widgetLoader.PropDropDown,
                    ModularisView: widgetLoader.ModularisView
                },

                /**
                 * Modules that allow communication between View Models or classes using the Mediator pattern.
                 * @namespace mediator
                 * @memberOf modularis.web
                 */
                mediator: {
                    _isNamespace: true,
                    MediatorBase: mediatorBase,
                    MainMediator: mainMediator,
                    ColleagueBase: colleagueBase,
                    ColleagueViewModelBase: colleagueViewModelBase
                }
            },

            /**
             * Modularis REST Proxies
             * @namespace proxy
             * @memberof modularis
             */
            proxy: {


                _isNamespace: true,

                activityServiceProxy: activityServiceProxy,
                commandServiceProxy: commandServiceProxy,
                logicalLockServiceProxy: logicalLockServiceProxy,
                notificationServiceProxy: notificationServiceProxy,
                securityServiceProxy: securityServiceProxy
            },

            version: modularisApp.version
        };

        return modularis;
    }
);
