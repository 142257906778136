define('entityHelper',
    ['clientCache', 'util'],
    function (clientCache, util) {
        'use strict';

        var entityTypes = [];

        /**
          * Helper that provides common tasks to work with entities.
          * @namespace entityHelper
          * @memberof modularis
          */
        var entityHelper = {
            /**
             * Initializes the entity helper.
             * 
             * @memberOf modularis.entityHelper
             * @param {Array} typesData - Array with the entity types.
             */
            initialize: function (typesData) {
                entityTypes = typesData.types;
            },

            _getEntityTypeByField: function (field, fieldValue) {
                var result = this._getEntityTypeFieldValueByField(field, fieldValue, 't');
                return result;
            },

            _getEntityTypeFieldValueByField: function (field, fieldValue, returnField) {
                var result = null;
                var entityType = this._getEntityType(field, fieldValue);

                if (entityType) {
                    result = entityType[returnField];
                }

                return result;
            },

            _getEntityType: function (field, fieldValue) {
                var systemName = clientCache.getSystemName(),
                    lowerCaseFieldVale = fieldValue.toLowerCase();

                var result = null;
                for (var modelIndex = 0; (modelIndex < entityTypes.length) && !result; modelIndex++) {
                    var currentModel = entityTypes[modelIndex];
                    if (currentModel.model.startsWith(systemName)) {
                        for (var typeIndex = 0; (typeIndex < currentModel.modelTypes.length) && !result; typeIndex++) {
                            var currentEntityType = currentModel.modelTypes[typeIndex];
                            if (currentEntityType[field].toLowerCase() === lowerCaseFieldVale) {
                                result = currentEntityType;
                            }
                        }
                    }
                }
                return result;
            },

            _getModelNameByField: function (field, fieldValue) {
                var systemName = clientCache.getSystemName(),
                    lowerCaseFieldVale = fieldValue.toLowerCase();

                var result = null;
                for (var modelIndex = 0; (modelIndex < entityTypes.length) && !result; modelIndex++) {
                    var currentModel = entityTypes[modelIndex];
                    if (currentModel.model.startsWith(systemName)) {
                        for (var typeIndex = 0; (typeIndex < currentModel.modelTypes.length) && !result; typeIndex++) {
                            var currentEntityType = currentModel.modelTypes[typeIndex];
                            if (currentEntityType[field].toLowerCase() === lowerCaseFieldVale) {
                                result = currentModel.model;
                            }
                        }
                    }
                }
                return result;
            },

            /**
             * Returns the entity type by collection name.
             * 
             * @memberOf modularis.entityHelper
             * @param {string} collectionName - The collection name.
             * @returns {string} The entity type.
             */
            getEntityTypeByCollectionName: function (collectionName) {
                return this._getEntityTypeByField('c', collectionName);
            },

            /**
             * Returns the entity type by entity name.
             * 
             * @memberOf modularis.entityHelper
             * @param {string} entityName - The entity name.
             * @returns {string} The entity type.
             */
            getEntityTypeByEntityName: function (entityName) {
                return this._getEntityTypeByField('e', entityName);
            },

            /**
             * Returns the entity collection name by entity name.
             * 
             * @memberOf modularis.entityHelper
             * @param {string} entityName - The entity name.
             * @returns {string} The entity collection name.
             */
            getEntityCollectionNameByEntityName: function (entityName) {
                return this._getEntityTypeFieldValueByField('e', entityName, 'c');
            },

            /**
             * Returns the entity collection name by entity collection type name.
             * 
             * @memberOf modularis.entityHelper
             * @param {string} entityCollectionTypeName - The entity collection type name.
             * @returns {string} The entity collection name.
             */
            getEntityCollectionNameByTypeName: function (entityCollectionTypeName) {

                var entityType = this._getEntityType('t', entityCollectionTypeName);
                var result = null;

                if (entityType) {
                    result = entityType.c;
                }

                return result;
            },

             /**
             * Returns the model name by entity type name.
             * 
             * @memberOf modularis.entityHelper
             * @param {string} entityTypeName - The entity  type name.
             * @returns {string} The model name.
             */
            getModelByEntityTypeName: function (entityTypeName) {
                return this._getModelNameByField('t', entityTypeName);
            },

             /**
             * Returns the model name by entity type name.
             * 
             * @memberOf modularis.entityHelper
             * @param {modularis.EntityBase} dynamicEntity - Dynamic Entity to initialize.
             * @param {object} options - Option object of the method.
             * @param {string} options.entityTypeName - Entity type name of the dynamic to be initialized.
             */
            initializeDynamicEntity: function (dynamicEntity, options) {
                var entityDef = options.entityDef;

                if (util.isDynamicEntity(options.entityTypeName) && util.isDefined(entityDef) && util.isDefined(entityDef.PropertyDefs)) {
                    var propertyDefs = entityDef.PropertyDefs.Items;

                    dynamicEntity.getEntityMetadata().setEntityStructure(propertyDefs);

                    for (var index = 0; index < propertyDefs.length; index++) {
                        var propertyDef = propertyDefs[index];
                        if (!(propertyDef.PropertyName in dynamicEntity)) {
                            dynamicEntity[propertyDef.PropertyName] = null;
                        }
                    }

                    dynamicEntity.DynamicEntityID = !dynamicEntity.DynamicEntityID ? util.newGuid() : dynamicEntity.DynamicEntityID;
                    dynamicEntity.PKID = dynamicEntity.DynamicEntityID;
                    dynamicEntity.EntityDefID = entityDef.EntityDefID;
                    dynamicEntity.EntityName = entityDef.EntityName;
                    dynamicEntity._Entity = dynamicEntity._serializationMetadata;
                }
            }
        };

        return entityHelper;
    }
);
