define('modularis/web/widgets/itemSelector',
    ['jquery', 'kendo', 'modularis/web/widgets/modularisView', 'webUtil', 'util', 'enums'],
    function ($, kendo, modularisViewContainer, webUtil, util, enums) {
        'use strict';
        var ModularisView = modularisViewContainer.widgetClass;

        var selectEvent = 'select',
            unselectEvent = 'unselect',
            dataBoundEvent = 'dataBound',
            changeEvent = 'change';

        /**
         * @typedef {Object} modularis.web.widgets.ItemSelector~Binders Binders supported by the widget.
         * @property {modularis.EntityCollectionBase|Array} selectedItems - Collection of selected items to be displayed in the selected items control.
         * @property {modularis.EntityCollectionBase|Array} unselectedItems - Collection of selected items to be displayed in the available items control.
         * @property {Function} beforeSelectedItems - Function to be called when an item is selected and is moved from the available items control to the selected items control.
         * @property {Function} beforeSelectedItemsAsync - Function to be called when an item is selected and is moved from the available items control to the selected items control. 
         *          <br>This function is executed asynchronously providing a callback that must be called when the execution is completed.
         * @property {Function} beforeUnselectedItems - Function to be called when an item is unselected and is moved from the selected items control to the available items control.
         * @property {Function} beforeUnselectedItemsAsync - Function to be called when an item is unselected and is moved from the selected items control to the available items control.
         *          <br>This function is executed asynchronously providing a callback that must be called when the execution is completed.
         */

        /**
         * @classdesc This widget is composed of 2 container widgets that could be grids or entityEditlists, depending on the <i>style</i> configuration option. 
         * The first container widget shows all the available items that can be selected and moved to the second container widget. 
         * The second container widget shows all the items that have been selected. 
         * <br> This widget has some configuration options that are specific for one of the container widgets, for those cases,
         *  we will call the first widget: Available items control, and we will call the second widget: Selected items control.
         *  
         * @constructs modularis.web.widgets.ItemSelector
         * @param {Object} options - Widget configuration options. Check the options property to see the available settings.
         * 
         * @property {Object} options - Widget Options.
             * @property {string} [options.style=grid] - {@link modularis.enums.itemSelectorStyles| Type of item selector} to render.
             *         <br>Grid style means that Kendo UI grids will be used to render the containers.
             *         <br>EntityEditList style will use Modularis' EntityEditLists to contain the data; this style is the ideal one when the objects to manipulate are entities.
             *         <br>The Toogle style is not supported.
             * @property {boolean} [options.changeEditMode=true] - If it is set to true, the edit modes of the selected entities are changed to delete when they are unselected.
             *         <br>Set this property to false if you want to manage the EditMode of the Entities manually
             * @property {number} options.height - The widget height.
             * 
             * @property {Array} options.columns - Columns configuration for the available items control. This parameter can be used if the style option is Grid or EntityEditList. 
             *                             <br>For more information about this parameter check out the {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/columns#columns| kendo grid column configuration}.
             * @property {Array} options.columnsSelected - Columns configuration for the selected items control. This parameter can be used if the style option is Grid or EntityEditList..
             *                             <br>For more information about this parameter check out the {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/columns#columns| kendo grid column configuration}.
             * @property {string} [options.selectedItemsTitle='Selected items'] - A text to be placed as title over the selected items control. 
             * @property {string} [options.unselectedItemsTitle='Available items'] - A text to be placed as title over the available items control. 
             * 
             * 
             * @property {string|boolean|object} [options.editable] - Configuration for the editable option of the available items control. This options is only available if the option style is 'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/editable| this link} to get more information.
             * @property {string|boolean|object} [options.selectedItemsEditable] - Configuration for the editable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/editable| this link} to get more information.
             * @property {boolean|Object} [options.filterable] - Configuration for the filterable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/filterable| this link} to get more information.
             * @property {boolean|Object} [options.selectedItemsFilterable] - Configuration for the filterable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/filterable| this link} to get more information.
             * @property {boolean|Objec} [options.groupable] - Configuration for the groupable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/groupable| this link} to get more information.
             * @property {boolean|Objec} [options.selectedItemsGroupable] - Configuration for the groupable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/groupable | this link} to get more information.
             * @property {boolean|Objec} [options.pageable] - Configuration for the pageable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/pageable| this link} to get more information.
             * @property {boolean|Objec} [options.selectedItemsPageable] - Configuration for the pageable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/pageable| this link} to get more information.
             * @property {boolean|Objec} [options.scrollable] - Configuration for the scrollable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/scrollable| this link} to get more information.
             * @property {boolean|Objec} [options.selectedItemsScrollable] - Configuration for the scrollable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/scrollable| this link} to get more information.
             * @property {boolean|Objec} [options.sortable] - Configuration for the sortable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/sortable| this link} to get more information.
             * @property {boolean|Objec} [options.selectedItemsSortable] - Configuration for the sortable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/sortable| this link} to get more information.
             * @property {boolean} [options.resizable] - Configuration for the sortable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/resizable| this link} to get more information.
             * @property {boolean} [options.selectedItemsResizable] - Configuration for the sortable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/resizable| this link} to get more information.
             * @property {boolean} [options.navigatable] - Configuration for the navigatable option of the available items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/navigatable| this link} to get more information.
             * @property {boolean} [options.selectEntityOnNavigation] - Configuration for the navigatable option of the selected items control. This options is only available if the option style is  'grid' or 'entityEditList'. <br>Check out {@link https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/navigatable| this link} to get more information.
             * 
             * @property {string} [options.entityTypeName] - Configuration for the entityTypeName option of the available items control. This option is only available if the option style is 'entityEditList'. <br>Check out the {@link modularis.web.widgets.EntityEditList| EntityEditList} documentation to get more information.
             * @property {string} [options.selectedEntitiesTypeName] - Configuration for the entityTypeName option of the selected items control. This option is only available if the option style is 'entityEditList'. <br>Check out the {@link modularis.web.widgets.EntityEditList| EntityEditList} documentation to get more information.
         */
        var ItemSelector = ModularisView.extend({
            init: function (element, options) {
                var that = this;
                ModularisView.fn.init.call(that, element, options);
                that.bind(enums.modularisViewEventName.afterViewModelAssigned, that._handleViewModelAssigned);

                var viewModelOptions = util.copy(that.options);
                viewModelOptions.additionalAttributes = webUtil.getAdditionalAttributes(that.element, that.options);
                viewModelOptions.selectedItems = that.options.selectedItems;
                viewModelOptions.unselectedItems = that.options.unselectedItems;
                that.setViewModelValue('options', viewModelOptions);
            },
            _handleViewModelAssigned: function () {
                var that = this;
                var viewModel = this.getViewModel();
                viewModel.bind(selectEvent, function (event) {
                    that.trigger(selectEvent, { allItems: event.allItems, items: event.items });
                });
                viewModel.bind(unselectEvent, function (event) {
                    that.trigger(unselectEvent, { allItems: event.allItems, items: event.items });
                });
                viewModel.bind(dataBoundEvent, function (event) {
                    that.trigger(dataBoundEvent, { sourceContainer: event.sourceContainer });
                });
                viewModel.bind(changeEvent, function (event) {
                    that.trigger(changeEvent, { sourceContainer: event.sourceContainer });
                });
            },
            options: {
                name: 'ItemSelector',
                viewModel: 'modularis/viewModels/widgets/itemDataContainer',
                style: enums.itemSelectorStyles.grid,
                height: 200,
                columns: null,
                columnsSelected: null,
                placeholder: 'Select an Item', // Used by the toggle mode which is not implemented yet.
                textField: null, // Used by the toggle mode which is not implemented yet.
                valueField: null, // Used by the toggle mode which is not implemented yet.
                selectedItemsTitle: 'Selected items',
                unselectedItemsTitle: 'Available items',
                changeEditMode: true,

                //Options available for entityEditList and grid styles
                editable: null,
                selectedItemsEditable: null,
                filterable: null,
                selectedItemsFilterable: null,
                groupable: null,
                selectedItemsGroupable: null,
                pageable: null,
                selectedItemsPageable: null,
                scrollable: null,
                selectedItemsScrollable: null,
                sortable: null,
                selectedItemsSortable: null,
                resizable: null,
                selectedItemsResizable: null,
                navigatable: null,
                selectEntityOnNavigation: null,

                //Options specific to the entityEditList style
                entityTypeName: null,
                selectedEntitiesTypeName: null,

                //Options specific to the sources initialization.
                // Those options did not were documented becouse they can be manipulated using the options.
                selectedItems: null,
                unselectedItems: null

            },

            events: [selectEvent, unselectEvent, dataBoundEvent, changeEvent],

            /**
            * Adjusts the layout of the widget depending on the value set in the style option. 
            * 
            * @instance
            * @memberOf modularis.web.widgets.ItemSelector
            */
            adjustLayout: function () {
                var viewModel = this.getViewModel();
                viewModel.adjustLayout();
            },

            // This method was not documented because it should be protected. 
            // It is used by the binder to update the selectedItems property in the viewModel.
            setSelectedItems: function (items) {
                this.setViewModelValue('selectedItems', items);
            },

            // This method was not documented because it should be protected. 
            // It is used by the binder to update the unselectedItems property in the viewModel.
            setUnselectedItems: function (items) {
                this.setViewModelValue('unselectedItems', items);
            },

            /**
             * Returns the widget that contains the selected items. 
             * The return type depends on the style defined for the widget.
             * @returns {modularis.web.widgets.EntityEditList|kendo.ui.Grid} 
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            getSelectedItemsWidget: function () {
                var viewModel = this.getViewModel();
                return viewModel.getSelectedItemsWidget();
            },

            /**
             * Returns the widget that contains the unselected items. 
             * The return type depends on the style defined for the widget.
             * @returns {modularis.web.widgets.EntityEditList|kendo.ui.Grid} 
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            getUnselectedItemsWidget: function () {
                var viewModel = this.getViewModel();
                return viewModel.getUnselectedItemsWidget();
            },

            /**
             * Sets the height of the widget and will adjust the layout accordingly.
             * @param {number} newHeight - New height of the widget.
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            setHeight: function (newHeight) {
                var viewModel = this.getViewModel();
                viewModel.setHeight(newHeight);
            },

            /**
             * Enables or disables the ItemSelector. If set to false the ItemSelector will be disabled and will not allow user input. The ItemSelector is enabled by default and allows user input.
             * @param {boolean} toggle - Set to false to disable the widget or set it to true to enable it.
             * 
             * @instance
             * @memberOf modularis.web.widgets.ItemSelector
             */
            enable: function (toggle) {
                this.setViewModelValue('enable', toggle);
            },

            /**
            * Adjusts the position of the buttons container on the layout of the widget depending on the value set in the style option. 
            * 
            * @instance
            * @memberOf modularis.web.widgets.ItemSelector
            */
            setButtonContainerPosition: function () {
                var viewModel = this.getViewModel();
                viewModel.setButtonContainerPosition();
            },

            _assignBeforeSelectedItemsHandler: function (method) {
                this.setViewModelValue('beforeSelectedItemsHandler', method);
            },

            _assignBeforeUnselectedItemsHandler: function (method) {
                this.setViewModelValue('beforeUnselectedItemsHandler', method);
            },

            _assignBeforeSelectedItemsAsyncHandler: function (method) {
                this.setViewModelValue('beforeSelectedItemsAsyncHandler', method);
            },

            _assignBeforeUnselectedItemsAsyncHandler: function (method) {
                this.setViewModelValue('beforeUnselectedItemsAsyncHandler', method);
            },

            _getViewPath: function () {
                var that = this;
                if (that._viewPath == null) {
                    that._viewPath = that.options.view || ('shared/widgets/itemSelector/_' + that.options.style + 'Style');
                }
                return that._viewPath;
            }

        });
        return {
            widgetClass: ItemSelector
        };

    });
