define('RESTCommandWebServiceProxy',
    ['jquery', 'RESTWebServiceProxy', 'clientCache', 'util', 'enums'],
    function ($, proxyBase, clientCache, util, enums) {
        'use strict';

        /**
         * Server command options available to invoke executeServerCommand.
         * @typedef {Object} modularis.proxy.commandServiceProxy~serverCommandSettings
         * @property {string} processor - Command processor.
         * @property {string} name - Command name.
         * @property {Object} [parameters] - Key/value pair object that contains the parameters to pass to the command.
         * @property {Object} [headers] - Key/value pair object that contains the headers to pass to the command. By default, SessionID and CustomerID will be added as headers. Options should be passed to these headers object.
         * @property {string} [httpMethod=POST] - HTTP Method (GET or POST).
         * @property {Object|string} [payload] - Object sent in the request body. Only applies to POST.
         * 
         */

        var defaultCommandSeparator = '|';
        var serverCommandURIFormat = 'ExecuteServerCommand/{0}/{1}{2}{3}';
        var post = 'POST';
        
        var defaultServerCommandSettings = {
            processor: null,
            name: null,
            parameters: {},
            headers: {},
            httpMethod: post,
            payload: {}
        };

        var parseCommandParameters = function (commandParameters, separator) {
            var result = '';
            for (var parameterName in commandParameters) {
                if (commandParameters.hasOwnProperty(parameterName)) {
                    result += parameterName + '=' + commandParameters[parameterName] + separator;
                }
            }
            result = result.substr(0, result.length - 1);
            return result;
        };

        var parseCommand = function (commandDefinition, options) {
            var result = commandDefinition;
            if (typeof (result) === 'object') {

                var separator = defaultCommandSeparator;
                if (options && (enums.options.commandSeparator in options)) {
                    separator = options[enums.options.commandSeparator];
                }

                var commandProcessor = commandDefinition.processor;
                var commandName = commandDefinition.name;

                result = commandProcessor + separator + commandName;
                result += separator + parseCommandParameters(commandDefinition.parameters, separator);
            }
            return result;
        };

        /**
         * Modularis Command Service Proxy
         * @namespace commandServiceProxy
         * @memberof modularis.proxy
         */

        var RESTCommandWebServiceProxy = function (serviceName) {

            var that = this;
            proxyBase.RESTWebServiceProxy.call(that, serviceName);

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeCommand
             * @function
             *              
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @param {String} [responseDataType=json] - The data type in which the response is expected.<br>
             * <ul>
             *  <li>
             *      <b>JSON: 'json':</b> <br>
             *       The server command must return an string in JSON format: '{ "Property": "value" }', otherwise the response parsing process will fail.<br>
             *       The parsed object will be provided to the callback in the response parameter, containing the properties and values defined in the JSON string.<br>
             *  
             * </li>
             *  <li>    
             *      <b>Text string: 'text': </b><br>
             *      The server command can return any string.<br>
             *      The result string will be provided to the callback in the response parameter.<br>
             *  </li>
             * </ul>
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.executeCommand = function (command, options, callback, responseDataType) {
                var parsedCommand = parseCommand(command);
                var responseType = responseDataType == null ? 'json' : responseDataType;
                var params = {
                    Command: parsedCommand,
                    CustomerID: that._getActiveServer(options).customerID,
                    Options: that._updateOptions(options, true),
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('ExecuteCommand', params, callback, responseType);
            };

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeCommandOnBinaryData
             * @function
             * 
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in
             * @param {byte[]} binaryData -The binary data to be sent to the command.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @param {String} [responseDataType=json] - The data type in which the response is expected.<br>
             * <ul>
             *  <li>
             *      <b>JSON: 'json':</b> <br>
             *       The server command must return an string in JSON format: '{ "Property": "value" }', otherwise the response parsing process will fail.<br>
             *       The parsed object will be provided to the callback in the response parameter, containing the properties and values defined in the JSON string.<br>
             *  
             * </li>
             *  <li>    
             *      <b>Text string: 'text': </b><br>
             *      The server command can return any string.<br>
             *      The result string will be provided to the callback in the response parameter.<br>
             *  </li>
             * </ul>
             * @memberof modularis.proxy.commandServiceProxy
              */
            that.executeCommandOnBinaryData = function (command, binaryData, options, callback, responseDataType) {
                var parsedCommand = parseCommand(command);
                var responseType = responseDataType == null ? 'json' : responseDataType;
                var params = {
                    Command: parsedCommand,
                    CustomerID: that._getActiveServer(options).customerID,
                    Data: binaryData,
                    Options: that._updateOptions(options),
                    SessionID: clientCache.getSessionID()
                };

                var additionalOptions = {
                    processData: false,
                    contentType: false,
                    convertToFormData: true
                };

                that.sendJSONRequest('ExecuteCommandOnBinaryData', params, callback, responseType, 'POST', additionalOptions);
            };

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeCommandOnCollection
             * @function
             * 
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in.
             * @param {modularis.EntityCollectionBase} entityCollection - An entity collection which is relevant to the execution of the command.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.commandServiceProxy
              */
            that.executeCommandOnCollection = function (command, entityCollection, options, callback) {
                var parsedCommand = parseCommand(command);
                var params = {
                    Command: parsedCommand,
                    CustomerID: that._getActiveServer(options).customerID,
                    Options: that._updateOptions(options, true),
                    Payload: entityCollection,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('ExecuteCommandOnCollection', params, callback);
            };

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeCommandOnEntity
             * @function
             * 
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in.
             * @param {modularis.EntityBase} entity - An entity which is relevant to the execution of the command.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.executeCommandOnEntity = function (command, entity, options, callback) {
                var parsedCommand = parseCommand(command);
                var params = {
                    Command: parsedCommand,
                    CustomerID: that._getActiveServer(options).customerID,
                    Options: that._updateOptions(options, true),
                    Payload: entity,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('ExecuteCommandOnEntity', params, callback);
            };

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeCommandOnEntityType
             * @function
             * 
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in.
             * @param {string} entityTypeName - The type name of te entity of interest.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.executeCommandOnEntityType = function (command, entityTypeName, options, callback) {
                var parsedCommand = parseCommand(command);
                var params = {
                    Command: parsedCommand,
                    CustomerID: that._getActiveServer(options).customerID,
                    Options: that._updateOptions(options),
                    EntityTypeName: entityTypeName,
                    SessionID: clientCache.getSessionID()
                };
                that.sendJSONRequest('ExecuteCommandOnEntityType', params, callback);
            };

            /**
             * Forwards an execute command request to the Command Service.
             * @name executeSimpleCommand
             * @function
             *
             * @param {String} command - The custom command name which identifies the action to be performed by the custom command service plug-in.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @param {String} [responseDataType=json] - The data type in which the response is expected.<br>
             * <ul>
             *  <li>
             *      <b>JSON: 'json':</b> <br>
             *       The server command must return an string in JSON format: '{ "Property": "value" }', otherwise the response parsing process will fail.<br>
             *       The parsed object will be provided to the callback in the response parameter, containing the properties and values defined in the JSON string.<br>
             *  
             * </li>
             *  <li>    
             *      <b>Text string: 'text': </b><br>
             *      The server command can return any string.<br>
             *      The result string will be provided to the callback in the response parameter.<br>
             *  </li>
             * </ul>
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.executeSimpleCommand = function (command, callback, responseDataType) {
                var responseType = responseDataType == null ? 'text' : responseDataType;
                var parsedCommand = parseCommand(command);
                that.sendPlainTextRequest('ExecuteSimpleCommand', parsedCommand, callback, responseType);
            };

            /**
             * Executes a server command.
             * @name executeServerCommand
             * @function
             * @param {modularis.proxy.commandServiceProxy~serverCommandSettings} serverCommandSettings - Server command settings.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * 
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.executeServerCommand = function (serverCommandSettings, callback) {
                var that = this;
                var settings = {};
                $.extend(true, settings, defaultServerCommandSettings, serverCommandSettings);

                if (!settings.processor || !settings.name) {
                    throw new Error('A command processor and a command name must be provided.');
                }

                //Create URI
                var httpMethod = settings.httpMethod.toUpperCase();
                var paramSeparator = httpMethod === post ? '|' : '&';
                var queryStringSeparator = httpMethod === post ? '|' : '?';
                var parsedParameters = parseCommandParameters(settings.parameters, paramSeparator);
                var uri = String.format(serverCommandURIFormat, settings.processor, settings.name, queryStringSeparator, parsedParameters);

                var requestOptions = { headers: {} };
                if (settings.headers && !util.isEmptyObject(settings.headers)) {
                    requestOptions.headers = util.copy(settings.headers);
                }

                if (!('SessionID' in requestOptions.headers)) {
                    var session = clientCache.getSessionID();
                    if (session && session !== 'null') {
                        requestOptions.headers.SessionID = clientCache.getSessionID();
                    }
                }
                if (!('CustomerID' in requestOptions.headers)) { requestOptions.headers.CustomerID = clientCache.getActiveCustomerID(); }

                if ('contentType' in requestOptions.headers) {
                    requestOptions.headers['Content-Type'] = requestOptions.headers.contentType;
                    delete requestOptions.headers.contentType;
                }

                var payload = settings.payload;
                var options = {};
                if (settings.options) {
                    options = settings.options;
                    delete settings.options;
                }

                var convertToJSEntity = false;

                if (payload && util.isFunction(payload.getInstanceWithSerializationMetadata)) {
                    convertToJSEntity = true;
                }

                requestOptions.headers.Options = that._updateOptions(options, convertToJSEntity);

                if (util.isDefined(options.processData)) {
                    requestOptions.processData = options.processData;

                } else {
                    requestOptions.processData = true;
                }

                requestOptions.isPayload = true;

                that.sendJSONRequest(uri, payload, callback, null, settings.httpMethod, requestOptions);
            };

            /**
             * Uploads files to the server. This function sends the file to the UploadFiles server command.
             * @name uploadFiles
             * @function
             * @param {File|FileList} filesData - A javascript {@link https://developer.mozilla.org/en-US/docs/Web/API/Filet|File} or {@link https://developer.mozilla.org/en-US/docs/Web/API/FileList|FileList} object with the information of the files that wil be uploaded.
             * @param {object} options - A plain object representing any custom actions to be performed during execution.
             * @param {Function} callback - The function to be executed when the process is completed.
             * @param {String} [responseDataType=json] - The data type in which the response is expected.<br>
             * <ul>
             *  <li>
             *      <b>JSON: 'json':</b> <br>
             *       The server command must return an string in JSON format: '{ "Property": "value" }', otherwise the response parsing process will fail.<br>
             *       The parsed object will be provided to the callback in the response parameter, containing the properties and values defined in the JSON string.<br>
             *  
             * </li>
             *  <li>    
             *      <b>Text string: 'text': </b><br>
             *      The server command can return any string.<br>
             *      The result string will be provided to the callback in the response parameter.<br>
             *  </li>
             * </ul>
             * @memberof modularis.proxy.commandServiceProxy
             */
            that.uploadFiles = function (filesData, options, callback, responseDataType) {

                var responseType = responseDataType == null ? 'json' : responseDataType;
                var params = {
                    CustomerID: that._getActiveServer(options).customerID,
                    Data: filesData,
                    Options: options,
                    SessionID: clientCache.getSessionID()
                };

                var additionalOptions = {
                    processData: false,
                    contentType: false,
                    convertToFormData: true
                };

                that.sendJSONRequest('UploadFiles', params, callback, responseType, 'POST', additionalOptions);

            };

        };

        //Inheritance
        RESTCommandWebServiceProxy.prototype = new proxyBase.RESTWebServiceProxy();
        RESTCommandWebServiceProxy.prototype.constructor = RESTCommandWebServiceProxy;

        return new RESTCommandWebServiceProxy('RESTCommandWebService');

    }
);
