define('modularis/i18n/localizationHelper',
    ['jquery', 'kendo', 'localization', 'modularis/i18n/translatorFactory', 'logger', 'util'],
    function ($, kendo, localization, TranslatorFactory, logger, util) {
        'use strict';

        var removeSingleQuotes = function (value) {
            return value.replace(/[']/g, '');
        };

        var getPropertyName = function (element, options) {
            var result = null;
            if (options.propertyName != null) {
                result = options.propertyName;
            } else {
                //In the case of properties, we can make the translation work using a <label for=""/> tag.
                if (element.is('label')) {
                    result = element.attr('for');
                } else if (element.data('for')) {
                    result = element.data('for');
                } else {
                    var labelContainer = element.closest('label');
                    if (labelContainer) {
                        result = labelContainer.attr('for');
                    }
                }
            }

            return result;
        };

        var validateElementTarget = function (element, target) {

            var targetResult = target;

            if ((element.is('input[type=button]') || element.is('input[type=submit]')) && !targetResult) {
                //For buttons, the 'value' attribute is where the text is displayed.
                targetResult = 'value';
            }

            return targetResult;
        };

        var getEntityDefID = function (element) {
            //Get a reference to the view model, if any
            var viewModel = null;
            // get ancestor div container
            var ancestor = element.closest('div[class!=mod-form-group]').parent();

            //get inputs that has a data-bind attribute.
            var bindingTarget = ancestor.find('input[data-bind]').prop('kendoBindingTarget');
            if (!bindingTarget) {
                bindingTarget = ancestor.closest('div').find('input[data-bind]').prop('kendoBindingTarget');
            }

            if (bindingTarget) {
                // get view model object.
                viewModel = bindingTarget.source;
            }

            var entityDefID = null;
            //Try to access other input elements to find the binding target
            if (viewModel) {
                entityDefID = viewModel.entityDefID;
            }

            return entityDefID;

        };

        var localizationHelper = {
            _pendingElements: [],
            translatorFactory: new TranslatorFactory(),
            localize: function (element, keyMapping) {

                var elementToLocalize = element;

                if (!(elementToLocalize instanceof $)) {
                    elementToLocalize = $(element);
                }

                var elementWidget = kendo.widgetInstance(elementToLocalize);

                for (var property in keyMapping) {
                    if (keyMapping.hasOwnProperty(property)) {

                        var translator = this.translatorFactory.createTranslator(property);

                        var key = removeSingleQuotes(keyMapping[property]);

                        var options = {
                            element: elementToLocalize,
                            widget: elementWidget,
                            mapping: keyMapping,
                            attribute: property,
                            key: key
                        };

                        var translationResult = translator.execute(options, localization);

                        if (!translationResult) {

                            var entityDefID = getEntityDefID(elementToLocalize);
                            if (!$.isEmptyObject(entityDefID)) {
                                var propertyKeyName = localization.getPropertyNameKey(entityDefID, key);

                                options.key = propertyKeyName;
                                translationResult = translator.execute(options, localization);
                            }

                            if (!translationResult) {

                                //if code execution reaches this point, it means that a mechanism to translate the element wasn't found
                                var outherHTML = element;

                                if (element.outerHTML) {
                                    outherHTML = element.outerHTML;
                                } else if (typeof element.prop == 'function') {
                                    outherHTML = element.prop('outerHTML');
                                }

                                logger.info('It wasn\'t possible to translate the element ' + outherHTML);
                            }
                        }
                    }
                }
            },
            _localize: function (element, options, retry) {
                var that = this;
                var keyMapping = options.config;

                var isPropertyLocalization = false;

                if (!keyMapping) {

                    var target = options.target;

                    keyMapping = {};

                    if (target) {

                        var targetResult = validateElementTarget(element, target);

                        if (options.key) {
                            keyMapping[targetResult] = options.key;
                        } else {
                            //Get ViewModel of the view
                            var entityDefID = getEntityDefID(element);

                            if (entityDefID) {

                                //In this case, we need to translate a PropertyDef name
                                var propertyName = getPropertyName(element, options);

                                if (propertyName) {

                                    //Get full property name path
                                    var propertyKey = localization.getPropertyNameKey(entityDefID, propertyName);
                                    keyMapping[targetResult] = propertyKey;
                                    isPropertyLocalization = true;
                                }
                            } else {
                                if (!retry) {
                                    //Add element to array so that it can be localized later.
                                    that._pendingElements.push({ element: element, options: options });
                                }
                            }
                        }
                    }
                }

                that.localize(element, keyMapping);

                if (isPropertyLocalization && !retry && that._pendingElements.length > 0) {
                    for (var index = 0; index < that._pendingElements.length; index++) {
                        that._localize(that._pendingElements[index].element, that._pendingElements[index].options, true);
                    }
                    util.clearArray(that._pendingElements);
                }
            }
        };

        return localizationHelper;
    });
