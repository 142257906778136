define('modularis/web/validation/serverSideValidationRule',
    ['jquery', 'util'],
    function ($, util) {
        'use strict';

        var getInputError = function (input, entityErrorsCollection) {

            //Check if there are errors in the collection.
            if (entityErrorsCollection === null || util.isEmptyObject(entityErrorsCollection))
            {
                return null;
            }

            for (var index = 0; index < entityErrorsCollection.length; index++)
            {
                var entityError = entityErrorsCollection[index];
                var selector = '[name="' + entityError.PropName + '"]';
                if (input.is(selector))
                {
                    return entityError;
                }
            }

            return null;

        };

         /**
          * @classdesc Class to handle Entity errors.
          * @constructs  modularis.web.validation.ServerSideValidationRule
          * 
          * @param {array} entityErrorsCollection - Entity errors collection. <br> Check out this {@link modularis.EntityMetadata#getErrors|link} for more information about hot to  {@link modularis.EntityMetadata#getErrors | get the entity errors}.
          * 
          */
        var ServerSideValidationRule = function (entityErrorsCollection)
        {

            var that = this;
            var hiddenPropertiesErrors = [];

            /** 
             * Set of custom validation rules. This class adds a custom rule named serverSideValidationRule.
             * @memberof modularis.web.validation.ServerSideValidationRule
             * @name rules
             */
            that.rules = {

                serverSideValidationRule: function (input) {
                    var isValid = true;

                    var inputError = getInputError(input, entityErrorsCollection);

                    if (inputError !== null)
                    {
                        //Validation rules should only consider visible fields
                        if (!input.is('[type=hidden]')) {
                            isValid = false;
                        } else {
                            //Hidden fields must treated in a different way
                            hiddenPropertiesErrors.push(inputError);
                        }
                    }
                    
                    return isValid;
                }

            };

            /**
             * Configuration for the messages that will be displayed by this validator.
             * @memberof modularis.web.validation.ServerSideValidationRule
             * @name messages
             */
            that.messages = {

                serverSideValidationRule: function (input) {
                    var inputError = getInputError(input, entityErrorsCollection);
                    var result = null;
                    if (inputError !== null)
                    {
                        result = inputError.Error;
                    }
                    return result;
                }

            };

            /**
             * Returns the entity errors for input fields that are not visible.
             * 
             * @name getHiddenPropertiesErrors
             * @function
             * @instance
             * @memberof modularis.web.validation.ServerSideValidationRule
             * @returns {Array} 
             */
            that.getHiddenPropertiesErrors = function ()
            {
                return hiddenPropertiesErrors;
            };

            /**
             * Returns the errors from properties that do not have a corresponding input in the view
             * 
             * @name getUnavailablePropertiesErrors
             * @function
             * @instance
             * @memberof modularis.web.validation.ServerSideValidationRule
             * @returns {Array} 
             */
            that.getUnavailablePropertiesErrors = function () {

                var unavailablePropertiesErrors = [];
                for (var index = 0; index < entityErrorsCollection.length; index++) {
                    var entityError = entityErrorsCollection[index];
                    var selector = '[name="' + entityError.PropName + '"]';
                    if (!$(selector) || $(selector).length === 0) {
                        unavailablePropertiesErrors.push(entityError);
                    }
                }
                return unavailablePropertiesErrors;

            };
            
        };

        return {
            ServerSideValidationRule: ServerSideValidationRule
        };

    }
);
