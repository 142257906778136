define('modularisTemplateLoader',
  ['jquery', 'kendo', 'localization', 'util', 'configLoader'],
  function ($, kendo, localization, modularisUtil, configLoader) {
    'use strict';

    /**
    * @property {object} loadedTemplates - Caches the loaded templates so that can be reused after the first load.
    */
    var loadedTemplates = {};
    var loadingTemplates = {};

    /*
    * Gets the full path of the template.
    * @function getFullTemplatePath
    * @param {string} path - Template path.
    * @returns {string} - Full template path.
    */
    var getFullTemplatePath = function (path) {
      //var result = path.toLowerCase();
      var result = path;
      var config = configLoader.appConfig;

      //Check for mobile templates
      if (configLoader.containsConfigValue('mobileSupport')) {
        var mobileSupport = config.mobileSupport;
        if (mobileSupport.enabled && kendo.support.mobileOS) {
          var currentDevice = kendo.support.mobileOS.tablet ? 'tablet' : 'phone';
          //Check if the current device should display the mobile template
          if (mobileSupport.availableFor.indexOf(currentDevice) > -1) {

            //Check if the view has a mobile version
            for (var templateIndex = 0; templateIndex < mobileSupport.views.length; templateIndex++) {
              var currentTemplate = mobileSupport.views[templateIndex].toLowerCase();
              if (currentTemplate === result.toLowerCase()) {
                result += '.mob';
                break;
              }
            }

          }
        }
      }

      if (result.toLowerCase().indexOf(config.templatesBasePath.toLowerCase()) < 0) {
        result = config.templatesBasePath + result;
      }
      if (result.toLowerCase().indexOf(config.templatesExtension.toLowerCase()) < 0) {
        result += config.templatesExtension;
      }
      return result;
    };

    /*
    * Adds the data title attribute to the view associated to the given template id.
    * @function addViewTitle
    * @param {object} responseObject - Template path.
    */
    var addViewTitle = function (responseObject) {
      /*eslint-disable no-param-reassign*/
      if (responseObject.templateId) {
        var viewSelector = '#' + responseObject.templateId;
        var titleKey = $(viewSelector).data('title-key');
        if (titleKey) {
          responseObject.title = localization.translate(titleKey);
        } else {
          var title = $(viewSelector).data('title');
          if (title) {
            responseObject.title = title;
          }
        }
      }
      /*eslint-enable no-param-reassign*/
    };

    /**
    * Provides functions for template loading.
    * @namespace remoteTemplateLoader
    * @memberof modularis.web
    */
    var remoteTemplateLoader = {

      /**
      * Loads a template located at the given path.
      * @param {string} path - Template path.
      * @param {function} callback - Function to be called after the template is loaded.
      * @memberOf modularis.web.remoteTemplateLoader
      */
      loadTemplate: function (path, callback) {
        var cacheViews = configLoader.appConfig.cacheViews;

        var fullPath = getFullTemplatePath(path);
        if (!cacheViews) {
          fullPath += modularisUtil.getCacheUrlArgs();
        }

        if (typeof (loadedTemplates[fullPath]) !== 'undefined') {
          var responseObject = {
            templateId: loadedTemplates[fullPath]
          };
          modularisUtil.notify(callback, responseObject, null);
        } else if (fullPath in loadingTemplates) {

          loadingTemplates[fullPath].push(callback);
        }
        else {
          loadingTemplates[fullPath] = [callback];
          $.ajax({
            url: fullPath
          })
            .done(function (responseResult) {

              var responseObject = {};

              //Check if the view was already loaded from another thread
              if (typeof (loadedTemplates[fullPath]) === 'undefined') {
                //Get template id and append template to body
                var templatedId = $(responseResult).appendTo('head').attr('id');

                if (templatedId != null) {
                  responseObject.templateId = templatedId;
                  loadedTemplates[fullPath] = templatedId;
                }
              } else {
                responseObject.templateId = loadedTemplates[fullPath];
              }

              addViewTitle(responseObject);
              var callbacks = loadingTemplates[fullPath];
              delete loadingTemplates[fullPath];

              for (var callbackIndex = 0; callbackIndex < callbacks.length; callbackIndex++) {
                modularisUtil.notify(callbacks[callbackIndex], responseObject, null);
              }


            })
            .fail(function (errorResult) {
              var errorObject = {
                status: errorResult.status,
                statusText: errorResult.statusText
              };
              modularisUtil.notify(callback, null, errorObject);
            });
        }
      },

      /**
       * Clears the cache of loaded templates.
       */
      reset: function () {
        loadedTemplates = {};
      }
    };

    return remoteTemplateLoader;
  }
);
