define('modularis/web/widgets/binders/localizeBinder',
   ['jquery', 'kendo', 'modularis/i18n/localizationHelper'],
   function ($, kendo, localizationHelper) {
       'use strict';

        /**
         * @classdesc Modularis Localize Binder.  Inherits from {@link https://docs.telerik.com/kendo-ui/framework/localization/overview}
         * <p>This class provides a mechanism for translating the message of the elements according to their specific location, with a simple configuration.</p>
         * @constructs modularis.web.widgets.localeBinderContainer
         * @inner
         * 
         * @example <caption> <p>How to use <b>Localize Widget</b></p>
         * <p><ul>1. Add the <b>data-bind</b> attribute to the element.</ul>
         * <ul>2. Inside the attribute must have the following structure "localize:{attributeName:'ResourceKey'}"
         * <p>Where <b>attributeName</b> is the attribute where is going to apply the translation result (<i>e.g. text and title</i>) and <b>ResourceKey</b> is the key that must be used to find a translation for the element (<i>This is configured in the string resources</i>).</p>
         * <p> All attributes inside of localize tag go separated by commas.</p></ul></p>
         * </caption>
         * <a href="#/theme"   
         * data-bind="localize:{title:'Modularis_Examples_SimpleProductCatalog_Entity_Customer', text:'theme_link_text'}"></a>
         *  
        **/

       var localeBinderContainer = {

           init: function () {
               /*eslint no-param-reassign: 0*/

               var refresh = function () {
                   var that = this;
                   localizationHelper.localize(that.element, that.keyMapping);
               };

               var initWidget = function (binder, element, bindings, options) {
                   var elementBinder = binder;

                   kendo.data.Binder.fn.init.call(elementBinder, element, bindings, options);

                   elementBinder.keyMapping = {};

                   for (var key in elementBinder.bindings.localize.path) {
                       if (elementBinder.bindings.localize.path.hasOwnProperty(key)) {
                           elementBinder.keyMapping[key] = elementBinder.bindings.localize.path[key];
                       }
                   }
               };

               kendo.data.binders.widget.localize = kendo.data.Binder.extend({
                   init: function (widget, bindings, options) {
                       var that = this;
                       initWidget(that, widget.element[0], bindings, options);
                   },
                   refresh: refresh
               });

               kendo.data.binders.localize = kendo.data.Binder.extend({
                   init: function (element, bindings, options) {
                       var that = this;
                       initWidget(that, element, bindings, options);
                   },
                   refresh: refresh
               });

           }

       };

       return localeBinderContainer;

   }
);
