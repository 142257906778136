define('modularisBase/entityMetadata',
    ['enums'],
    function (enums) {
        'use strict';

        var types = enums.propertyTypes;

        /**
         * @classdesc Class that provides functions to access entity metadata and state information. This class cannot be instantiated directly. 
         * An instance can be obtained using the {@link modularis.EntityBase#getEntityMetadata|getEntityMetadata} function of an entity.
         * @constructs modularis.EntityMetadata
         */

        var EntityMetadata = function (entity) {
            var that = this;
            that.entity = entity;
            that.propertyDefCache = {};

        };

        /**
         * Returns an attribute value.
         * @param {string} attributeName - Name of the attribute to return.
         * 
         * @name getAttribute
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * @returns {object} 
         */
        EntityMetadata.prototype.getAttribute = function (attributeName) {
            var allAtributes = this.getAttributes();
            for (var index = 0; index < allAtributes.length; index++) {
                var attributeObject = allAtributes[index];
                if (attributeName in attributeObject) {
                    return attributeObject[attributeName];
                }
            }
            return null;
        };

        /**
         * Returns all the attributes defined in the entity.
         * 
         * @name getAttributes
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * @returns {Array} 
         */
        EntityMetadata.prototype.getAttributes = function () {
            return this.entity._serializationMetadata.Attributes;
        };

        /**
         * Sets an attribute providing its name and the new value.
         * 
         * @name setAttribute
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {string} attributeName - Attribute name.
         * @param {object} attributeValue - Attribute value.
         */
        EntityMetadata.prototype.setAttribute = function (attributeName, attributeValue) {
            var allAtributes = this.getAttributes();
            var changed = false;
            for (var index = 0; index < allAtributes.length; index++) {
                var attributeObject = allAtributes[index];
                if (attributeName in attributeObject) {
                    attributeObject[attributeName] = attributeValue;
                    changed = true;
                }
            }

            if (!changed) {
                var newAttibute = {};
                newAttibute[attributeName] = attributeValue;
                allAtributes.push(newAttibute);
            }
        };

        EntityMetadata.prototype.removeAttribute = function (attributeName) {

            if (attributeName.indexOf('.OV') >= 0 || attributeName.indexOf('.DV')) {
                return;
            }

            var attributes = this.getAttributes();
            for (var index = 0; index < attributes.length; index++) {
                var currentAttribute = attributes[index];
                if (attributeName in currentAttribute) {
                    attributes.splice(index, 1);
                    break;
                }
            }
        };

        EntityMetadata.prototype._attributeExists = function (attributeName) {
            var allAtributes = this.getAttributes();
            for (var index = 0; index < allAtributes.length; index++) {
                var attributeObject = allAtributes[index];
                if (attributeName in attributeObject) {
                    return true;
                }
            }
            return false;
        };

        /**
         * Returns the original value for a given property.
         * 
         * @name getOriginalValue
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {string} propertyName - Property name to be consulted.
         * @returns {object}  
         */
        EntityMetadata.prototype.getOriginalValue = function (propertyName) {
            var that = this;
            var originalValue;
            var attributeName = propertyName + '.OV';
            if (that._attributeExists(attributeName)) {
                originalValue = that.getAttribute(attributeName);
            } else {
                originalValue = that.entity.getPropertyValue(propertyName);
            }
            return originalValue;
        };

        EntityMetadata.prototype._storeOriginalValue = function (propertyName, currentValue) {
            var that = this;
            if (that.getEditMode() !== enums.editModes.update) { return; }

            var attributeName = propertyName + '.OV';
            var exists = that._attributeExists(attributeName);
            if (!exists) {
                //Check if the OV attribute is valid for the property type
                var propertyDef = that.getPropertyDef(propertyName);
                var ovValid = propertyDef.type !== types.typeObject && propertyDef.type !== types.typeBLOB
                    && propertyDef.type !== types.typeEntity && propertyDef.type !== types.typeEntityCollection
                    && propertyDef.type !== types.typeCollection && propertyDef.type !== types.typeDictionary;
                if (ovValid) {
                    that.setAttribute(attributeName, currentValue);
                }
            }
        };

         /**
         * Returns the value of the edit mode (Update, Insert, Delete).
         * 
         * @name getEditMode
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {string}  
         */
        EntityMetadata.prototype.getEditMode = function () {
            return this.entity._serializationMetadata.EditMode;
        };

        /**
         * Sets the value of the edit mode with the value specified in the parameter newEditMode.
         * 
         * @name setEditMode
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {string} newEditMode - Edit mode to be set.
         */
        EntityMetadata.prototype.setEditMode = function (newEditMode) {
            this.entity._serializationMetadata.EditMode = newEditMode;
        };

        /**
         * Returns value of the entity name.
         * 
         * @name getEntityName
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {string}  
         */
        EntityMetadata.prototype.getEntityName = function () {
            return this.entity._serializationMetadata.Name;
        };

         /**
         * Returns an array with the entities errors.
         *
         * @name getErrors
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         *
         * @returns {Array}
         */
        EntityMetadata.prototype.getErrors = function () {
            if (!this.entity._serializationMetadata.Errors) {
                this.entity._serializationMetadata.Errors = [];
            }
            return this.entity._serializationMetadata.Errors;
        };

         /**
         * Sets the entity metadata errors.
         *
         * @name setErrors
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         *
         * @param {string} newErrorsValue - Array with the errors to be set.
         */
        EntityMetadata.prototype.setErrors = function (newErrorsValue) {
            this.entity._serializationMetadata.Errors = newErrorsValue;
        };

         /**
         * Indicates whether the entity is valid or not.
         *
         * @name isValid
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @return {boolean}
         */
        EntityMetadata.prototype.isValid = function () {
            return this.entity._serializationMetadata.Valid;
        };

        /**
         * Sets the metadata Valid property with the value in the parameter newIsValidValue.
         *
         * @name setValid
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {boolean} newIsValidValue - The new valid value to be set.
         */
        EntityMetadata.prototype.setValid = function (newIsValidValue) {
            this.entity._serializationMetadata.Valid = newIsValidValue;

            if (this.entity._Entity) {
                this.entity._Entity.Valid = newIsValidValue;
            }
        };

        /**
         * Indicates whether the entity graph associated with the entity is valid or not.
         * 
         * @name isGraphValid
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {boolean} 
         */
        EntityMetadata.prototype.isGraphValid = function () {
            var that = this;
            var graphValid = true;
            var propertyNames = that.getPropertyNames();
            for (var index = 0; (index < propertyNames.length) && graphValid; index++) {
                var propertyName = propertyNames[index];
                var propertyDef = that.getPropertyDef(propertyName);
                switch (propertyDef.type) {
                    case types.typeEntity:
                        if (that.entity[propertyName]) {
                            graphValid = that.entity[propertyName].getEntityMetadata().isGraphValid();
                        }
                        break;
                    case types.typeEntityCollection:
                        if (that.entity[propertyName]) {
                            graphValid = that.entity[propertyName].getCollectionMetadata().isGraphValid();
                        }
                        break;
                    //no default
                }
            }
            return graphValid;
        };

        /**
         * Indicates whether the entity has been updated or not.
         *
         * @name isDirty
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {boolean}
         */
        EntityMetadata.prototype.isDirty = function () {
            return this.entity._serializationMetadata.Dirty;
        };

        /**
         * Indicates whether the entity graph associated to the entity is dirty or not.
         * 
         * @name isGraphDirty
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * @returns {boolean} 
         */
        EntityMetadata.prototype.isGraphDirty = function () {
            var that = this;
            var graphDirty = that.isDirty();
            if (graphDirty) { return graphDirty; }

            var propertyNames = that.getPropertyNames();
            for (var index = 0; (index < propertyNames.length) && !graphDirty; index++) {
                var propertyName = propertyNames[index];
                var propertyDef = that.getPropertyDef(propertyName);
                switch (propertyDef.type) {
                    case types.typeEntity:
                        if (that.entity[propertyName]) {
                            graphDirty = that.entity[propertyName].getEntityMetadata().isGraphDirty();
                        }
                        break;
                    case types.typeEntityCollection:
                        if (that.entity[propertyName]) {
                            graphDirty = that.entity[propertyName].getCollectionMetadata().isGraphDirty();
                        }
                        break;
                    //no default
                }
            }
            return graphDirty;
        };

        /**
         * Changes the value of the Dirty flag for the current entity and all its children.
         * @name setGraphDirty
         * @param {boolean} newGraphDirtyValue - New GraphDirty value.
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         */
        EntityMetadata.prototype.setGraphDirty = function (newGraphDirtyValue) {
            var that = this;

            //Change root entity
            that.setDirty(newGraphDirtyValue);

            //Change flag for child entities
            var propertyNames = that.getPropertyNames();
            for (var index = 0; index < propertyNames.length; index++) {
                var propertyName = propertyNames[index];
                var propertyDef = that.getPropertyDef(propertyName);
                switch (propertyDef.type) {
                    case types.typeEntity:
                        if (that.entity[propertyName]) {
                            that.entity[propertyName].getEntityMetadata().setGraphDirty(newGraphDirtyValue);
                        }
                        break;
                    case types.typeEntityCollection:
                        if (that.entity[propertyName]) {
                            that.entity[propertyName].getCollectionMetadata().setGraphDirty(newGraphDirtyValue);
                        }
                        break;
                    //no default
                }
            }
        };

        /**
         * Changes the value of the Dirty flag for the current entity.
         * @name setDirty
         * @param {boolean} newDirtyValue - New GraphDirty value.
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         */
        EntityMetadata.prototype.setDirty = function (newDirtyValue) {
            this.entity._serializationMetadata.Dirty = newDirtyValue;
        };

        /**
         * Returns the structure of the key for the current entity.
         * @name getKeyStructure
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {string}
         */
        EntityMetadata.prototype.getKeyStructure = function () {
            return this.entity._keyStructure;
        };

        /**
         * Returns the property definition for the property name given in the parameter propertyName.
         * @name getPropertyDef
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {string} propertyName - The property to be consulted.
         * @returns {object}
         */
        EntityMetadata.prototype.getPropertyDef = function (propertyName) {
            var that = this;
            if (propertyName in that.propertyDefCache) {
                return that.propertyDefCache[propertyName];
            }

            for (var index = 0; index < that.entity._propertyDefs.length; index++) {
                var propertyDef = that.entity._propertyDefs[index];
                if (propertyDef.name === propertyName) {
                    that.propertyDefCache[propertyName] = propertyDef;
                    return propertyDef;
                }
            }
            return null;
        };

        /**
         * Returns an array with all the property names of the current entity.
         * @name getPropertyNames
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @returns {Array}
         */
        EntityMetadata.prototype.getPropertyNames = function () {
            var that = this;
            var propertyNames = [];
            for (var property in that.entity._propertyValues) {
                if (that.entity._propertyValues.hasOwnProperty(property)) {
                    propertyNames.push(property);
                }
            }
            return propertyNames;
        };

        /**
         * Sets the entity structure with the property definitions given in the parameter propertyDefs.
         * @name setEntityStructure
         * 
         * @function
         * @instance
         * @memberOf modularis.EntityMetadata
         * 
         * @param {Array} propertyDefs - The property definitions to be set.
         */
        EntityMetadata.prototype.setEntityStructure = function (propertyDefs) {
            var that = this;

            var entityStructure = propertyDefs.length;

            var serializationMetadata = {
                'AssemblyName': that.entity._serializationMetadata.AssemblyName,
                'EntityStructure': '',
                'Culture': that.entity._serializationMetadata.Culture,
                'Name': that.entity._serializationMetadata.Name,
                'Type': that.entity._serializationMetadata.Type,
                'AllowNulls': that.entity._serializationMetadata.AllowNulls,
                'Dirty': that.entity._serializationMetadata.Dirty,
                'EditMode': that.entity._serializationMetadata.EditMode,
                'Valid': that.entity._serializationMetadata.Valid,
                'Attributes': that.entity._serializationMetadata.Attributes
            };

            for (var index = 0; index < propertyDefs.length; index++) {
                var propertyDef = propertyDefs[index];
                entityStructure += String.format('|{0}|{1}', propertyDef.PropertyName, propertyDef.PropertyType);
            }

            serializationMetadata.EntityStructure = entityStructure;
            that.entity._serializationMetadata = serializationMetadata;
        };


        /**
         * @classdesc Class that provides functions to access entity collection metadata and state information. This class cannot be instantiated directly.
         * An instance can be obtained using the {@link modularis.EntityCollectionBase#getCollectionMetadata|getCollectionMetadata} function of an entity collection.
         * @constructs modularis.EntityCollectionMetadata
         */

        var EntityCollectionMetadata = function (entityCollection) {
            var that = this;
            that.entityCollection = entityCollection;

        };

        /**
         * Returns the name of the entity associated to the collection.
         * 
         * @name getEntityName
         * @function
         * @instance
         * @memberof modularis.EntityCollectionMetadata
         * @returns {string} 
         */
        EntityCollectionMetadata.prototype.getEntityName = function () {
            var that = this;
            return that.entityCollection._entityTypeName.substr(that.entityCollection._entityTypeName.lastIndexOf('.') + 1);
        };

        /**
         * Indicates whether the entity graph associated to the collection is valid or not.
         * 
         * @name isGraphValid
         * @function
         * @instance
         * @memberof modularis.EntityCollectionMetadata
         * @returns {string} 
         */
        EntityCollectionMetadata.prototype.isGraphValid = function () {
            var that = this;
            var graphValid = true,
                entityMetadata = null;
            for (var index = 0; (index < that.entityCollection.Items.length) && graphValid; index++) {
                entityMetadata = that.entityCollection.Items[index].getEntityMetadata();
                graphValid = entityMetadata.isValid() && entityMetadata.isGraphValid();
            }
            return graphValid;
        };

        /**
        * Indicates whether the entity graph associated to the collection is dirty or not.
        * 
        * @name isGraphDirty
        * @function
        * @instance
        * @memberof modularis.EntityCollectionMetadata
        * @returns {string} 
        */
        EntityCollectionMetadata.prototype.isGraphDirty = function () {
            var that = this;
            var graphDirty = false,
                entityMetadata = null;
            for (var index = 0; (index < that.entityCollection.Items.length) && !graphDirty; index++) {
                entityMetadata = that.entityCollection.Items[index].getEntityMetadata();
                graphDirty = entityMetadata.isDirty() || entityMetadata.isGraphDirty();
            }
            return graphDirty;
        };

        /**
        * Returns the total number of items returned by the underlying query used to build the collection. 
        * 
        * @name getTotalCount
        * @function
        * @instance
        * @memberof modularis.EntityCollectionMetadata
        * @returns {number} 
        */
        EntityCollectionMetadata.prototype.getTotalCount = function () {
            
            return this.entityCollection._serializationMetadata.TotalCount;
        };
        
        /**
         * Changes the value of the Dirty flag for all the entities in the collection and all its children.
         * @name setGraphDirty
         * @param {boolean} newGraphDirtyValue - New GraphDirty value.
         * 
         * @function
         * @instance
         * @memberof modularis.EntityCollectionMetadata
         */
        EntityCollectionMetadata.prototype.setGraphDirty = function (newGraphDirtyValue) {
            var that = this;
            var entityMetadata = null;
            for (var index = 0; index < that.entityCollection.Items.length; index++) {
                entityMetadata = that.entityCollection.Items[index].getEntityMetadata();
                entityMetadata.setGraphDirty(newGraphDirtyValue);
            }
        };

        return {
            EntityMetadata: EntityMetadata,
            EntityCollectionMetadata: EntityCollectionMetadata
        };

    }
);
