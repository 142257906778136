define('modularis/base/dynamicEntityHelper',
    ['jquery', 'RESTActivityWebServiceProxy', 'metadataCache', 'util'],
    function ($, RESTActivityWebServiceProxy, metadataCache, util) {
        'use strict';

        /**
          * Modularis Dynamic Entity Helper
          * @namespace dynamicEntityHelper
          * @memberof modularis.web
          */
        var dynamicEntityHelper = {
            /**
             * Creates and initializes a new dynamic entity instance based on the given entityDefID.
             * @param {string} entityDefID -  The full entity definition ID.
             * @param {Object} options - A plain object representing any custom actions to be performed during execution.
             * @param {requestCallback} callback - The function to be executed when the process is completed.
             * @memberOf modularis.web.dynamicEntityHelper
             */
            getNew: function (entityDefID, options, callback) {
                metadataCache.getEntityDef({
                    entityDefId: entityDefID,
                    includePropertyDefs: true,
                    callback: function (entityDef, error) {
                        if (util.success(entityDef, error)) {

                            var proxyOptions = { entityDef: entityDef };
                            $.extend(proxyOptions, options);

                            RESTActivityWebServiceProxy.getNew(entityDefID, proxyOptions, callback);
                        }
                    }
                });
            }
        };

        return dynamicEntityHelper;
    }
);
