define('modularis/viewModels/widgets/moduleMenu',
    ['jquery', 'metadataCache', 'kendo', 'viewModelBase', 'util', 'mediator/colleagueBase'],
    function ($, metadataCache, kendo, viewModelBaseFactory, util, ColleagueBase) {
        'use strict';

        var ClassName = {
            collapsed: 'sidebar-collapse',
            open: 'sidebar-open',
            mini: 'sidebar-mini',
            expanded: 'sidebar-expanded-on-hover',
            expandFeature: 'sidebar-mini-expand-feature',
            layoutFixed: 'fixed',
            tree: 'tree',
            treeOpen: 'menu-open',     
            vertical: 'menubar-vertical',     
            horizontal: 'menubar-horizontal',
            openPanel: 'open-panel'
        };
        var Selector = {
            treeContainer: '#menuMetaDataTree',
            horizontalContainer: '#menuMetaDataHorizontal',
            tree: '.tree',
            treeview: '.treeview',
            horizontalview: '.horizontalview',
            treeviewMenu: '.treeview-menu',
            horizonalviewMenu: '.horizontalview-menu',
            open: '.menu-open, .active',
            buttonContainer: '#navbar-header',
            widgetContainer: '.main-menubar'
        };

        var colleagueId = 'modularis.ModuleMenuViewModel';

        var ModuleMenuViewModel = function () {
            var that = this;
            viewModelBaseFactory.ViewModelBase.call(that);
            ColleagueBase.call(that, colleagueId);

            //#region Properties
            that.breakpoint = null;
            that.triggerSelector = '.treeview a';
            that.triggerSelectorHor = '.horizontalview a';
            that.triggerHorizontalSelector = '.horizontalview';
            that.expandMode = null;
            that.animationSpeed = null;
            that.followLink = false;
            that.element = null;

            //that.createModules();  
            //#endregion

        };

        ModuleMenuViewModel.prototype = new viewModelBaseFactory.ViewModelBase();
        $.extend(ModuleMenuViewModel.prototype, Object.create(ColleagueBase.prototype));

        //#region Event handlers
        ModuleMenuViewModel.prototype.onReceiveMessage = function (senderId, messageType) {
            var that = this;
            switch (messageType) {
                case 'mainHeaderViewIsDisplayed':
                    that._createToggleButton();
                    break;
                default:
                    break;
            }
        };

        ModuleMenuViewModel.prototype.setValue = function (key, value) {
            var that = this;
            that.set(key, value);
        };

        ModuleMenuViewModel.prototype._createToggleButton = function () {
            var dom = this.isVertical() ? '<button class="toggleButton m-sidebar-toggle k-button"><span class="k-icon k-i-menu"></span></button>' : '<button class="toggleButton m-sidebar-toggle k-button horizontal-button"><span class="k-icon k-i-menu"></span></button>';
            if ($(Selector.buttonContainer).find('.toggleButton').length === 0) {
                $(Selector.buttonContainer).append(dom);
                $(Selector.buttonContainer).find('.toggleButton').on('click', $.proxy(this.togglePanel, this));
            }
        };

        ModuleMenuViewModel.prototype.togglePanel = function () {
            var isOpen;
            if (this.isVertical()) {
                var windowWidth = $(window).width();
                isOpen = !$('body').hasClass(ClassName.collapsed);

                if (windowWidth <= this.breakpoint) {
                    isOpen = $('body').hasClass(ClassName.open);
                }

                if (!isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            } else {
                isOpen = $(this.element).parent().hasClass(ClassName.openPanel);
                if (!isOpen) {
                    this.showMenu();
                } else {
                    this.hideMenu();
                }
            }
            
        };

        ModuleMenuViewModel.prototype.showMenu = function () {
            $(this.element).parent().addClass(ClassName.openPanel);
            $(this.element).slideDown(this.animationSpeed);
        };

        ModuleMenuViewModel.prototype.hideMenu = function () {
            $(this.element).parent().removeClass(ClassName.openPanel);
            $(this.element).slideUp(this.animationSpeed);
        };

        ModuleMenuViewModel.prototype.open = function () {
            var that = this;
            var windowWidth = $(window).width();
            if (windowWidth > this.breakpoint) {
                $('body').removeClass(ClassName.collapsed);
                that.trigger('onExpand', that);
            }
            else {
                $('body').addClass(ClassName.open);
                that.trigger('onExpand', that);
            }
        };

        ModuleMenuViewModel.prototype.close = function () {
            var that = this;
            var windowWidth = $(window).width();
            if (windowWidth > this.breakpoint) {
                $('body').addClass(ClassName.collapsed);
                that.trigger('onCollapse', that);
            } else {
                $('body').removeClass(ClassName.open + ' ' + ClassName.collapsed);
                that.trigger('onCollapse', that);
            }
        };

        ModuleMenuViewModel.prototype.afterViewIsDisplayed = function () {
            this.createModules();
            if (this.isVertical()) {                
                $('body').addClass(ClassName.mini);
                $(Selector.widgetContainer).addClass(ClassName.vertical);
                this.set('element', Selector.treeContainer);
            } else {
                $(Selector.widgetContainer).addClass(ClassName.horizontal);
                this.set('element', Selector.horizontalContainer);
            }
            this.createMenu();
            
        };

        ModuleMenuViewModel.prototype.createMenu = function () {
            $(this.element).addClass(this.isVertical() ? ClassName.tree : ClassName.horizontal);
            this._setUpListeners();
        };

        ModuleMenuViewModel.prototype._setUpListeners = function () {
            var that = this;
            var currentSelector = that.isVertical() ? that.triggerSelector : that.triggerSelectorHor;
            $(that.element).on('click', currentSelector, function (event) {
                that.toggle($(this), event);
            });
            if (!this.isVertical()) {
                $(that.element).on('mouseleave', that.triggerHorizontalSelector, function () {
                    var windowWidth = $(window).width();
                    if (windowWidth > that.breakpoint) {
                        that.collapse($(this).children('a'), $(this).children('a').parent());
                    }
                    
                });
            }
        };

        ModuleMenuViewModel.prototype.toggle = function (link, event) {
            var treeviewMenu = link.next(this.isVertical() ? Selector.treeviewMenu : Selector.horizonalviewMenu);
            var parentLi = link.parent();
            var isOpen = parentLi.hasClass(ClassName.treeOpen);
            if (!parentLi.is(this.isVertical() ? Selector.treeview : Selector.horizontalview)) {
                return;
            }

            if (!this.followLink || link.attr('href') === '#') {
                event.preventDefault();
            }

            if (isOpen) {
                this.collapse(treeviewMenu, parentLi);
            } else {
                this.expand(treeviewMenu, parentLi);
            }
        };

        ModuleMenuViewModel.prototype.expand = function (tree, parent) {
            if (this.expandMode === 'single' || this.orientation === 'horizontal') {
                var openMenuLi = parent.siblings(Selector.open);
                var openTree = openMenuLi.children(this.isVertical() ? Selector.treeviewMenu : Selector.horizonalviewMenu);
                this.collapse(openTree, openMenuLi);
            }

            parent.addClass(ClassName.treeOpen);
            if (this.isVertical()) {
                 tree.slideDown(this.animationSpeed);
            }
        };

        ModuleMenuViewModel.prototype.collapse = function (tree, parentLi) {
            var that = this;
            tree.find(Selector.open).removeClass(ClassName.treeOpen);
            parentLi.removeClass(ClassName.treeOpen);
            if (this.isVertical()) {
                tree.slideUp(this.animationSpeed, function () {
                    var widgetview = that.isVertical() ? Selector.treeview : Selector.horizontalview;
                    tree.find(Selector.open + ' > ' + widgetview).slideUp();
                });
            }
        };

        ModuleMenuViewModel.prototype.isVertical = function () {
            return this.orientation === 'vertical';
        };

        ModuleMenuViewModel.prototype.disposeViewModel = function () {
            $(Selector.buttonContainer).off('click', '.toggleButton');
            $(this.element).off('click', this.triggerSelector);
            $(this.element).off('mouseleave', this.triggerHorizontalSelector);
            if ($(Selector.treeContainer).length > 0) {
                $(Selector.treeContainer).remove();
            }
            if ($(Selector.buttonContainer).find('.toggleButton').length > 0) {
                $(Selector.buttonContainer).find('.toggleButton').remove();
            }
            $('body').removeClass();
        };

        ModuleMenuViewModel.prototype.createModules = function () {
            var that = this;
            metadataCache.getModuleGroups(function (moduleGroups, moduleGroupError) {                
                if (util.success(moduleGroups, moduleGroupError)) {
                    var domContainer = '';
                    var currentGroup, currentModule;
                    var moduleGroupsItems = moduleGroups.Items;
                    for (var groupIndex in moduleGroupsItems) {
                        if (moduleGroupsItems.hasOwnProperty(groupIndex)) {
                            currentGroup = moduleGroupsItems[groupIndex];
                            var domWithParentFormat = '<li class="{0}"><a href="#"><i class="m-menu-icon icon-{1}"></i><span>{2}</span><span class="pull-right-container"><span class="k-icon {3} pull-right"></span></span></a>{4}</li>';
                            var domWithNoParentFormat = '<li class="{0}">{1}</li>';
                            var displayParent = !that.isVertical() || currentGroup.NavDisplayOrder >= 0;
                            var currentDom = displayParent ? domWithParentFormat : domWithNoParentFormat;
                            var arrowClass = that.isVertical() ? 'k-i-arrow-60-left' : 'k-i-arrow-60-down',
                                horizontalClass = 'horizontalview',
                                verticalClass = currentGroup.Name === 'Main' ? 'active treeview menu-open' : 'treeview',
                                classes = that.isVertical() ? verticalClass : horizontalClass,
                                icon = currentGroup.Name.toLowerCase(),
                                children = '';

                            if (currentGroup.UIModules) {
                                var modules = currentGroup.UIModules.Items;
                                var menuModuleItemOpenFormat = that.isVertical() ? '<ul class="treeview-menu{0}">' : '<ul class="horizontalview-menu{0}">';
                                var menuModuleItemOpen = displayParent ? String.format(menuModuleItemOpenFormat, '') : String.format(menuModuleItemOpenFormat, ' no-parent');
                                children += menuModuleItemOpen;
                                for (var moduleIndex in modules) {
                                    if (modules.hasOwnProperty(moduleIndex)) {
                                        currentModule = modules[moduleIndex];
                                        var moduleURL = currentModule.Name.startsWith('/') ? '#' + currentModule.Name : '#/' + currentModule.Caption.toLowerCase().replace(' ', '-');
                                        children += '<li><a href="' + moduleURL + '">' + currentModule.Caption + '</a></li>';
                                    }
                                }
                                children += '</ul>';
                                var moduleGroupDom = displayParent ?
                                    String.format(currentDom, classes, icon, currentGroup.Caption, arrowClass, children) :
                                    String.format(currentDom, classes, children);
                                domContainer += moduleGroupDom;
                            }
                        }
                    }
                    if ($(Selector.treeContainer).find('li').length === 0) {
                        if (that.isVertical()) {
                            $(Selector.treeContainer).append(domContainer);
                        } else {
                            $(Selector.horizontalContainer).append(domContainer);
                        }
                        
                    }

                }
            });

        };
        //#endregion

        //Enable the second parameter in case the view-model could receive parameters configured in the corresponding route.
        var initializeForView = function (callback) {
            var viewModelInstance = new ModuleMenuViewModel();
            util.notify(callback, viewModelInstance);

        };

        return {
            initializeForView: initializeForView
        };

    }
);
