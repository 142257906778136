define('modularisData/dataSourceFactory',
    ['dataSource', 'metadataCache', 'securityHelper', 'util', 'logger', 'enums'],
    function (DataSource, metadataCache, securityHelper, util, logger, enums) {
        'use strict';

        var types = enums.propertyTypes;
        var readOnlyAttributeName = 'PropertyName.RO';

        var isEditableField = function (propDef) {

            var isReadOnly = false;
            var attributes = propDef._Entity.Attributes;
            if (util.isArray(attributes) && attributes.length > 0) {
                for (var index = 0; index < attributes.length && !isReadOnly; index++) {
                    var attribute = attributes[index];
                    if (readOnlyAttributeName in attribute) {
                        isReadOnly = true;
                    }
                }
            }

            return securityHelper.isFunctionApproved(propDef.AllowEditToken) && !isReadOnly;
        };

        var parsePropertyType = function (propDef) {
            var type = 'string';
            if (propDef) {
                switch (propDef.PropertyType) {
                    case types.typeByte:
                    case types.typeDecimal:
                    case types.typeDouble:
                    case types.typeInt16:
                    case types.typeInt32:
                    case types.typeInt64:
                    case types.typeSingle:
                        type = 'number';
                        break;
                    case types.typeString:
                    case types.typeGuid:
                        type = 'string';
                        break;
                    case types.typeDateTime:
                        type = 'date';
                        break;
                    case types.typeBoolean:
                        type = 'boolean';
                        break;
                    default:
                        type = null;
                }
            }
            return type;
        };

        /**
         * Object specialized in creating data sources based on metadata.
         * @namespace dataSourceFactory
         * @memberof modularis.web
         */
        var dataSourceFactory = {

            _createSync: function (entityDef, additionalConfig) {

                var propertyDefs = entityDef.PropertyDefs;
                var dataSourceConfig = additionalConfig;

                dataSourceConfig.schema = dataSourceConfig.schema || {};
                dataSourceConfig.schema.model = dataSourceConfig.schema.model || {};
                dataSourceConfig.schema.model.id = dataSourceConfig.schema.model.id || entityDef.KeyStructure;

                //Create fields
                var fields = dataSourceConfig.schema.model.fields || {};
                for (var index = 0; index < propertyDefs.Items.length; index++) {
                    var propertyDef = propertyDefs.Items[index];
                    //Create field definition only if the received configuration does not contain one already for the current field.
                    if (!(propertyDef.PropertyName in fields)) {
                        fields[propertyDef.PropertyName] = {
                            //Add property type
                            type: parsePropertyType(propertyDef),
                            //Editable?
                            editable: isEditableField(propertyDef),
                            validation: {
                                required: propertyDef.Mandatory
                            }
                        };

                    }
                }
                dataSourceConfig.schema.model.fields = fields;

                return new DataSource(dataSourceConfig);
            },

            /**
             * Asynchronously creates a data source and will assign it to the given view-model and property.
             * 
             * @param {modularis.web.ViewModelBase} viewModel - View-Model instance that contains the dataSource.
             * @param {string} viewModelPropertyName - Name of the property to which the created dataSource will be assigned.
             * @param {Object} dataSourceConfiguration - DataSource configuration.
             * 
             * @memberOf modularis.web.dataSourceFactory
             */
            initDataSource: function (viewModel, viewModelPropertyName, dataSourceConfiguration) {
                if (!dataSourceConfiguration || !dataSourceConfiguration.modularis || !dataSourceConfiguration.modularis.entityTypeName) {
                    logger.error('Please provide the setting modularis.entityTypeName to initialize the data source correctly.');
                    return;
                }
                var that = this;
                metadataCache.getEntityDef({
                    entityDefId: dataSourceConfiguration.modularis.entityTypeName,
                    includePropertyDefs: true,
                    callback: function (entityDef, entityDefError) {
                        if (util.success(entityDef, entityDefError)) {
                            var dataSource = that._createSync(entityDef, dataSourceConfiguration);
                            viewModel.set(viewModelPropertyName, dataSource);
                        } else {
                            logger.error('Error loading metadata');
                        }
                    }
                });

            }
        };

        return dataSourceFactory;
    }
);
