define('modularis/web/widgets/asyncValidator',
    ['jquery', 'kendo', 'util', 'enums', 'modularis/web/widgets/modularisValidator'],
    function ($, kendo, util, enums, modularisValidator) {
        'use strict';
        /*eslint new-cap:0*/
        var Validator = modularisValidator.widgetClass;

        //Get the DOM object in case the given field is a jQuery object.
        var getDOMField = function (inputField) {
            return inputField instanceof $ ? inputField.get(0) : inputField;
        };

        /**
         * @class
         * @classdesc Modularis Async Validator widget. Inherits from {@link http://docs.telerik.com/kendo-ui/api/javascript/ui/validator|kendo.ui.Validator}.
         * <p>This widget provides a mechanism for manage asynchronous server-side validations.</p>
         * @namespace modularis.web.widgets.AsyncValidator
         * @static
         * @see {@link modularis.web.widgets.ModularisValidator|ModularisValidator}
         */
        var AsyncValidator = Validator.extend({

            _currentValidations: [],
            _promises: [],

            init: function (element, options) {
                var that = this;
                Validator.fn.init.call(that, element, options);
            },

            options: {
                name: 'AsyncValidator'
            },

           /**
             * Register the current state of the validation.
             * @param {string} validationName - Validation name.
             * @param {input} inputField - Control to validate.
             * @param {Object} [currentState = checking] - Validation current state.
             * @returns {Object}  Validation promise. The result of completing the validation.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            registerValidation: function (validationName, inputField, currentState) {
                var that = this;

                var state = currentState;
                if (!state) {
                    state = enums.validationState.checking;
                }

                var deferred = $.Deferred(),
                    promise = null;
                var validation = that.getValidation(validationName, inputField);
                //Check if the validation already exists
                if (validation) {
                    validation.state = state;
                    promise = validation.promise;
                }else {
                    that._currentValidations.push({
                        name: validationName,
                        input: getDOMField(inputField),
                        state: state,
                        deferred: deferred
                    });
                    promise = deferred.promise();
                    that._promises.push(promise);
                }                
                return promise;
            },

           /**
             * Notifies the result of the validation.
             * @param {function} callback - Function to be called to notify with the validation result.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            notifyValidationResult: function (callback) {
                var that = this;
                $.when.apply($, that._promises).always(function () {
                    var validationResult = true,
                        asyncRulesCount = 0;
                    if (arguments.length > 0) {
                        for (var index = 0; index < arguments.length && validationResult; index++) {
                            validationResult = arguments[index].result;
                            asyncRulesCount++;
                        }
                    }
                    util.notify(callback, { valid: validationResult, rulesCount: asyncRulesCount });
                });
            },

           /**
             * Obtains the named validation if it was applied to the input field.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             * @returns {Object}  Validation object. Otherwise, null.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            getValidation: function (validationName, inputField) {
                var that = this;
                var result = null,
                    input = getDOMField(inputField);
                for (var index = 0; (index < that._currentValidations.length) && !result; index++) {
                    var validation = that._currentValidations[index];
                    if (validation.name === validationName && validation.input === input) {
                        result = validation;
                    }
                }
                return result;
            },

           /**
             * Gets the current state of the validation.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             * @returns {Object}  Validation state.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            getValidationState: function (validationName, inputField) {
                var validation = this.getValidation(validationName, inputField);
                var result = validation ? validation.state : null;
                return result;
            },

            /**
             * Sets the validation state to <i>failed</i>.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            validationFailed: function (validationName, inputField) {
                var input = getDOMField(inputField);
                var validation = this.getValidation(validationName, input);
                validation.state = enums.validationState.invalid;
                validation.deferred.reject({ validationName: validationName, input: input, result: false });
            },

            /**
             * Sets the validation state to <i>valid</i>.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            validationCompleted: function (validationName, inputField) {
                var input = getDOMField(inputField);
                var validation = this.getValidation(validationName, input);
                validation.state = enums.validationState.valid;
                validation.deferred.resolve({ validationName: validationName, input: input, result: true });
            },

           /**
             * Cleans up the validation result cache.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             *  
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            removeValidation: function (validationName, inputField) {
                var that = this;
                var validationIndex = -1,
                    input = getDOMField(inputField);
                for (var index = 0; (index < that._currentValidations.length) && (validationIndex < 0); index++) {
                    var validation = that._currentValidations[index];
                    if (validation.name === validationName && validation.input === input) {
                        validationIndex = index;
                    }
                }

                if (validationIndex >= 0) {
                    that._currentValidations.splice(validationIndex, 1);
                    that._promises.splice(validationIndex, 1);
                }
            },

           /**
             * Maps the validation state to a boolean value.
             * @param {string} validationName - Validation name.
             * @param {input} inputField -  The control where it was applied the validation.
             *  
             * @returns {Boolean}  True if the validation state is <i>valid</i>. Otherwise, False.
             *      
             * @instance
             * @memberof modularis.web.widgets.AsyncValidator
             * @static
             */
            mapStateToBoolean: function (validationName, inputField) {
                var validation = this.getValidation(validationName, inputField);
                var result = validation && validation.state === enums.validationState.valid;
                return result;
            }

        });

        return {
            widgetClass: AsyncValidator
        };
    }
);
