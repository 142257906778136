define('modularisData/activityAdaptee',
   ['jquery', 'modularisData/dataAccessAdaptee', 'RESTActivityWebServiceProxy', 'metadataCache', 'logger', 'webUtil', 'util', 'enums', 'objectBuilder'],
   function ($, DataAccessAdaptee, activityServiceProxy, metadataCache, logger, webUtil, util, enums, objectBuilder) {
       'use strict';

       var getCollectionByWhere = 'getcollectionbywhere',
           getAll = 'getall',
           getCollectionByQueryDefID = 'getcollectionbyquerydefid';

       var validActivityMethods = [getCollectionByWhere, getAll, getCollectionByQueryDefID];

       var ActivityAdaptee = function(config) {
           var that = this;
           that.method = null;
           that.entityTypeName = null;
           that.where = null;
           that.parameters = [];
           that.getChildren = false;
           that.options = null;
           that.queryDefID = null;
           that.propertyName = null;

           DataAccessAdaptee.call(that, config);
       };

       ActivityAdaptee.prototype = new DataAccessAdaptee();

       ActivityAdaptee.prototype.init = function (config) {
           var that = this;

           for (var property in config) {
               if (config.hasOwnProperty(property) && (property in that)) {
                   that[property] = config[property];
               }
           }
       };

       ActivityAdaptee.prototype.create = function (createOptions) {
           var proxyOptions = {};
           proxyOptions[enums.options.refetch] = true;

           var entity = createOptions.data;
           var entityTypeName = this.entityTypeName;
           objectBuilder.getAssemblyName(entityTypeName, function (assemblyName) {
               var state = {
                   _Entity: {
                       AssemblyName: assemblyName,
                       Culture: null,
                       Name: entityTypeName.substr(entityTypeName.lastIndexOf('.') + 1),
                       Type: 'Entity',
                       AllowNulls: false,
                       Dirty: true,
                       EditMode: enums.editModes.insert,
                       Valid: true,
                       Attributes: [],
                       Errors: []
                   }
               };

               var entityWithState = {};
               $.extend(true, entityWithState, state, entity);

               objectBuilder.createEntityObject({
                   assignIdentifier: true,
                   entityTypeName: entityTypeName,
                   copyFrom: entityWithState,
                   callback: function (transformedEntity, resultError) {
                       if (util.success(transformedEntity, resultError)) {
                           activityServiceProxy.save(transformedEntity, true, true, proxyOptions, function (entityResult, entityError) {
                               if (util.success(entityResult, entityError)) {
                                   createOptions.success(entityResult);
                               } else {
                                   createOptions.error(entityError);
                               }
                           });
                       } else {
                           createOptions.error(new Error('Error saving entity'));
                       }

                   }
               });
           });
       };

       ActivityAdaptee.prototype.update = function (updateOptions) {
           var proxyOptions = {};
           proxyOptions[enums.options.refetch] = true;
           proxyOptions[enums.options.optimizeForWeb] = true;

           var entity = updateOptions.data;

           objectBuilder.createEntityObject({
               copyFrom: entity,
               callback: function (transformedEntity, resultError) {
                   if (util.success(transformedEntity, resultError)) {
                       transformedEntity.getEntityMetadata().setDirty(true);
                       activityServiceProxy.save(transformedEntity, true, true, proxyOptions, function (entityResult, entityError) {
                           if (util.success(entityResult, entityError)) {
                               updateOptions.success(entityResult);
                           } else {
                               updateOptions.error(entityError);
                           }
                       });
                   } else {
                       updateOptions.error(new Error('Error saving entity'));
                   }

               }
           });
       };

       ActivityAdaptee.prototype.destroy = function (destroyOptions) {
           
           var entity = destroyOptions.data;

           objectBuilder.createEntityObject({
               copyFrom: entity,
               callback: function (transformedEntity, resultError) {
                   if (util.success(transformedEntity, resultError)) {
                       activityServiceProxy.deleteEntity(transformedEntity, true, {}, function (result, error) {
                           if (util.success(result, error)) {
                               destroyOptions.success(result);
                           } else {
                               destroyOptions.error(error);
                           }
                       });
                   } else {
                       destroyOptions.error(new Error('Error deleting entity'));
                   }

               }
           });
       };

       ActivityAdaptee.prototype.read = function (readOptions) {
           var that = this;
           var lowerCaseMethod = that.method.toLowerCase();
           if (validActivityMethods.indexOf(lowerCaseMethod) < 0) {
               throw new Error('Invalid activity method: ' + that.method);
           }

           /*Map method to the name with the appropriate case.
           * Also, define parameters for the method.
           */
           var activityMethod = null,
               methodParameters = null,
               activityOptions = null;
           switch (lowerCaseMethod) {
               case getCollectionByWhere:
                   activityMethod = 'getCollectionByWhere';
                   activityOptions = that._addPaginationOptions(that.options, readOptions);
                   methodParameters = [that.entityTypeName, that.where, that.parameters, that.getChildren, activityOptions, that._getDefaultRequestCallback(readOptions)];
                   break;
               case getAll:
                   activityMethod = 'getAll';
                   activityOptions = that._addPaginationOptions(that.options, readOptions);
                   methodParameters = [that.entityTypeName, that.getChildren, activityOptions, that._getDefaultRequestCallback(readOptions)];
                   break;
               case getCollectionByQueryDefID:
                   activityMethod = 'getCollectionByQueryDefID';
                   that._handleGetCollectionByQueryDef(activityMethod, readOptions);
                   return;
               //no default
           }
           
           //Invoke activity proxy method passing the parameters as an array.
           activityServiceProxy[activityMethod].apply(activityServiceProxy, methodParameters);
       };

       ActivityAdaptee.prototype.updateParameters = function (newParameters) {
           if (newParameters instanceof Array) {
               this.parameters = newParameters;
           } else {
               this.parameters = [newParameters];
           }
       };

       ActivityAdaptee.prototype.getRealTimeEntitiesToMonitor = function () {
           return [this.entityTypeName];
       };

       ActivityAdaptee.prototype.updateEntityParameters = function (/*entities*/) {
           throw new Error('Function not available for data sources related to the activity service');
       };

       ActivityAdaptee.prototype.setDataSourceOptions = function (newDataSourceOptions) {
           var that = this;
           DataAccessAdaptee.prototype.setDataSourceOptions.call(that, newDataSourceOptions);
           //Check whether the entityTypeName is available at the dataSourceOptions setting level.
           if (!that.entityTypeName && that.dataSourceOptions
               && that.dataSourceOptions.modularis && that.dataSourceOptions.modularis.entityTypeName) {
               that.entityTypeName = that.dataSourceOptions.modularis.entityTypeName;
           }
       };

       ActivityAdaptee.prototype._handleGetCollectionByQueryDef = function (method, readOptions) {
           var that = this;
           var methodParameters;
           var callback = that._getDefaultRequestCallback(readOptions);

           if (that.propertyName) {
               if (that.parameterSource) {
                   //Load metadata necessary to execute the query
                   metadataCache.getPropertyDef(that.entityTypeName, that.propertyName, function (propertyDef, propertyDefError) {
                       if (!util.success(propertyDef, propertyDefError)) {
                           var errorMessage = 'Error loading metadata for DataSource';
                           logger.error(errorMessage);
                           util.notify(that._getDefaultRequestCallback(readOptions), null, new Error(errorMessage));
                           return;
                       }
                       //In this case, try to use the parameter source to the get parameter values for the query def
                       var parameterValues = webUtil.formatLookupQueryDefParameterValues(that.parameterSource, propertyDef.LookupQueryDefParameterValues);
                       methodParameters = [propertyDef.LookupEntity, propertyDef.LookupQueryDefID, parameterValues, that.getChildren, that.options, callback];
                       activityServiceProxy[method].apply(activityServiceProxy, methodParameters);
                   });
               } else {
                   util.notify(callback, []);
               }
               
           } else {
               //extract parameters to invoke the activity service from the configuration passed to the proxy
               methodParameters = [that.entityTypeName, that.queryDefID, that.parameters, that.getChildren, that.options, callback];
               activityServiceProxy[method].apply(activityServiceProxy, methodParameters);
           }
       };

       return ActivityAdaptee;
   }
);
